

import { useNavigate } from "react-router-dom"
import { Box, Typography } from "@mui/material"

import { style } from "./refund_policy"
import Footer from "../components/footer"
import { useEffect } from "react";
import Header1 from "../components/header1"


const TermsOfService = () => {
    useEffect(()=>{
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;

    },[])
    const navigate = useNavigate()
    return <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", width: "100%", overflow: "hidden" }}>
        <Header1 />
        <Box sx={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
            <Box sx={{ marginTop: {xs:"77px",md:"118px"}, height: "150px", bgcolor: "grey.200", width: "100%", display: "flex", justifyContent: "center" }}>
                <Box sx={{ padding: { xs: "16px", sm: "24px" }, display: "flex", alignItems: "center", flexDirection: { xs: "column", sm: "row" }, justifyContent: "space-between", maxWidth: "1440px", width: "100%" }}>
                    <Typography sx={{ fontSize: "30px", fontWeight: "500" }}>Terms of Services</Typography>
                    <Box sx={{ display: "flex", justifyContent: "space-between", gap: "10px" }}>
                        <Typography onClick={() => navigate("/")} sx={{ fontSize: "12px", fontWeight: "400", color: "#ea4d2a", cursor: "pointer" }}>HOME</Typography>
                        <Typography sx={{ fontSize: "12px", fontWeight: "400" }}>{">"}</Typography>
                        <Typography sx={{ fontSize: "12px", fontWeight: "400", color: "grey.600" }}>TERMS OF SERVICES</Typography>
                    </Box>

                </Box>

            </Box>
            <Box sx={{ maxWidth: "1440px", padding: { xs: "16px", sm: "24px" } }}>
                {/* <Divider sx={{ marginTop: "20px", marginBottom: "10px" }} /> */}
                <Box sx={{ width: "100%", display: "flex", justifyContent: "center", marginTop: "70px", flexDirection: "column", gap: "24px" }}></Box>
                <Box>

                   <Typography sx={style.title}>Who we are</Typography>
                   <Typography sx={style.content}>Our website address is: https://mayiiq.com</Typography>
                   <br/>
                   <Typography sx={style.content}>These Terms of Use (“Terms”) govern your access to and use of our website, products, and services (collectively, the “Service”). By accessing or using the Service, you agree to comply with these Terms. If you do not agree to these Terms, you may not access or use the Service.</Typography>
                   <br/>
                   <Typography sx={{...style.content,fontWeight:500}}>This website is operated by MAYI IQ AI TECHNOLOGIES LLP.</Typography>
                   <br/>
                   <Typography sx={style.content}>1. Use of the Service You may use the Service only for lawful purposes and in accordance with these Terms. You agree not to:</Typography>
                   
                   <Typography sx={style.content}>I. Violate any applicable laws or regulations.</Typography>
                   
                   <Typography sx={style.content}>II. Infringe upon the rights of others.</Typography>
                   
                   <Typography sx={style.content}>III. Interfere with or disrupt the operation of the Service.</Typography>
                   
                   <Typography sx={style.content}>IV. Transmit any unauthorized or unsolicited advertising, promotional materials, or spam. V. Attempt to gain unauthorized access to any portion of the Service or any systems or networks connected to the Service.</Typography>
                   <br/>
                   <Typography sx={style.content}>2. Intellectual Property All content and materials available on the Service, including but not limited to text, graphics, logos, images, and software, are the property of Company or its licensors and are protected by copyright, trademark, and other intellectual property laws.</Typography>
                   <br/>
                   <Typography sx={style.content}>3. User Accounts Some features of the Service may require you to create an account. You are responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account. You agree to notify us immediately of any unauthorized use of your account or any other breach of security.</Typography>
                   <br/>
                   <Typography sx={style.content}>4. Privacy Your use of the Service is subject to our Privacy Policy, which governs the collection, use, and disclosure of your personal information. By accessing or using the Service, you consent to the collection, use, and disclosure of your personal information in accordance with our Privacy Policy.</Typography>
                   <br/>
                   <Typography sx={style.content}>5. Limitation of Liability TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, COMPANY SHALL NOT BE LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL, CONSEQUENTIAL, SPECIAL, OR PUNITIVE DAMAGES ARISING OUT OF OR IN ANY WAY CONNECTED WITH YOUR USE OF OR INABILITY TO USE THE SERVICE, INCLUDING BUT NOT LIMITED TO DAMAGES FOR LOST PROFITS, LOSS OF DATA, LOSS OF GOODWILL, OR BUSINESS INTERRUPTION, EVEN IF ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.</Typography>
                   <br/>
                   <Typography sx={style.content}>6. Modifications to the Service We reserve the right to modify, suspend, or discontinue any aspect of the Service at any time without notice. We may also impose limits on certain features or restrict your access to parts or all of the Service without notice or liability.</Typography>
                   <br/>
                   <Typography sx={style.content}>7. Governing Law These Terms are governed by and construed in accordance with the laws of the state of Delaware, US, without regard to its conflict of law principles.</Typography>
                   <br/>
                   <Typography sx={style.content}>8. Changes to Terms We reserve the right to modify these Terms at any time, effective upon posting the modified Terms on the Service. Your continued use of the Service following such changes constitutes your acceptance of the modified Terms.</Typography>
                   <br/>
                   <Typography sx={style.content}>9. <span style={{color:"#616161",fontWeight:600}}>Shipping Policy</span>: We partner with Ship rocket to deliver your purchases on time. We also use intelligent service recommendations to choose the cheapest, fastest and most economical shipping method when you add it to our cart. We ensure your purchases reaches you anywhere from 0-7 days. We also provide you a tracking status of the shipments so you are in the know how. For any queries in delivery please do email us @ <a style={{color:"rgb(39,162,253,1)",cursor:"pointer",textDecoration:"none"}} href="mailto:privacy@mayiiq.com"> support@mayiiq.com</a></Typography>
                   <br/>
                  
               </Box>
            </Box>
        </Box>
        <Footer/>
    </Box>
}
export default TermsOfService