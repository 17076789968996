import { Box, Button, InputBase, Paper, TextareaAutosize, Typography, Grid, Select, MenuItem, FormControl,InputLabel, Stack,Modal, Tooltip } from "@mui/material"
import Layout from "../layout"
import SideBarList from "../components/list"
import CheckCirleIcon from "../assets/Check_circle.svg";
import ExcIcon from "../assets/Exclamation.svg";
import ExcRedIcon from "../assets/exclamation_Red.svg";
import { inputStyles } from "../forms/signup_form"
import { useDispatch, useSelector } from "react-redux"
import { updateAddressModal, updateFirstName,updateLastName,updateUserName,  updateBio, updateAddress,updateEmail,updateMobile,updateProfile, updateOtpCheck, updateTransactionPin, updateReconfirmPin, updateMemberResponse,updateRole,updateId,updateAccumulated,updateGetBank ,updateIsProfileReloaded,updateWithdrawRequest} from "../redux/user_reducer"
import { styled } from '@mui/system';
import BackupIcon from '@mui/icons-material/Backup';
// import Address from "../components/address_modal"
import { useMediaQuery } from "react-responsive"
import axios from "axios"
import CustomAxios from '../utils/CustomAxios';
import { useEffect, useRef, useState } from "react"
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import InsertPhotoIcon from '@mui/icons-material/InsertPhoto';
import CircularProgress from '@mui/material/CircularProgress';
import Address from "../components/address_modal"
import Avatar from '@mui/material/Avatar';
import { EditOutlined } from "@mui/icons-material";
import security_png from '../assets/profile/Shield.png'
import { DatePicker } from "@mui/x-date-pickers";
import VerifiedIcon from '@mui/icons-material/Verified';
import CloseIcon from '@mui/icons-material/Close';     
import { MuiOtpInput } from "mui-one-time-password-input";
import { jwtDecode } from "jwt-decode";
import { keyframes } from '@mui/system';
import SheildIcon from "../assets/dashboad/profile/Frame.svg";
import { updateSnackBarMessage, updateSnackBarOpen, updateSnackBarSeverity } from "../redux/snackbar";
import dayjs from "dayjs";
import { OtpTransPin } from "../components/otp_transactionPIN";
import MessageBar from "./messageBar";
import BankComponent from "../components/bankSection"
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'

dayjs.extend(isSameOrBefore);

export const formStyle = {
    inputBase: { border: "1px solid", borderColor: "grey.400", width: "100%", borderRadius: "5px", height: "48px", fontSize: "16px", padding: "14px", color: "grey.700" },
    inputBasePin: { border: "1px solid #CBD5E1", maxWidth:"537px",width: "100%", borderRadius: "4px", height: "48px", fontSize: "16px", padding: "12px", color: "grey.700" },
    label:{ marginBottom: "12px", fontWeight: 400, fontSize: "14px" ,textAlign:"left"},
    textBox: { border: "1px solid", borderColor: "grey.400", width: "100%", borderRadius: "5px", fontSize: "16px",padding: "14px", color: "grey.700" },
    button: { height: "3rem",padding:"0rem 1.25rem", bgcolor:"primary.light","&:hover":{bgcolor:"primary.light",color:"primary.contrastText"},textTransform: "none", fontWeight: 600, fontSize: "14px", maxWidth: "150px", alignSelf: "flex-end",borderRadius:"5px" },
}


const Profile = () => {
    const glowingBorder = keyframes`
        0% {
            box-shadow: 0 0 8px 2px rgba(34, 197, 94, 0.3);
        }
        50% {
            box-shadow: 0 0 16px 4px rgba(34, 197, 94, 0.7);
        }
        100% {
            box-shadow: 0 0 8px 2px rgba(34, 197, 94, 0.3);
        }
    `
    const dispatch = useDispatch()
    const [imagefile, setImagefile] = useState('')
    const [selectedImage, setSelectedImage] = useState("");
    const [imagestatus, setImageStatus] = useState(true)
    const [useremail, setUserEmail] = useState("")
    const [isloading, setIsloading] = useState(false)
    const [isDloading, setIsDloading] = useState(false)
    const [allow, setAllow] = useState(false)
    const [userreponse, setUserResponse] = useState({})
    const [reasonSelect, setReasonSelect] = useState("")
    const [message, setMessage] = useState("")
    const [userid, setUserId] = useState('')
    const [pmessage, setPMessage] = useState('')
    const [imageurl, setImageUrl] = useState('')
    const navigate = useNavigate()
    // let 
    const [resend,setResend]=useState(0)
    const [ profileEdit,setProfileEdit]=useState(false)
    const username=useSelector(state => state.user.user_name)
    const fileRef = useRef()
    const [errors,setErrors]=useState({})
    const [emailVerified,setEmailVerified]=useState(true)
    const [mobileVerified,setMobileVerified]=useState(true)
    const [verify,setVerify]=useState(false)
    const [time, setTime] = useState();
    const [emailid,setEmailid]=useState("")
    const [emailEdit,setEmailEdit]=useState(false)
    const [mobileNO,setMobileNo]=useState("")
    const [otpNotmatch, setOtpNotMatch] = useState(false)
    const [verification,setVerification]=useState(false)
    const [mobileEdit,setMobileEdit]=useState(false)
    const [otp,setOtp]=useState('')
    const [maskedEmail,setMaskedEmail]=useState()
    const [maskedmobileno,setMaskedMobile]=useState()
    let  first_name = useSelector(state => state.user.first_name)
    let  last_name = useSelector(state => state.user.last_name)
    let  email = useSelector(state => state.user.email)
    let  mobile = useSelector(state => state.user.mobile_no)
    let bio = useSelector(state => state.user.bio)
    let id = parseInt(useSelector(state => state.user.id))
    let profile = useSelector(state => state.user.profile)
    // console.log(id)
    // let address,updateFirstName, updateLastName,
    // let address = useSelector(state => state.user.address)
    const fulladdress = useSelector(state => state.user.address)
    const [addresslist, setAddressList] = useState([])
    let addValue = false
    addValue = window.sessionStorage.getItem('addressadd');
   
    const address = useSelector(state => state.user.allAddress)
    // console.log(address)
    // // console.log(username)
    const isMobile = useMediaQuery({ maxWidth: 600 });
    const isLaptop = useMediaQuery({ query: '(min-width: 900px)' });
    const kyc_ref = useRef()
    const transpin_ref = useRef()
    const urlParams = new URLSearchParams(window.location.search)
    const [kyc_details,setKycDetails] = useState({
        fullName:"",
        dob:null,
        mobileNo:"",
        pan:""
    })
    const [iskyceditable,setIskyceditable] = useState(false)
    const [kycPresent,setkycPresent] = useState(false)
    const [kyc_errors,setKycErrors] = useState({})
    const [otpModal, setOtpmodal] = useState({ value: false, type: ''})
    const [transPinPresent, setTranspinPresent] = useState(false)
    const [maskedValue, setMaskedValue] = useState('');
    const [validations, setValidations] = useState({transPin:"",resubmitPin:"",equalPin:"",numberCheck:""})
    let transactionPin = useSelector(state => state.user.transactionPin)
    let reconfirmPin = useSelector(state => state.user.reconfirmPin)
    const [retransClose, setRetransclose] = useState(false)
    const [reTransPinOtp, setReTransPinOtp] = useState(false)
    const [transactionpinSuccess,setTransactionpinSuccess]=useState(false)
    const { hash } = useLocation();
    const isProfileReloaded = useSelector(state=>state.user.isProfileReloaded)
    const [kyc_linked_data,setKyc_Linked_data] = useState({})
    const [kyc_existing_data,setKyc_existing_data] = useState({})
    useEffect(() => {
        // console.log(id)
        setUserId(id)
    }, [id])

    useEffect(() => {
        fetchuserdetails()
        dispatch(updateIsProfileReloaded(false))
    }, [userid,transactionpinSuccess,isProfileReloaded])

    useEffect(()=>{
        setUserEmail(email)
    },[email])

    useEffect(() => {
        // // console.log(addValue)
        // console.log(fulladdress)
        if (fulladdress == true) {
            setAddressList(address)
        }
    }, [fulladdress])


    useEffect(()=>{
        // console.log(mobileEdit)
        if (mobileEdit){
            setMobileVerified(false)
        }
        else{
            setMobileVerified(true)
        }
        
    
    },[mobileEdit])
    useEffect(()=>{
        setErrors({})
        if (emailEdit){
            setEmailVerified(false)
        }
        else{
            setEmailVerified(true)
        }
        
    
    },[emailEdit])
    const verifyHandler=(verify)=>{
        if (verify=="email" && !emailVerified){
            setVerify("email");
        setTime(30);
       
            const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{1,}$/;
            if( emailPattern.test(emailid)){
              
                verifyRequest(verify)
    
               }
               else{
                const newErrors={
                    email:"Please enter valid Email"
                }
                setErrors(newErrors)
               }
    
    
        }
        else if((verify=="mobile" && !mobileVerified )){
            setVerify("mobile");
            setTime(30);
            // console.log(mobileNO)
            if( mobileNO.length==10){
             verifyRequest(verify)
            }
            else{
             const newErrors={
                 mobile_no:"Please enter valid Mobile No"
             }
             setErrors(newErrors)
            }
    
        }
    }
    const verifyRequest=(verify)=>{
        // console.log(verify)
        setOtpNotMatch(false)
        setOtp("")
        const payload={id:id,email:emailid,verify:verify,mobileno:mobileNO}
        // console.log(payload)
        CustomAxios.post("verify/",payload).then(data=>setVerification(true))

    }
    const fetchuserdetails = async () => {
        // console.log("id", id)
        const payload = {
            user_id: userid
        }
        if(userid){

            await CustomAxios.post(`get_details/`, payload).then(response => {
                // console.log(response.data)
                setUserResponse(response.data)
                if(response.data.account.transaction_pin){
                    setTranspinPresent(true)
                }
                setIskyceditable(false)
                response.data.account.kyc_status?setkycPresent(true):setkycPresent(false)
                setKycDetails({...kyc_details,...response.data.kyc_details || {}, dob: response.data.kyc_details ? dayjs(response.data.kyc_details.dob) : null,mobileNo:response.data.user.mobileno})
                // setKycDetails({...kyc_details,...response.data.kyc_details || {}, dob: response.data.kyc_details ? dayjs(response.data.kyc_details.dob) : null})
                setKyc_existing_data({...kyc_details,...response.data.kyc_details || {}, dob: response.data.kyc_details ? dayjs(response.data.kyc_details.dob) : null})
                // console.log(response.data)
                dispatch(updateUserName(response.data.user.username))
                dispatch(updateMemberResponse(response.data))
                dispatch(updateFirstName(response.data.user.first_name))
                dispatch(updateLastName(response.data.user.last_name))
                dispatch(updateEmail(response.data.user.email))
                dispatch(updateMobile(response.data.user.mobileno))
                dispatch(updateBio(response.data.account.bio))
                dispatch(updateAccumulated(response.data.accumulated))
                dispatch(updateGetBank(response.data.bankdetails))
                dispatch(updateWithdrawRequest(response.data.withdrawRequest))
                // setImageUrl(response.data.att?.original)
                if (response.data.att?.original){
                    setImageStatus(true)
                }
                dispatch(updateProfile(response.data.att?.original))
                const get_address = response.data.add.flatMap(innerArray => innerArray);
                // console.log(get_address)
                dispatch(updateAddress(get_address, "update"))
                // console.log(address)
            }
            ).catch(error => {
                // console.log(error)
            })
        }
    }

    useEffect(() => {
        // // console.log(addValue)
        // console.log(fulladdress)
        if (fulladdress == true) {
            setAddressList(address)
        }
    }, [fulladdress])
    
    const updateProfiles = async () => {
        
        // console.log("allow to all")
       
        // console.log(email)
        const formData = {
            first_name: first_name,
            last_name: last_name,
            // bio: bio,
            email: emailVerified&&emailid? emailid:email,
            mobile_no:mobileVerified&&mobileNO? mobileNO:mobile,
            username:username

        }

        const validation = profileValidate(formData)
        // console.log(formData)
        if (validation) {
            const form = new FormData();
            form.append("first_name", first_name);
            form.append("last_name", last_name);
            // console.log("before form validation")
            // form.append("bio", bio)
            form.append("mobileno", mobileVerified&&mobileNO? mobileNO:mobile)
            // console.log("before form validation")
            form.append("email", emailVerified&&emailid? emailid:email)
            form.append("user_id", id)
            // form.append("username",username)
            // console.log("form", form)
            form.append("file_url", imagefile)
            // console.log(imagefile)
            //// console.log(username, bio, email, id)
            if (userreponse.att?.length === 0) {
                form.append("att_id", "")
            }
            else {
                // console.log(userreponse.att)
                // console.log("else")
                form.append("att_id", userreponse.att?.id)
            }
            form.append('address_list', JSON.stringify(address))
            setIsloading(true)
            const updateresponse = await CustomAxios.post(`update_profile/`, form)
            setIsloading(false)
            if (updateresponse.data.message == "User Profile succesfully updated without email") {
                setMessage("Updated Successfully !")
                setProfileEdit(false)
                window.location.reload();
            }
            else {
                if (updateresponse.data.message == "User Profile updated along with email") {
                    dispatch(updateOtpCheck('profile'))
                    // navigate('/otp-verification')
                    setProfileEdit(false)
                }
                else {
                    if (updateresponse.data.message == "Give a different email") {
                        setMessage("Email id already exist Give a different email")
                        setProfileEdit(false)
                    }
                }
            }
        }



    }
    useEffect(()=>{
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;

    },[])
    useEffect(() => {
        // console.log(time)
        if (time > 0) {
          const timerId = setInterval(() => {
            setTime(prevTime => prevTime - 1);
          }, 1000);
          return () => clearInterval(timerId); // Cleanup interval on component unmount
        }
        else{
            setResend(true)
        }
      }, [time]);
    const profileValidate = (formData) => {
        let valid = true;
        // console.log(formData)
        let newErrors = {}
        // console.log(formData)
        if (!formData.first_name.trim()) {
            newErrors.firstname = 'Please enter your First Name here';
            valid = false;
        }
        if (!formData.last_name.trim()) {
            newErrors.lastname = 'Please enter your Last Name here';
            valid = false;
        }
        const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{1,}$/;
        if (!emailVerified && !formData.email.trim()) {
            newErrors.email = 'Enter your email address.​';
            valid = false;
        } else if (!emailVerified && !emailPattern.test(formData.email)) {
            newErrors.email = 'Please enter valid email address';
            valid = false;
        }
        else if(!emailVerified && emailPattern.test(formData.email)){
            newErrors.email="Please Verify Email"
            valid=false
        }
        if(!mobileVerified && !formData.mobile_no.trim()){
            newErrors.mobile_no = 'Enter your Mobile Number.'
            valid = false
        }
        else if(!mobileVerified &&formData.mobile_no.length !== 10){
            newErrors.mobile_no = 'Enter Valid Number.'
            valid = false
        }
        else if(!mobileVerified && formData.mobile_no.length == 10){
            newErrors.mobile_no="Please Verify Mobile No"
            valid=false
        }
       
        // console.log(newErrors)
        setErrors(newErrors)
        return valid

    }
    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
      };
    const maskEmail = (email) => {       //--------------> This is for masking email
        const [localPart, domain] = email.split('@');
        if (localPart.length <= 2) {
            return `${localPart}@${domain}`; 
        }
        const visiblePart = localPart.slice(-2); 
        const maskedPart = 'X'.repeat(localPart.length - 2);
        return `${maskedPart}${visiblePart}@${domain}`;
    };
      const maskMobileNo = (mobileNumber) => {     //-----------> This is for masking mobile
        if (mobileNumber.length <= 2) {
            return mobileNumber; 
        }
        
        const visiblePart = mobileNumber.slice(-2); 
        const maskedPart = 'X'.repeat(mobileNumber.length - 2); 
        return `${maskedPart}${visiblePart}`;
    };
   useEffect(()=>{
    if (mobile==mobileNO){
        setMobileVerified(true)
        setErrors({})
    }

   },[mobileNO])
   useEffect(()=>{
    if (email==emailid){
        setEmailVerified(true)
        setErrors({})
    }

   },[emailid])

    useEffect(() => {
        if (emailid) {
            setMaskedEmail(maskEmail(emailid));
        }
        if (mobileNO) {
          setMaskedMobile(maskMobileNo(mobileNO));
      }
    }, [emailid,mobileNO ]);
    const handleResendOtp =(e) => {
        verifyRequest(verify)
        setTime(30)
      }
    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            
            
        }
      };
      const handleComplete = async(value) => {
        const payload={
            id:id,
            email:email,
           
            otp:otp
        }
        CustomAxios.post('otp-verification/',payload).then(data=>{
            if (data.data.message=="Congratulations, your account have been successfully verified"){
                const decode=jwtDecode(data.data.access_token)
                localStorage.setItem('authtoken', data.data.access_token);
                localStorage.setItem('refreshToken', data.data.refresh_token)
                dispatch(updateFirstName(decode.first_name))
            dispatch(updateLastName(decode.last_name))
            dispatch(updateEmail(decode.email))
            dispatch(updateRole(decode.role))
            dispatch(updateId(decode.id))
                if (verify=="email"){
                    setEmailVerified(true)
                    setOtpNotMatch(false)
                    setErrors({})
                }
                if(verify=="mobile"){
                    setMobileVerified(true)
                    setOtpNotMatch(false)
                    setErrors({})
                }
                setVerification(false)
            }
            else{
                setOtpNotMatch(true)

            }
            
        }
            
        )
       }
    // const updateEmail = () => {
    //     // console.log("allow to all")
    // }

    // const updateMobile = () => {
    //     // console.log("allow to all")
    // }

    const uploadImage=(event)=>{
        // console.log(event)
        // console.log("we are here")
        const file = event.target.files[0];
        setImagefile(file)
        if (file) {

            const reader = new FileReader();

            reader.onload = (e) => {
                setSelectedImage("")
              setSelectedImage(e.target.result);
              setImageStatus(true)
            };

            reader.readAsDataURL(file);
          }
    }
    const editAddress = (item) => {
        dispatch(updateAddress(item))
        dispatch(updateAddressModal(true))
    }
    useEffect( ()=>{
        const payload = {
            id : id
        } 
        if (useremail){
            CustomAxios.post(`get_delete/`, payload).then(({data})=>{
                // console.log(data)
                setAllow(data?.request_decline)
            }).catch(error=>{
                // console.log(error)
            })
        }
    },[useremail])

    const loadFile =() =>{
        document.getElementById("image-upload").click();
    }
    const handleReasonSelect=(e)=>{
        setReasonSelect(e.target.value)
    }
    const handleAccDelRequest= async ()=>{
        const payload={
            id:id,
            reason:reasonSelect
        }
        if (!allow || reasonSelect!=""){
            setIsDloading(true)
        const response = await CustomAxios.post(`request_delete/`, payload)
        setIsDloading(false)
        if (response.data.message=="Mail sent successfully"){
            setMessage("Your profile deletion request has been successfully submitted. Your account will be deleted within 2 business days.")
        }}

    }

    // console.log(imagestatus, imagefile)

    const isTabletHori = useMediaQuery({ query: '(min-width: 1200px)' });
    function stringToColor(string) {
        let hash = 0;
        let i;
      
        /* eslint-disable no-bitwise */
        for (i = 0; i < string.length; i += 1) {
          hash = string.charCodeAt(i) + ((hash << 5) - hash);
        }
      
        let color = '#';
      
        for (i = 0; i < 3; i += 1) {
          const value = (hash >> (i * 8)) & 0xff;
          color += `00${value.toString(16)}`.slice(-2);
        }
        /* eslint-enable no-bitwise */
      
        return color;
      }
    function stringAvatar(name) {
        return {
          sx: {
            bgcolor: stringToColor(name),
            width:"172px",height:"172px",
            fontSize:"32px",borderRadius:0
          },
          children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
        };
      }
      const capitalize = (str) => {
        return str?.split(' ').map(word => word?.charAt(0).toUpperCase() + word.slice(1)).join(' ');
      };

      const handleKycSubmit = async (e) => {
        e.preventDefault();
        setIsloading(true);
        setOtpNotMatch(false)
        dispatch(updateSnackBarMessage(""));
    
        let errors = validateKYCFormDetails();

        // let is_kyc_data_changed = Object.keys(kyc_details).filter(key=> ['fullName','dob','mobileNo','pan'].includes(key)).some((key)=>console.log(key,kyc_details[key] , kyc_existing_data[key]))
        if (Object.keys(errors).length === 0 ) {
            let is_kyc_data_changed = Object.keys(kyc_details).filter(key=> ['fullName','dob','mobileNo','pan'].includes(key)).some((key)=> key !== 'dob' ? kyc_details[key].replace(" ",'').toLowerCase() !== kyc_existing_data[key].replace(" ",'').toLowerCase() : dayjs(kyc_details[key], 'DD-MM-YYYY').format('YYYY-MM-DD') !== dayjs(kyc_existing_data[key], 'DD-MM-YYYY').format('YYYY-MM-DD') )
            if(is_kyc_data_changed){

                try {
                    let formattedDob = dayjs(kyc_details.dob, 'DD-MM-YYYY').format('YYYY-MM-DD');
                    let dobString = formattedDob.toString();
                
                    let payload = {
                        ...kyc_details,
                        dob:dobString,
                        // dob: formattedDob,
                        user_id: id
                    };
        
                    if (userreponse?.kyc_details && iskyceditable) {
                        payload.kyc_id = userreponse?.kyc_details?.id;
                        // delete payload['pan'];
                        // delete payload['id'];
        
                        const res = await CustomAxios.put('kyc-details/', payload);
                        handlePutResponse(res.data);
                    } else {
                        payload.account = userreponse?.account?.id;
                        payload.id = id;
        
                        const res = await CustomAxios.post('kyc-details/', payload);
                        handlePostResponse(res.data);
                    }
                } catch (err) {
                    console.error(err);
                    handleError("Failed to update KYC details");
                }
            }
            else{
                !is_kyc_data_changed && handleError("No modifications were made to the data.")
            }
        } else {
            setIsloading(false);
        }
    };

    const handleKycCancel = ()=>{
        if(userreponse?.kyc_details){
            setKycDetails({...kyc_details,...userreponse.kyc_details,dob :dayjs(userreponse.kyc_details.dob)})
        }
        else{
            setKycDetails({
                ...kyc_details,
                fullName:"",
                dob:null,
                // mobileNo:"",
                pan:""
            })
        }
        setIskyceditable(false)
        setKycErrors({})
    }
    
    const handlePutResponse = (data) => {
        if (data.error) {
            if (typeof data.error === "object") {
                let keys = Object.keys(data.error);
                let errors = {};
                keys.forEach((e) => errors[e] = "Already Exists.");
                setKycErrors(errors);
                dispatch(updateSnackBarMessage("Contact Customer Support."));
            } else {
                dispatch(updateSnackBarMessage(data.error));
            }
            dispatch(updateSnackBarSeverity("error"));
            dispatch(updateSnackBarOpen(true));
            setOtpmodal({ value: false, type: '' });
        } else {
            // Handle success response for PUT method
            // console.log("here put")
            setKyc_Linked_data(data?.json_data)
            dispatch(updateSnackBarMessage("KYC details updated successfully"));
            setOtpmodal({ value: true, type: 'kyc' });
            setTime(30);
            dispatch(updateOtpCheck("kycOtp"));
        }
        setIsloading(false);
    };

    const handlePostResponse = (data) => {
        if (data.error) {
            if (typeof data.error === "object") {
                let keys = Object.keys(data.error);
                let errors = {};
                keys.forEach((e) => errors[e] = "Already Exists.");
                setKycErrors(errors);
                dispatch(updateSnackBarMessage("Contact Customer Support."));
            } else {
                dispatch(updateSnackBarMessage(data.error));
            }
            dispatch(updateSnackBarSeverity("error"));
            dispatch(updateSnackBarOpen(true));
            setOtpmodal({ value: false, type: '' });
        } else {
            // Handle success response for POST method
            // console.log("here post")
            setKyc_Linked_data(data?.json_data)
            dispatch(updateSnackBarMessage("KYC details added successfully"));
            setOtpmodal({ value: true, type: 'kyc' });
            setTime(30);
            dispatch(updateOtpCheck("kycOtp"));
        }
        setIsloading(false);
    };

    const validateKYCFormDetails = ()=>{
        let errors={}
        let pan_regrx = new RegExp(/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/);
        if(!kyc_details.fullName || !kyc_details.fullName.trim()){
            errors['fullName'] = "Required."
        }
        if(!kyc_details.dob){
            errors['dob'] = "Required."
        }
        else if(!kyc_details.dob.isSameOrBefore(dayjs())){
            errors['dob'] = "DOB cannot be in future."
        }
        if( !kyc_details.mobileNo || !kyc_details.mobileNo.trim()){
            errors['mobileNo'] = "Required."
        }
        else if(kyc_details.mobileNo.length !== 10){
            errors['mobileNo'] = "Enter a valid number."
        }
        if(!kyc_details.pan.trim()){
            errors['pan'] = "Required."
        }
        else if(kyc_details.pan.length !==10){
            errors['pan'] = "Enter a valid pan."
        }
        else if( !pan_regrx.test(kyc_details.pan.trim().toUpperCase())){
            errors['pan'] = "Invalid format."
        }
        setKycErrors(errors)
        return errors

    }

    const handleError = (message) => {
        dispatch(updateSnackBarMessage(message));
        dispatch(updateSnackBarSeverity("error"));
        dispatch(updateSnackBarOpen(true));
        setIsloading(false);
    };

    const handleChangeTranspin = (e,pin) => {
        // console.log(pin)
        const newValue = e.target.value;
        if (/^[0-9*]*$/.test(newValue)) {
            setValidations(prev => ({...prev, numberCheck: ""}));
            if(pin == 'transpin'){
                setMaskedValue(newValue);
                dispatch(updateTransactionPin(newValue))
            }
            if(pin == 'retranspin'){
                dispatch(updateReconfirmPin(newValue))
            }
            
        }else{

            setValidations(prev => ({...prev, numberCheck: "Only numbers are allowed"}));
        }
      };

      const handleCheckCharacterLength = (e) => {  //-----------------> for checking the transaction PIN length
        e.preventDefault();
        setMaskedValue('* '.repeat(e.target.value.length));
        if (transactionPin.length > 0 && transactionPin.length < 6 ) {
            setValidations(prev => ({...prev, transPin: "Minimum 6 Numbers"}));
          }
           else {
            setValidations(prev => ({...prev, transPin: ""}));
          }
        if(reconfirmPin.length > 0 && reconfirmPin.length < 6) {
            setValidations(prev => ({...prev, resubmitPin: "Minimum 6 Numbers"}));
          }
          else {
            setValidations(prev => ({...prev, resubmitPin:""}));
          }

        
      } 

      const handleFocus = () => {
        setMaskedValue(transactionPin);
    };

    const handleCopy = (event) => {
        event.preventDefault();
    };

    const changePin = (e) => {        //-------------------> Resetting Transaction pin
        e.preventDefault();
        // dispatch(updateTransactionPin(""));
        dispatch(updateReconfirmPin(""));
        setMaskedValue("")
        setTranspinPresent(false)
        setRetransclose(true)
        setValidations({transPin:"",resubmitPin:"",equalPin:"",numberCheck:""})
     }

     const sendOTP = (e,type) => {
        e.preventDefault();
        
        let payload
        if(type === "transactionpin"){
            if(transactionPin === reconfirmPin || transPinPresent){
                setTime(30)
                setOtpNotMatch(false)
                setOtpmodal({ value: true, type: 'transactionpin' })
                payload = {
                otp_type:type,
                id:id
                 } }
                 else{
                     setValidations(prev => ({...prev, equalPin: "Pin does not match"}));
                 }
        }
        if(type === "kyc"){ 
            let errors = validateKYCFormDetails()
            if(Object.keys(errors).length == 0){  
                setTime(30)
                setOtpmodal({ value: true, type: 'kyc' })
                    payload = {
                    otp_type:type,
                    id:id
                }}
                // if(kyc_linked_data.mobileno){
                //     payload['mobileno'] = kyc_linked_data.mobileno
                // }
        }
        if(payload){

            CustomAxios.post(`profile_otp/`, payload).then(({data})=>{
            if(data.message == "Transaction OTP has been sent"){
                dispatch(updateOtpCheck('transOtp'));
            }
            if(data.message == "KYC OTP has been sent"){  
                dispatch(updateOtpCheck('kycOtp'));
            }
        }).catch(error=>{
            // console.log(error)
        })}
       
      }

      const handleCancel = (event) => {
        event.preventDefault();
        setTranspinPresent(true)
        setValidations({transPin:"",resubmitPin:"",equalPin:"",numberCheck:""})
    };

    const handleClearPin = () => {     //-------------------> Transaction pin form clear
        if(!transPinPresent){
            dispatch(updateTransactionPin(""))
            dispatch(updateReconfirmPin(""))
            setMaskedValue("")
            setValidations({transPin:"",resubmitPin:"",equalPin:"",numberCheck:""})
        }
      }

      const handleSubmitPin = () => {   //-----------------> for submitting transaction PIN
        const payload = {
            transaction_pin:transactionPin,
            user_id:userid
        }
        
        CustomAxios.post(`Transaction_pin/`, payload).then(({data})=>{  //----------------> API for adding transaction pin  
           if(data.message == "transaction pin has been set successfully"){
               setTranspinPresent(true);
               setOtpmodal({ value: false, type: '' })
               setTransactionpinSuccess(true)
               dispatch(updateSnackBarMessage("Transaction pin has been set successfully"))
               dispatch(updateSnackBarSeverity("success"))
               dispatch(updateSnackBarOpen(true))
           }
        }).catch(error=>{
            // console.log(error)
            setTransactionpinSuccess(true)

        })
  }
    const scrollToSection = (id) => {
        const element = document.getElementById(id);
        if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
        }
    };

    useEffect(()=>{
        if(transPinPresent){
            // dispatch(updateTransactionPin("* * * * * *"))
            setMaskedValue("* * * * * *")
        }else{
            dispatch(updateTransactionPin(""))
        }
    },[transPinPresent])

    useEffect(() => {
        if (hash) {
            const element = document.querySelector(hash);
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, [hash]);
          return (

        <Box sx={{ bgcolor:{ xs:"#fff",sm:"grey.100"}, minHeight: "100vh", display: "flex", alignItems: "start", justifyContent: "start" }}>
            <Layout />
            <MessageBar/>
            <Box sx={{ alignSelf: "start", marginTop:{xs:"70px", sm:"140px"}, marginBottom: "100px", justifySelf: "center", display: "flex", justifyContent: "center", width: "100%", gap: {xs:"0px",md:"36px"} }}>
                {isTabletHori && <Paper sx={{ height: "max-content",width:"20%",position:"sticky",top:"140px" }} elevation={1}>
                    {SideBarList('left')}
                </Paper>}
                
                <Box id="mobileno" elevation={1} sx={{ width:  {xs:"100%",md:"95%",lg:"65%"}, display: "flex", flexDirection: "column",  gap: {xs:"0px",md:"36px"}  }}>

                <Box component={isLaptop ? Paper : Box} sx={{ padding: "20px"}}>
                    <Stack direction={"row"} sx={{justifyContent:"space-between",alignItems:"center",marginBottom:"16px"}}>
                            <Stack direction="row" sx={{gap:"10px",alignItems:"center"}}>
                                <Typography sx={{fontFamily:"Poppins,Roboto",fontWeight:500,lineHeight:"24px",fontSize:"16px",letterSpacing:"0.15px",color:"#000000"}}>Overview</Typography>
                                {/* <Box component="img" src={security_png} sx={{width:"20px",height:"20px"}}/> */}
                            </Stack>
                            <EditOutlined sx={{color:profileEdit?"grey.700":"primary.light",cursor:profileEdit?"not-allowed":"pointer"}} onClick={()=>setProfileEdit(true)} />
                        </Stack>
                        
                        <Box sx={{ display: "flex", flexDirection: 'column', gap: "16px", width: "100%" ,maxWidth:"703px" }}>
                        <Box>
                        
                                <Typography sx={formStyle.label} color="grey.900"> User Name <Typography component="span" sx={{color:"#EF4444"}}>*</Typography> </Typography>
                                <Box>
                                    {/* <Typography sx={formStyle.label} color="grey.700">Email</Typography> */}
                                    <InputBase disabled sx={formStyle.inputBase} value={username} onChange={(e) => dispatch(updateUserName(e.target.value))} />
                                    {/* <Typography sx={{marginTop:"10px"}}>Maximum 8 character. You can share this with friends for referrals.</Typography>
                                    {errors.username && <Typography sx={{color:"error.light"}}>{errors.username}</Typography>} */}
                                </Box>
                                {/* <Typography sx={formStyle.label} color="grey.700">Email</Typography> */}
                                {/* <InputBase sx={formStyle.inputBase} value={email} onChange={(e) => dispatch(updateUserName(e.target.value))} /> */}
                                {/* {errors.userName && <Typography sx={{color:"error.light"}}>{errors.userName}</Typography>} */}
                            </Box>
                            
                           
                            <Typography sx={formStyle.label} color="grey.900">Profile image</Typography>
                             {selectedImage!="" && imagestatus ? <Box onClick={() => { fileRef.current && fileRef.current.click() }} component="img" src={selectedImage} sx={{width:isLaptop?"172px":"120px",height:isLaptop?"172px":"120px",objectFit:"cover",background:"#CBD5E1",border:"none",cursor:!profileEdit?"default":"pointer"}} ></Box>:(profile) && imagestatus  && <img  onClick={() => { fileRef.current && fileRef.current.click() }} src={profile} style = {{width:"172px",height:"172px",objectFit:"cover",background:"#CBD5E1",border:"none",cursor:!profileEdit?"default":"pointer"}} alt='Profile' onError={(e) => {setImageStatus(false)}}/>}
                            {/* {} */}
                            <Box sx={{display:"flex", flexDirection:"row", gap:"8px",marginTop:"10px"}}>
                            <Box sx={{display:"flex",alignItems:"center",gap:"8px"}}>
                            {/* <InsertPhotoIcon color='primary'/> */}
                           {!selectedImage!="" &&  (!profile) &&<Box onClick={() => { fileRef.current && fileRef.current.click() }} sx={{ cursor:!profileEdit?"default":"pointer",width:"185px",height:"185px",bgcolor:"grey.300",display:"flex",alignItems:"center",justifyContent:"center",fontSize:"24px" }}> 
                                <Typography>+</Typography>
                           </Box>}
                           <input disabled={!profileEdit}  ref={fileRef}
                                id="image-upload"
                                type="file"
                                accept="image/*" // Allow only image files
                                style={{padding: '10px 20px',
                                marginTop:"8px",
                                marginBottom:"18px",
                                color: 'white',
                                borderRadius: '5px',
                                cursor: 'pointer',
                                display:"none",
                                color:"var(--pallate-green-500, #22C55E)"
                            }}
                                onChange={uploadImage}
                            />
                            </Box>
                        </Box>

                            
                            <Box sx={{ display:"flex" ,flexDirection:{xs:"column",sm:"row"} ,gap:"16px"}}>
                            <Box sx={{ width:"100%"}}>
                                <Typography sx={formStyle.label} color="grey.900">First Name <Typography component="span" sx={{color:"#EF4444"}}>*</Typography></Typography>
                                <InputBase disabled={!profileEdit} sx={formStyle.inputBase} value={first_name} onChange={(e) => dispatch(updateFirstName(e.target.value))} />
                                {errors.firstname && <Typography sx={{color:"error.light"}}>{capitalize(errors.firstname)}</Typography>}
                            </Box>
                            <Box  sx={{ width:"100%"}}>
                                <Typography sx={formStyle.label} color="grey.900">Last Name <Typography component="span" sx={{color:"#EF4444"}}>*</Typography></Typography>
                                <InputBase disabled={!profileEdit} sx={formStyle.inputBase} value={last_name} onChange={(e) => dispatch(updateLastName(e.target.value))} />
                                {errors.lastname && <Typography sx={{color:"error.light"}}>{capitalize(errors.lastname)}</Typography>}
                            </Box>
                            </Box>
                            {/* <Box>
                                <Typography sx={formStyle.label} color="grey.900">Bio</Typography>

                                <InputBase multiline rows={5} sx={formStyle.textBox} value={bio} onChange={(e) => dispatch(updateBio(e.target.value))} /> */}

                                {/* {errors.userName && <Typography sx={{color:"error.light"}}>{errors.userName}</Typography>} */}
                            {/* </Box> */}

                            {/* <Button sx={formStyle.button} variant="contained" onClick={updateProfile} >Update</Button> */}
                            <Box sx={{ display:"flex" ,flexDirection:{xs:"column"} ,gap:"16px"}}>
                            <Box sx={{ width:"100%"}}>
                                    <Typography sx={formStyle.label}>Mobile Number <Typography component="span" sx={{color:"red"}}>*</Typography></Typography>
                                    <Box sx={{display:"flex",gap:"16px"}}>
                                    {/* onKeyDown={(e)=>{if(e.key=='Enter'){verifyHandler('mobile')}}} */}
                                    <InputBase disabled={!profileEdit} autoComplete="tel-national"   onKeyDown={(e)=>{if(e.key=='Enter'){verifyHandler('mobile')}}} value={mobileNO || mobileEdit ?mobileNO:mobile} sx={formStyle.inputBase} startAdornment={<Typography sx={{pr:"5px",fontSize:"16px"}} >+91</Typography>} endAdornment={mobileVerified&&mobile&& <VerifiedIcon sx={{color:"secondary.light"}} />}  placeholder="(Enter your 10 Digit mobile number)" 
                                    onChange={(e)=>{setMobileNo(e.target.value.replace(/\D/g, ''));setMobileEdit(true);setMobileVerified(false)}}
                                    />
                                    {
                                        mobile!=mobileNO&& mobileNO!=""&& !mobileVerified &&<>
                               
                                    <Button sx={formStyle.button} variant="contained" onClick={()=>verifyHandler("mobile") } >{isloading? <CircularProgress color="inherit" size={30}/> : "Verify"}</Button>
                                    <Button sx={formStyle.outlinedButton} variant="outlined" onClick={()=>{setMobileNo("");setMobileEdit(false);setErrors({})}} > Cancel</Button></>     }
                                    </Box>
                                    {errors?.mobile_no && <Typography sx={{ fontWeight: 400, fontSize: "14px", color: "error.light", mt: "4px" }}>{capitalize(errors.mobile_no)}</Typography>}
                                </Box>
                            <Box sx={{ width:"100%"}}>
                                <Typography sx={formStyle.label} color="grey.900">Email ID <Typography component="span" sx={{color:"#EF4444"}}>*</Typography> </Typography>
                                <Box sx={{display:"flex",gap:"16px"}}>
                                    {/* <Typography sx={formStyle.label} color="grey.700">Email</Typography> */}
                                    {/* onKeyDown={(e)=>{if(e.key=='Enter'){verifyHandler('email')}}} */}
                                    <InputBase disabled={!profileEdit} sx={formStyle.inputBase}  onKeyDown={(e)=>{if(e.key=='Enter'){verifyHandler('email')}}}  value={emailid || emailEdit ? emailid:email} endAdornment={emailVerified&& <VerifiedIcon sx={{color:"secondary.light"}} />} onChange={(e) =>{ setEmailid(e.target.value);setEmailEdit(true);setEmailVerified(false)}} />
                                    {
                                        email!=emailid&& emailid!="" && !emailVerified&&<>
                                    <Button sx={formStyle.button} variant="contained" onClick={()=>verifyHandler("email") }>{isloading? <CircularProgress color="inherit" size={30}/> : "Verify"}</Button>
                                    <Button sx={formStyle.outlinedButton} variant="outlined" onClick={()=>{setEmailid("");setEmailEdit(false);setErrors({})}} > Cancel</Button></>}
                                    
                                </Box>
                                {errors.email && <Typography sx={{color:"error.light"}}>{capitalize(errors.email)}</Typography>}
                                {/* <Typography sx={formStyle.label} color="grey.700">Email</Typography> */}
                                {/* <InputBase sx={formStyle.inputBase} value={email} onChange={(e) => dispatch(updateUserName(e.target.value))} /> */}
                                {/* {errors.userName && <Typography sx={{color:"error.light"}}>{errors.userName}</Typography>} */}
                            </Box>
                            </Box>
                            {
                                profileEdit&&  <Box  sx={{alignSelf:"flex-start",display:"flex",gap:"16px"}}> 
                                <Button disabled={!emailVerified||!mobileVerified} sx={formStyle.button} variant="contained" onClick={updateProfiles} >{isloading? <CircularProgress color="inherit" size={30}/> : "Update"}</Button>
                                <Button sx={formStyle.outlinedButton} variant="outlined" onClick={()=>{setProfileEdit(false);setErrors({});setMessage("");setEmailid("");setEmailEdit(false);setMobileNo("");setMobileEdit(false);}} > Cancel</Button>
    
                                </Box>
                            }
                            

                            

                            {message !== "" && <Typography sx={{ alignSelf: "stretch", fontFamily: "inter", fontSize: "16px", fontStyle: "normal", fontWeight: "400", lineHeight: "24px", color: "green" }}>{message}</Typography>}
                        </Box>
                       

                    </Box>
                    {/* <Box component={isLaptop ? Paper : Box} elevation={1} sx={{ padding: "20px", }}>
                        <Box sx={{ display: "flex", flexDirection: 'column', gap: "16px", width: "100%" }}>
                            
                            {/* {errors.userName && <Typography sx={{color:"error.light"}}>{errors.userName}</Typography>} 
                            <Button sx={formStyle.button} variant="contained"  onClick={updateProfile} >Update</Button>
                        </Box>

                        
                    </Box> */}
                    <Box component={isLaptop ? Paper : Box} elevation={1} sx={{ padding: "20px", }}>
                    <Box sx={{ display: "flex", flexDirection: 'column', gap: "16px", width: "100%" }}>
                        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                            <Typography sx={formStyle.label} color="grey.900">Address</Typography>
                            <Typography sx={[formStyle.label, { cursor: "pointer" }]} color="primary" onClick={() => { dispatch(updateAddressModal(true)); dispatch(updateAddress({})); }}>+ Add</Typography>



                            {/* {errors.userName && <Typography sx={{color:"error.light"}}>{errors.userName}</Typography>} */}
                        </Box >
                        <Grid container sx={{ display: "flex" }}>


                            {address?.map(item => {
                                return <Grid xs={12} md={4} sm={6} item sx={{ width: { xs: "100%", sm: "100%", md: "100%", padding: "10px" } }} onClick={() => editAddress(item)}><Box sm={3} item sx={{ border: "1px solid", padding: "12px", borderColor: "grey.500", borderRadius: "10px" }}>
                                    {/* <Typography sx={{ fontWeight: "600",textTransform:"capitalize" }}>{item.address_type}</Typography> */}
                                    <Typography sx={{ fontWeight: "600",textTransform:"capitalize" }}> {item.title},</Typography>
                                    <Typography sx={{ whiteSpace: "pre-wrap" }}>{item.address}, {item.town_city}, {item.state}, {item.country}, {item.pin}.</Typography>
                                    <Typography > Mobile No:{item.mobileno} </Typography>

                                </Box>
                                </Grid>

                            })}
                        </Grid>

                        {/* <Button sx={formStyle.button} variant="contained" >Update</Button> */}
                    </Box>

                </Box>

                  <Box id="kyc-details" ref={kyc_ref} component={isLaptop? Paper :Box} elevation={1} sx={{padding:{xs:"20px",md:"24px"},display:"flex",flexDirection:"column",gap:"16px", animation: isLaptop && !userreponse?.account?.kyc_status && urlParams?.get('scrollto') === "kyc" ? `${glowingBorder} 2s infinite ease-in-out` : '',}}>
                        <Stack sx={{gap:"4px"}}>
                            <Stack direction={"row"} sx={{justifyContent:"space-between",alignItems:"center"}}>
                                <Stack direction="row" sx={{gap:"10px",alignItems:"center"}}>
                                    <Typography sx={{fontFamily:"Poppins,Roboto",fontWeight:500,lineHeight:"24px",fontSize:"16px",letterSpacing:"0.15px",color:"#000000"}}>KYC Details</Typography>
                                    <Box component="img" src={security_png} sx={{width:"20px",height:"20px"}}/>
                                </Stack>
                                <EditOutlined sx={{color:iskyceditable?"grey.700":"primary.light",cursor:iskyceditable?"not-allowed":"pointer"}} onClick={()=>{!kyc_details.mobileNo && setOtpmodal({value:true}) ; kyc_details.mobileNo && setIskyceditable(true) }} />
                            </Stack>
                            <Typography sx={{fontFamily:"Poppins,Roboto",fontWeight:400,fontSize:"14px",lineHeight:"20px",letterSpacing:"0.25px",color:"#000000",width:{xs:"100%",md:"auto"}}}>Complete KYC to verify your identity and enable future edits. Phone number used here will be for KYC/bank updates.</Typography>
                        </Stack>
                            <Stack sx={{width:{xs:"100%",lg:"703px"},gap:"16px"}}>
                                <Stack direction={{xs:"column",md:"row"}} sx={{gap:"16px",justifyContent:"space-between"}}>
                                    <Stack sx={{gap:"8px",width:"100%"}}>
                                        <Typography sx={{fontWeight:400,fontSize:"16px",lineHeight:"24px",color:"#1E293B"}}>Full Name <Typography component="span" sx={{color:"#EF4444"}}>*</Typography></Typography>
                                        <InputBase required value={kyc_details.fullName} disabled={!iskyceditable} sx={formStyle.inputBase} placeholder="Enter your full name" onChange={(e)=>setKycDetails({...kyc_details,fullName:e.target.value})}/>
                                    {kyc_errors?.fullName && <Typography color="red">{kyc_errors.fullName}</Typography>}
                                    </Stack>
                                    <Stack sx={{gap:"8px",width:"100%"}}>
                                        <Typography  sx={{fontWeight:400,fontSize:"16px",lineHeight:"24px",color:"#1E293B"}}>DOB <Typography component="span" sx={{color:"#EF4444"}}>*</Typography></Typography>
                                        {/* <Calendar style={formStyle.inputBase} icon/> */}
                                        <DatePicker disableFuture  value={kyc_details.dob} onChange={(e)=>{setKycDetails({...kyc_details,dob:e})}} disabled={!iskyceditable} className="pan-datepicker" format="DD/MM/YYYY" sx={{"&.pan-datepicker .MuiInputBase-root.MuiOutlinedInput-root":{...formStyle.inputBase,border:"none"}}}/>
                                    {kyc_errors?.dob && <Typography color="red">{kyc_errors.dob}</Typography>}
                                    </Stack>
                                </Stack>
                                {kyc_details.mobileNo && <Stack sx={{gap:"8px"}}>
                                    <Typography sx={{fontWeight:400,fontSize:"16px",lineHeight:"24px",color:"#1E293B"}}>Mobile Number <Typography component="span" sx={{color:"#EF4444"}}>*</Typography></Typography>
                                    <InputBase required value={kyc_details.mobileNo} disabled={true}  sx={formStyle.inputBase} startAdornment={<Typography sx={{pr:"5px"}}>+91</Typography>} placeholder="(Enter your 10 Digit mobile number)" onChange={(e)=>setKycDetails({...kyc_details,mobileNo:e.target.value.replace(/\D/g, '')})}/>
                                    <Typography sx={{fontWeight:400,fontSize:"12px",lineHeight:"16px",color:"#1E293B"}}>Mobile number for OTP verification when updating KYC.
                                    {/* </Typography> */}
                                    or bank information.</Typography>
                                {kyc_errors?.mobileNo && <Typography color="red">{kyc_errors.mobileNo}</Typography>}
                                </Stack>}
                                <Stack sx={{gap:"8px"}}>
                                    <Typography sx={{fontWeight:400,fontSize:"16px",lineHeight:"24px",color:"#1E293B"}}>PAN <Typography component="span" sx={{color:"#EF4444"}}>*</Typography></Typography>
                                    <InputBase inputProps={{style:{textTransform:"uppercase"}}} required value={kyc_details.pan} disabled={!iskyceditable || userreponse?.kyc_details?.pan } sx={{...formStyle.inputBase,textTransform:"uppercase"}}  placeholder="Enter PAN Number" onChange={(e)=>setKycDetails({...kyc_details,pan:e.target.value})}/>
                                {kyc_errors?.pan && <Typography color="red">{kyc_errors.pan}</Typography>}
                                </Stack>
                            {iskyceditable && <Stack direction={"row"} sx={{gap:"16px",justifyContent:"flex-start"}}>
                                    <Button type="submit" sx={{...formStyle.button,color:"#FFFFFF"}} onClick={(e)=>handleKycSubmit(e)}>{isloading? <CircularProgress color="inherit" size={30}/> :"Submit"}</Button>
                                    <Button type="reset" variant="outlined" sx={{...formStyle.button,bgcolor:"unset","&:hover":{bgcolor:"unset"},color:"FFFFFF"}} onClick={()=>handleKycCancel()}>Cancel</Button>
                                </Stack>}
                            </Stack>
                        <Stack></Stack>
                    </Box>
                    <Box component={isLaptop ? Paper : Box} elevation={1} sx={{ padding: "20px", }}>
                   
                   <BankComponent/>
                   </Box>
                    <Box id='transaction_pin' component={isLaptop ? Paper : Box} ref={transpin_ref} elevation={1} sx={{display:"flex", flexDirection:"column", padding: "24px", justifyContent:isLaptop?"space-between":"normal" ,background:"#FFFFFF",gap:"16px", animation: isLaptop && !userreponse?.account?.transaction_pin && urlParams?.get('scrollto') === "transaction_pin" ? `${glowingBorder} 2s infinite ease-in-out` : ''}}>
                    <Box sx={{ display:"flex", gap:"12px",flexDirection:"column"}}>
                        <Box sx={{display:"flex",gap:"10px"}}>
                             <Typography sx={{ fontWeight: 500, fontSize: "16px",lineHeight:"24px",letterSpacing:"0.15px" }} color="grey.900">Security Code</Typography>
                             <Box component='img' src={SheildIcon}></Box>
                        </Box>
                        <Box>
                             <Typography sx={{ fontWeight: 400, fontSize: "14px",lineHeight:"20px",letterSpacing:"0.25px" }} color="grey.900">Set the 6 digit security code for secured transaction</Typography>
                        </Box>
                    </Box>
                    <Box sx={{ display:"flex", gap:"12px",flexDirection:"column"}}>
                        <Box sx={{ display:"flex", gap:"8px",flexDirection:"column"}}>
                             <Typography sx={{ fontWeight: 400, fontSize: "16px",lineHeight:"24px"}} color="grey.900">Transaction PIN <Typography component="span" sx={{color:"red"}}>*</Typography></Typography>
                             <InputBase inputProps={{ maxLength: 6  }}  disabled = {transPinPresent?true:false} sx={formStyle.inputBasePin} value={maskedValue} onChange={(e) =>handleChangeTranspin(e,'transpin')} onBlur={(e)=>handleCheckCharacterLength(e)}  onFocus={handleFocus} onCopy={handleCopy}/>
                             {validations.transPin!=="" && <Typography component="span" sx={{color:"red"}}>{validations.transPin}</Typography>}
                        </Box>
                         {!transPinPresent && <Box sx={{ display:"flex", gap:"8px",flexDirection:"column"}}>
                             <Typography sx={{ fontWeight: 400, fontSize: "16px",lineHeight:"24px"}} color="grey.900">Reconfirm PIN <Typography component="span" sx={{color:"red"}}>*</Typography></Typography>
                             <InputBase inputProps={{ maxLength: 6 }}  sx={formStyle.inputBasePin} value={reconfirmPin} onChange={(e) => handleChangeTranspin(e,'retranspin')} onBlur={(e)=>handleCheckCharacterLength(e)} onCopy={handleCopy}/>
                              {validations.resubmitPin!=="" && <Typography component="span" sx={{color:"red"}}>{validations.resubmitPin}</Typography>}
                              {validations.equalPin!=="" && <Typography component="span" sx={{color:"red"}}>{validations.equalPin}</Typography>}
                             {validations.numberCheck!=="" && <Typography component="span" sx={{color:"red"}}>{validations.numberCheck}</Typography>}
                        </Box>}
                        {/* {transPinPresent && <Typography onClick={(e)=>sendOTP(e,'reSubmit')}  sx={{ fontWeight: 400, fontSize: "14px",lineHeight:"20px",cursor:"pointer"}} color="primary">Forgot Secured Transaction PIN?</Typography>} */}
                        {transPinPresent && <Typography onClick={(e)=>changePin(e)}  sx={{ fontWeight: 400, fontSize: "14px",lineHeight:"20px",cursor:"pointer"}} color="primary">Forgot Secured Transaction PIN?</Typography>}
                    </Box>
                   {!transPinPresent&& <Box sx={{ display:"flex", gap:"16px"}}>
                        <Button variant="contained" disabled={transactionPin&&reconfirmPin?false:true} sx={{bgcolor:transactionPin&&reconfirmPin?"primary.light":"secondary",color:"primary.contrastText",height:"48px",maxWidth:"77px",width:"100%",textTransform: "none"}} onClick={(e)=>sendOTP(e,'transactionpin')}><Typography sx={{fontWeight: 400, fontSize: "16px",lineHeight:"24px"}}>Submit</Typography></Button>
                        <Button variant="outlined" sx={{height:"48px",maxWidth:"77px",width:"100%",textTransform: "none"}} onClick={(e)=>retransClose?handleCancel(e):handleClearPin(e)}><Typography sx={{fontWeight: 400, fontSize: "16px",lineHeight:"24px"}} >Cancel</Typography></Button>
                    </Box>}
                </Box>



                <Box component={isLaptop ? Paper : Box} elevation={1} sx={{display:"flex", flexDirection:isLaptop?"row":"column", padding: "20px", justifyContent:isLaptop?"space-between":"normal" }}>
                <Box sx={{ display:"flex", flexDirection:"column", width:"100%",  minWidth:"314px", gap:"21px"}}>
                <Typography sx={{ fontWeight: 500, fontSize: "22px" }} color="grey.900">Data Privacy and Security</Typography>
                <Typography sx={{ fontWeight: 700, fontSize: "16px" }} color="grey.900">Delete Profile</Typography>
                <Box sx={{ display:"flex", flexDirection:"column", width:"100%",maxWidth:"25%", minWidth:"314px", height:"80px", gap:"8px" }}>
                <Typography sx={{ fontWeight: 500, fontSize: "14px" }} color="grey.900">Reason for deletion</Typography>
                <Box sx={{ display:"flex", flexDirection:"column", }}>
                <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Select Reason</InputLabel> 
                    <Select value={reasonSelect} label= {"Select Reason"} disabled={allow?true:false} onChange={(e) => handleReasonSelect(e)}>
                    <MenuItem value={"No longer using the service"}>No longer using the service</MenuItem>
                    <MenuItem value={"Privacy concerns"}>Privacy concerns</MenuItem>
                    <MenuItem value={"Too many notifications"}>Too many notifications</MenuItem>
                    <MenuItem value={"User experience issues"}>User experience issues</MenuItem>
                    <MenuItem value={"Found a better alternative"}>Found a better alternative</MenuItem>
                    <MenuItem value={"Other Reason"}>Other Reason</MenuItem>
                    </Select>
                </FormControl>
                </Box>
                </Box>
                {
                // message!=""? <Box sx={{ display:"flex", flexDirection:"row", width:"100%", background:"#37BE5F", padding:"12px", gap:"12px"}}>
                // <img src={CheckCirleIcon} style={{width:"32px", height:"32px"}}/>
                // <Typography sx={{ fontWeight: 500, fontSize: "14px" }} color="#FFF">{pmessage}</Typography>   
                // </Box>:
                reasonSelect!=""? <Box sx={{ display:"flex", flexDirection:"row", width:"100%", gap:"12px"}} >
                        <img src={ExcIcon} style={{ width:"24px", height:"24px" }}/>
                        <Typography sx={{ fontWeight: 500, fontSize: "16px" }} color="grey.900">Deleting your profile permanently erases all associated data, including rankings, teams, and levels, and this action cannot be undone.</Typography>
                </Box>:
                allow ? <Box sx={{ display:"flex", flexDirection:"row", width:"100%", gap:"12px"}} >
                        <img src={ExcRedIcon} style={{ width:"24px", height:"24px" }}/>
                        <Typography sx={{ fontWeight: 500, fontSize: "16px" }} color="grey.900">Profile deletion cannot be completed at this time. Please withdraw funds from your MAYI - Wallet and ensure all orders are received before proceeding.</Typography>
                </Box>:<></>}
                </Box>
                {message=="" && <Button variant="contained" sx={{ height: "3rem", textTransform: "none", fontWeight: 600, fontSize: "14px",width:"100%", maxWidth: "200px","&:hover":{bgcolor:allow?"grey.700":"primary.light",color:"primary.contrastText"}, background:allow?"grey":"primary.light", borderRadius:"5px",fontSize:"",textTransform: 'none', marginTop:"130px"}} disable={allow?true:false} onClick={handleAccDelRequest}>{isDloading? <CircularProgress color="inherit" size={30}/> : "Submit for request"}</Button>}
                </Box>
                <OtpTransPin otpModal={otpModal} setOtpmodal = {setOtpmodal}  reTransPinOtp = {reTransPinOtp} setReTransPinOtp={setReTransPinOtp} handleSubmitPin={handleSubmitPin} transPinPresent={transPinPresent} setTranspinPresent={setTranspinPresent} handleClearPin={handleClearPin} sendOTP={sendOTP} time={time} setTime = {setTime} otpNotmatch={otpNotmatch} setOtpNotMatch={setOtpNotMatch} retransClose={retransClose} handleKycSubmit={handleKycSubmit} setkycPresent={setkycPresent} kycPresent={kycPresent} kyc_details={kyc_details} fetchuserdetails={fetchuserdetails} scrollToSection={scrollToSection}
                    extra_data={{
                    "kyc_linked_data": kyc_linked_data
                    }}
                />
                </Box>
                {/* <Box component={isLaptop ? Paper : Box} elevation={1} sx={{ padding: "20px", }}>
                        <Box sx={{ display: "flex", flexDirection: 'column', gap: "16px", width: "100%" }}>
                            <Box>
                                <Typography sx={formStyle.label} color="grey.700">Contact Number</Typography>
                                <Box sx={{ display: "flex", alignItems: "center" }}>

                                    {/* <Typography sx={{}} color="">+91</Typography> 
                                    <InputBase value="+91" disabled sx={{ border: "1px solid", borderColor: "grey.400", width: "60px", borderRadius: "5px", height: "48px", fontSize: "16px", padding: "14px", color: "grey.700" }}> </InputBase>
                                    <InputBase sx={formStyle.inputBase} value={mobile} onChange={(e) => dispatch(updateUserName(e.target.value))} />
                                </Box>
                                {/* {errors.userName && <Typography sx={{color:"error.light"}}>{errors.userName}</Typography>} 
                            </Box>

                            <Button sx={formStyle.button} variant="contained" onClick={updateMobile}>Update</Button>
                        </Box>

                    </Box> */}
                


                {/* <Box sx={{ display: "flex", flexDirection: "column" }}>
                    
                </Box> */}
            </Box>
            <Modal open={verification} sx={{}}>
            <Box sx={{ width: isMobile ? "calc(100% - 64px)" : "380px", background: "#fff", left: "50%", top: "50%", position: "absolute", transform: 'translate(-50%, -50%)', padding: "32px", border: "none",display:"flex",justifyContent:"center",flexDirection:"column",alignItems:"center" }}>
                <Box sx={{display:"flex",justifyContent:"space-between",width:"100%"}}>
                <Typography sx={{fontWeight:"600",color:"primary.light",lineHeight:"24px"}}>Verify Your Mobile Number</Typography>
                <CloseIcon onClick={()=>setVerification(false) } sx={{cursor:"pointer"}} />
                </Box>
                {
                    verify=="mobile"?
               
                <Typography sx={{fontSize:"14px",lineHeight:"20px"}}>A verification code has been sent to your changed Mobile no {maskedmobileno}</Typography>:
                <Typography sx={{fontSize:"14px",lineHeight:"20px"}}>A verification code has been sent to your changed Email {maskedEmail}</Typography> }
                <Typography sx={{fontSize:"14px",lineHeight:"20px",marginTop:"16px"}}>Please enter the code below to add your bank account.</Typography>
                <MuiOtpInput gap={isMobile?.1:1} sx={{marginTop:"16px"}} value={otp} length={6} onKeyDown={(e)=>handleKeyPress(e)} onChange={(value)=>setOtp(value)}/>
               {/* {otpNotmatch  ?<Typography sx={{ fontWeight: 400, fontSize: "12px",lineHeight:"16px"}} color="error">Invalid code. Please enter the valid code.</Typography>: !time==0&&<Typography sx={{ fontWeight: 400, fontSize: "12px",lineHeight:"16px",textAlign:"start",width:"100%",}} color="error">Resend Code ({formatTime(time)} remaining)</Typography>} */}
                    <Box sx={{margin:"4px 0px",alignSelf:"flex-start"}}>
                    {otpNotmatch  ?<Typography sx={{ fontWeight: 400, fontSize: "12px",lineHeight:"16px"}} color="error">Invalid code. Please enter the valid code.</Typography>: !time==0&&<Typography sx={{ fontWeight: 400, fontSize: "12px",lineHeight:"16px",textAlign:"start",width:"100%",}} color="error">Resend Code ({formatTime(time)} remaining)</Typography>}

                    </Box>
                
               <Box sx={{ display:"flex", justifyContent:"space-between",width:"100%"}}>
                        <Button variant="contained" sx={{bgcolor:"primary.light",color:"primary.contrastText",height:"48px",maxWidth:"77px",width:"100%",textTransform: "none"}} onClick={handleComplete}><Typography sx={{fontWeight: 400, fontSize: "16px",lineHeight:"24px"}}>{isloading? <CircularProgress color="inherit" size={30}/> : "Submit"}</Typography></Button>
                        <Button disabled={!resend || time!==0 ?true:false} sx={{bgcolor:"transparent",border:"none",outline:"none",textTransform:"none"}} onClick={(e)=>handleResendOtp(e)}><Typography sx={{fontWeight: 400, fontSize: "16px",lineHeight:"24px",}} color={resend&& time==0 ?"primary":"#BFC7D4"}>Resend OTP</Typography></Button>
            </Box>
               
            </Box>
            </Modal>
        </Box>
      
           
    )

}
export default Profile