
import { Box } from "@mui/material"

import AdminSidebar from "../components/adminSideBar"
import Header from "../components/header"
import Header1 from "../components/header1"
import CommisionHistoryComponent from "../components/commisionHistoryComponent"
import MessageBar from "./messageBar"


const CommisionHistory = () => {
    return (<>
        <Box sx={{ bgcolor: { sm: "grey.100", xs: "#fff" }, minHeight: "calc(100vh - 80px)" }}>
            <MessageBar/>
            <Header1 />
            <Box sx={{ display: "flex", paddingTop: "144px",gap:"24px",mx:"24px" , position:"relative"}}>
                <AdminSidebar />
                <CommisionHistoryComponent />
            </Box>
        </Box>
    </>)

}
export default CommisionHistory