
import { Box, Paper, Button, Typography, InputBase, Divider, Grid, RadioGroup, Radio, FormControlLabel, Accordion, AccordionSummary, AccordionDetails, Stack, CircularProgress, Skeleton, Modal, Tooltip } from "@mui/material"
import Layout from "../layout"
import { useMediaQuery } from "react-responsive";
import { formStyle } from "./profile";
import { updateUserName, updateMobile, updateAddressModal, updateAddress, updatePackage, updateAllAddress, updateTypeEnable,updateGetBank,updateAccumulated,updateWithdrawRequest } from "../redux/user_reducer";
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import ClearIcon from '@mui/icons-material/Clear';
import axios from "axios";
import CustomAxios from '../utils/CustomAxios';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { useNavigate, useNavigation } from "react-router-dom";
import MessageBar from "../modules/messageBar";
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import { updateSnackBarMessage, updateSnackBarOpen, updateSnackBarSeverity } from "../redux/snackbar";
import CloseIcon from '@mui/icons-material/Close';
import prodOne from "../assets/prodOne.png"
import { updateCart } from "../redux/products";
import { inputStyles } from "../forms/signup_form";
import razorpayicon from "../assets/Mayi_Payment.svg"
import upi from "../assets/upi-icon.png"
import card from "../assets/credit-card-color-icon.png"
import netbank from "../assets/bank-deposit-icon.png" 
import { load } from "@cashfreepayments/cashfree-js";
import Footer from "../components/footer";
import { updateMemberResponse } from "../redux/user_reducer";
import { InfoOutlined } from "@mui/icons-material";
import AddIcon from '@mui/icons-material/Add';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import React from "react";
import {Drawer} from "@mui/material";
const Checkout = () => {
  const cartSidebar=useSelector(state=>state.sideBar.cartSidebar)
  const cart = useSelector(state => state.product.cart)
  const [subTotal, setSubtotal] = useState(0.00)
  const [totalTax, setTotalTax] = useState(0.00)
  const [total, setTotal] = useState(0.00)
  const [showingTotal,setShowingTotal]=useState(0)
  const [userreponse, setUserResponse] = useState({})
  const [userid, setUserId] = useState('')
  const [selectedpackage, setSelectedPackage] = useState("")
  const address = useSelector(state => state.user.allAddress)
  const userpackage = useSelector(state => state.user.package)
  const id = useSelector(state => state.user.id)
  const [expanded, setExpanded] = useState("panel1");
  const [shippingAddress,setShippingAddress]=useState()
  const [deliveryCharge,setDeliveryCharge] = useState(0.00)
  const [isLoading,setIsLoading] = useState(false)
  const [courier_details,setCourier_details] = useState(null)
  const [deliveryType,setDeliveryType]=useState(2)
  const [pickup,setPickup]=useState([])
  const [orderPlaceDisabled,setOrderPlaceDisabled]=useState(false)
  const [openCoupon, setOpenCoupon] = useState(false)
  const userReponse = useSelector(state => state.user.member_response)
  const [selectedVoucher, setSelectedVoucher] = useState()
  const [discount,setDiscount]=useState(0)
  let handling_fee = 2.31/100
  // let platform_fee = 1.5/100
  let platform_fee = 0
  const editAddress = (item) => {
    dispatch(updateAddress(item))
    dispatch(updateAddressModal(true))

  }
  const navigate = useNavigate()
  const [shipping, setShipping] = useState()
  const [billing, setBilling] = useState()
  const [errors, setErrors] = useState({})
  const [paymentMethod,setPaymentMethod]=useState('')
  const [deliveryWaiveLimit,setDeliveryWaiveLimit] = useState(null)
  const clearCart = (item) => {
    const prevCart = [...cart]
    // // console.log()
    const newCart = prevCart.filter(ct => ct.product?.id !== item.product?.id)
    CustomAxios.delete(`cart/${item.id}/`)

    // // console.log(newCart)/
    dispatch(updateCart(newCart))
    localStorage.setItem("cart", JSON.stringify(newCart))
    // // console.log( localStorage.setItem("cart",newCart))
    // // console.log()
  }
  // useEffect(() => {
  //   if (address.filter(item => item.address_type == "billing").length == 1) {
  //     // console.log(address.filter(item => item.address_type == "billing")[0].id)
  //     setBilling(address.filter(item => item.address_type == "billing")[0].id)
  //   }
  //   if (address.filter(item => item.address_type == "shipping").length == 1) {
  //     setShipping(address.filter(item => item.address_type == "shipping")[0].id)
  //   }
  // }, [address])

  useEffect(() => {
    //// console.log(id)
    setUserId(id)
  }, [id])
  useEffect(() => {

    fetchuserdetails()
  }, [userid])
  const fetchuserdetails = async () => {
    // console.log("id", id)
    const payload = {
      user_id: userid
    }
    await CustomAxios.post(`get_details/`, payload).then(response => {
      // console.log(response.data)
      setUserId(response.data.user.id)
      dispatch(updateUserName(response.data.user.username))
      setUserResponse(response.data)
      updateMemberResponse(response.data)
      dispatch(updateAllAddress(response.data?.add))
      dispatch(updateAccumulated(response.data.accumulated))
      dispatch(updateGetBank(response.data.bankdetails))
      dispatch(updateWithdrawRequest(response.data.withdrawRequest))
    }
    ).catch(error => {
      // console.log(error)
    })
  }

  useEffect(() => {
    // console.log(userpackage)
    setSelectedPackage(userpackage)
  }, [userpackage])
  useEffect(() => {
    if (cart.length > 0) {


      let total = cart.reduce((a, b) => a + parseFloat((parseFloat(b.product?.selling_price) + Math.round( parseFloat(b.product?.selling_price) * (parseFloat(b.product?.tax) / 100)))) * b.quantity, 0)
      // // console.log(cart)
      // // console.log(total * 0.12)
      let tax = cart.reduce((a, b) => a +( Math.round(parseFloat(b.product?.selling_price) * (parseFloat(b.product?.tax) / 100)) * b.quantity).toFixed(2), 0)
      // console.log(tax)

      // setSubtotal(parseFloat(total + tax).toFixed(2))
      // setSubtotal(parseFloat(total).toFixed(2))
      // setTotal((parseFloat(total * (selectedVoucher.voucher.offer_percentage / 100))).toFixed(2))
      setShowingTotal(total.toFixed(2))
      setTotalTax(parseFloat(tax).toFixed(2))
      if (selectedVoucher) {
        console.log(selectedVoucher, (parseFloat(total * (selectedVoucher.voucher.offer_percentage / 100))).toFixed(2))
        let min_offer=(parseFloat(selectedVoucher.voucher.min_purchase * (selectedVoucher.voucher.offer_percentage / 100))).toFixed(2)
        let actual=(parseFloat(total * (selectedVoucher.voucher.offer_percentage / 100))).toFixed(2)
        setTotal(min_offer<actual? parseFloat(total)-parseFloat(min_offer):parseFloat(total)-parseFloat(actual))
        setSelectedVoucher(total<selectedVoucher.min_purchase? null:selectedVoucher)
        setDiscount(total<selectedVoucher.min_purchase  ? 0: min_offer<actual ?min_offer :actual )
        setSubtotal(min_offer<actual? parseFloat(total)-parseFloat(min_offer):parseFloat(total)-parseFloat(actual))
        getDelivery_charge(min_offer<actual? parseFloat(total)-parseFloat(min_offer):parseFloat(total)-parseFloat(actual))
      }
      else {
        setTotal(parseFloat(total).toFixed(2))
        setSubtotal(parseFloat(total).toFixed(2))
        getDelivery_charge(total, tax, null, null)
      }
      // console.log("-------from cart useeffect-----------"
    }
    else {
      setIsLoading(true)

    }
  }, [cart, selectedVoucher])
  const dispatch = useDispatch()
  const isMobile = useMediaQuery({ maxWidth: 600 });
  const isLaptop = useMediaQuery({ query: '(min-width: 900px)' });
  const loadScript = () => {
    const script = document.createElement("script");
    script.src = "https://checkout.razorpay.com/v1/checkout.js";
    document.body.appendChild(script);
  };

  const CalculateTaxFreetotal = ()=>{
    const total = cart.reduce((a, b) => a + parseFloat( parseFloat(b.product?.selling_price) * b.quantity), 0).toFixed(2)
    const tax = cart.reduce((a, b) => a + ( Math.round(parseFloat(b.product?.selling_price)  * (b.product?.tax / 100))* b.quantity), 0)
    return {"total":total,"tax":tax}
  }

  const handle_Cash_Pickup = ()=>{
    let bodyData = new FormData();
    let values = CalculateTaxFreetotal()
    bodyData.append("amount", parseFloat(values?.total).toFixed(2));
    bodyData.append("order", JSON.stringify(cart))
    bodyData.append("tax", parseFloat(values?.tax).toFixed(2))
    bodyData.append("total_amount", parseFloat(subTotal).toFixed(2))
    bodyData.append("billing", shipping)
    bodyData.append("platform_fee",(total * platform_fee).toFixed(2))
    bodyData.append("transaction_fee",0.00)
    bodyData.append("shipping", shipping)
    bodyData.append('user', id)
    bodyData.append('delivery_charge', parseFloat(deliveryCharge))
    bodyData.append("paymentmethod", "cash")
    bodyData.append('package_type', selectedpackage)
    bodyData.append('discounts', selectedVoucher? discount:parseFloat(0.00))
    bodyData.append('voucher_allocation', selectedVoucher?.id)
    CustomAxios.post("cash-pickup/",bodyData).then((res)=>{
      let data = res.data
      if(data.error){
        dispatch(updateSnackBarMessage("Something went wrong."))
        dispatch(updateSnackBarSeverity("error"))
        dispatch(updateSnackBarOpen(true))
      }
      else{
        navigate("/orderstatus")
        setTotal("");
      }
    }).catch((err)=>{
      dispatch(updateSnackBarMessage("Contact Customer Support."))
      dispatch(updateSnackBarSeverity("error"))
      dispatch(updateSnackBarOpen(true))
    })
  }

  const handle_COD = ()=>{
    let bodyData = new FormData();
    getDelivery_charge(total,totalTax,null,null)
    let values = CalculateTaxFreetotal()
    bodyData.append("amount", parseFloat(values?.total).toFixed(2));
    bodyData.append("order", JSON.stringify(cart))
    bodyData.append("tax", values?.tax)
    bodyData.append("total_amount", parseFloat(subTotal).toFixed(2))
    bodyData.append("shipping", shipping)
    bodyData.append("billing", shipping)
    bodyData.append("platform_fee",(total * platform_fee).toFixed(2))
    bodyData.append("transaction_fee",0.00)
    bodyData.append('user', id)
    bodyData.append('delivery_charge', parseFloat(deliveryCharge))
    bodyData.append("paymentmethod", "cod")
    bodyData.append('package_type', selectedpackage)
    bodyData.append('courier_id',courier_details?.courier_company_id)
    bodyData.append('courier_name',courier_details?.courier_name)
    bodyData.append('est_delivery_date',courier_details?.etd)
    bodyData.append('discounts', selectedVoucher? discount:0)
    bodyData.append('voucher_allocation', selectedVoucher?.id)
    CustomAxios.post("cod/",bodyData).then((res)=>{
      let data = res.data
      if(data.error){
        dispatch(updateSnackBarMessage("Something went wrong."))
        dispatch(updateSnackBarSeverity("error"))
        dispatch(updateSnackBarOpen(true))
      }
      else{
        navigate("/orderstatus")
        setTotal("");
      }
    }).catch((err)=>{
      dispatch(updateSnackBarMessage("Contact Customer Support."))
      dispatch(updateSnackBarSeverity("error"))
      dispatch(updateSnackBarOpen(true))
    })
  }


  const handle_MayiCash = ()=>{
    let bodyData = new FormData();
    getDelivery_charge(total,totalTax,null,null)
    let values = CalculateTaxFreetotal()

    bodyData.append("amount", parseFloat(values?.total).toFixed(2));
    bodyData.append("order", JSON.stringify(cart))
    bodyData.append("tax", values?.tax.toFixed(2))
    bodyData.append("total_amount", parseFloat(subTotal).toFixed(2))
    bodyData.append("shipping", shipping)
    bodyData.append("billing", shipping)
    bodyData.append("platform_fee",(total * platform_fee).toFixed(2))
    bodyData.append("transaction_fee",0.00)
    bodyData.append('user', id)
    bodyData.append('delivery_charge', parseFloat(deliveryCharge))
    bodyData.append("paymentmethod", "mayicash")
    bodyData.append('package_type', selectedpackage)
    bodyData.append('courier_id',courier_details?.courier_company_id)
    bodyData.append('courier_name',courier_details?.courier_name)
    bodyData.append('est_delivery_date',courier_details?.etd)
    bodyData.append('discounts', selectedVoucher? discount:0)
    bodyData.append('voucher_allocation', selectedVoucher?.id)

    CustomAxios.post("mayicash/",bodyData).then((res)=>{
      let data = res.data
      if(data.error){
        dispatch(updateSnackBarMessage(data.error))
        dispatch(updateSnackBarSeverity("error"))
        dispatch(updateSnackBarOpen(true))
      }
      else{
        navigate("/orderstatus")
        setTotal("");
      }
    }).catch((err)=>{
      dispatch(updateSnackBarMessage("Contact Customer Support."))
      dispatch(updateSnackBarSeverity("error"))
      dispatch(updateSnackBarOpen(true))
    })


  }
  const couponChoose = () => {
    setOpenCoupon(!openCoupon)
  }


  const placeOrder = () => {
    setOrderPlaceDisabled(true)
    let error = {}
    let valid = true
    // if (!shipping) {
    //   error.shipping = "shipping address required"
    //   valid = false

    // }
    if (!shipping && address.length>0) {
      error.shipping = " Please Select Your Delivery Address"
      valid = false
    }
    else if(!shipping && address.length==0){
      error.shipping = " Please Add Your Delivery Address"
      valid = false
    }
    // if (!selectedpackage && shipping) {
    //   error.package = "Please select a package before placing order"
    //   valid = false
    // }
    if (paymentMethod=='' && shipping ) {
      error.package = "Please select PaymentMethod"
      valid = false
    }
    setErrors(error)
    // console.log(shipping)

    if (valid) {
      // console.log(deliveryType,paymentMethod)
      switch (paymentMethod) {
        case "cash":
          handle_Cash_Pickup()
          break;
        case "cod":
          handle_COD()
          break;
        case "mc":
          handle_MayiCash()
          break;
        default:
          showRazorpay()
          break;
      }
      // if(paymentMethod === "cash" ){
      //   handle_Cash_Pickup()
      // }
      // else{
      //   showRazorpay()
      // }

    }
  }
  // // console.log(data)
  useEffect(()=>{
    dispatch(updatePackage(null))
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    // dispatch(updatePayment)

    // console.log("addresslenght ",address.length)
    if (address.length>0 && address?.length<=1 && deliveryType==2){
      // console.log(address[0].id)
      setShipping(address[0].id)
      setExpanded("panel2")
      setShippingAddress(address[0])
    }
  },[address])
  const showRazorpay = async () => {


    const res = await loadScript();

    let bodyData = new FormData();
    // getDelivery_charge(total,totalTax,null,null)
    // we will pass the amount and product name to the backend using form data
    // console.log("totaltax",totalTax)
    let values = CalculateTaxFreetotal()
    bodyData.append("amount", parseFloat(values?.total).toFixed(2));
    bodyData.append("order", JSON.stringify(cart))
    bodyData.append("tax", values?.tax)
    bodyData.append("total_amount", parseFloat(subTotal).toString())
    bodyData.append("shipping", shipping)
    bodyData.append("billing", shipping)
    bodyData.append("platform_fee",((total) * platform_fee).toFixed(2))
    bodyData.append("transaction_fee",((parseFloat(total)+parseFloat(deliveryCharge)) * handling_fee).toFixed(2))
    bodyData.append('user', id)
    bodyData.append('delivery_charge', parseFloat(deliveryCharge))
    bodyData.append("paymentmethod", paymentMethod)
    bodyData.append('discounts', selectedVoucher? discount:0)
    bodyData.append('voucher_allocation', selectedVoucher?.id)
    // bodyData.append("name", name);

    const data = await CustomAxios({
      url: `start_payment/`,
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: bodyData,
    }).then((res) => {
      return res;
    });
    // console.log(data)
    let cashfree;
    // var initializeSDK = async function () {          
    cashfree = await load({
              mode: "production"
    });
    // }
    // initializeSDK();

    // const doPayment = async () => {
    let checkoutOptions = {
      paymentSessionId: data.data.payment.payment_session_id,
      redirectTarget: "_modal",
    };
    cashfree.checkout(checkoutOptions).then((result) => {
              if(result.error){
        // This will be true whenever user clicks on close icon inside the modal or any error happens during the payment
                  // console.log("User has closed the popup or there is some payment error, Check for Payment Status");
                  // console.log(result.error);
        setOrderPlaceDisabled(false)
      }
              if(result.redirect){
        // This will be true when the payment redirection page couldnt be opened in the same window
        // This is an exceptional case only when the page is opened inside an inAppBrowser
        // In this case the customer will be redirected to return url once payment is completed
                  // console.log("Payment will be redirected");
      }
              if(result?.paymentDetails?.paymentMessage=="Payment finished. Check status."){
        // This will be called whenever the payment is completed irrespective of transaction status
        // console.log("Payment has been completed, Check for Payment Status",result.paymentDetails.paymentMessage);
        // console.log(result);
        // if()
        // handlePaymentSuccess(data.data.payment);
        navigate("/orderstatus")
                setTotal("");}
    });
    // in data we will receive an object from the backend with the information about the payment
    //that has been made by the user

    // var options = {
    //   // key_id: "rzp_test_sFIXxCGfxHis41", // in react your environment variable must start with REACT_APP_
    //   // key_secret: "2ee8A8EcXcHmHep3aLdacAY5",
    //   key_id: process.env.REACT_RAZORPAY_KEY_ID,
    //   key_secret: process.env.REACT_RAZORPAY_KEY_SECRET_ID,
    //   amount: data.data.payment.amount,
    //   currency: "INR",
    //   name: "MAYI IQ",
    //   description: "Thank You for Your Purchase!",
    //   image: "", // add image url
    //   order_id: data.data.payment.id,
    //   handler: function (response) {
    //     // we will handle success by calling handlePaymentSuccess method and
    //     // will pass the response that we've got from razorpay
    //     handlePaymentSuccess(response);
    //   },
    //   prefill: {
    //     name: "User's name",
    //     email: "User's email",
    //     contact: "User's phone",
    //   },
    //   notes: {
    //     address: "Razorpay Corporate Office",
    //   },
    //   theme: {
    //     color: "#27A2FD",
    //   },
    // };


    // };
    // var rzp1 = new window.Razorpay(options);
    // rzp1.open();



  };
  const handlePaymentSuccess = async (response) => {
    try {
      let bodyData = new FormData();

      // we will send the response we've got from razorpay to the backend to validate the payment
      bodyData.append("response", JSON.stringify(response));
      // bodyData.append("order", JSON.stringify(cart))
      bodyData.append('package_type', selectedpackage)
      bodyData.append('courier_id',courier_details?.courier_company_id)
      bodyData.append('courier_name',courier_details?.courier_name)
      bodyData.append('est_delivery_date',courier_details?.etd)
      bodyData.append("paymentmethod", paymentMethod)
      // console.log(JSON.stringify(response))
      await CustomAxios({
        url: `razorpay/payment/success/`,
        method: "POST",
        data: bodyData,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then((res) => {
          // console.log("Everything is OK!");
          //   setName("");
          navigate("/orderstatus")
          setTotal("");
        })
        .catch((err) => {
          // console.log(err);
        });
    } catch (error) {
      // console.log(console.error());
    }
  };

  const handleCopyurl = () => {
    const url = process.env.REACT_APP_URL + `signup/?code=${userreponse?.account?.referral_code}`
    navigator.clipboard.writeText(url)
      .then(() => {
        dispatch(updateSnackBarMessage("URL copied to clipboard!"))
        dispatch(updateSnackBarSeverity("success"))
      })
      .catch((error) => {
        dispatch(updateSnackBarMessage(error))
        dispatch(updateSnackBarSeverity("error"))
      });
    dispatch(updateSnackBarOpen(true))
  }
  useEffect(()=>{
    getDelivery_charge(total,totalTax)
    // handle_PaymentMethodChange(paymentMethod)
  },[deliveryType])
        useEffect(()=>{
    // console.log(paymentMethod,deliveryType)
    getDelivery_charge(total,totalTax,null,paymentMethod)
    // handle_PaymentMethodChange(paymentMethod)

  },[paymentMethod])

  const getDelivery_charge = (total,tax,pin=null,p_method=null)=>{
    setIsLoading(true)
    let incoming_tax = 0.00
    // let isdeliveryfree = false
    // console.log("-------------------coming for delivery charge----------",shippingAddress,cart,pin)
    if(total && deliveryWaiveLimit && parseFloat(total) >= parseFloat(deliveryWaiveLimit)){
      // console.log("-----coming for delivery calc--------",total,deliveryWaiveLimit,parseFloat(total * platform_fee))
      let delivery = 0
      setDeliveryCharge(delivery.toFixed(2))
      if(paymentMethod === "cashfree"){
        setSubtotal((parseFloat(total) + parseFloat(incoming_tax) + delivery + (parseFloat(total) * (platform_fee+handling_fee))).toFixed(2))
      }
      else{
        setSubtotal( (parseFloat(total) + parseFloat(incoming_tax) + delivery + (parseFloat(total)*platform_fee)).toFixed(2) )
      }
      // setIsLoading(false)
      // return
    }
    if((shippingAddress?.pin || pin) && cart && cart.length>=1 && deliveryType==2){
      let order_weight = 0
        cart.map((prod)=> {
        let weight = prod.product.unit.match(/\d+/)[0]
        order_weight += (parseInt(prod.quantity) * parseInt(weight))
      })
      let payload = JSON.stringify({
        "pickup_postcode": "600097",
          "delivery_postcode":pin || shippingAddress.pin,
          "weight":order_weight/1000,
          "cod": p_method ==="cod"?true:false,
          "total":subTotal
      })
      // // console.log("-------payload-------",payload)
        CustomAxios.post("get-delivery-charge/",payload).then((res)=>{
        let data = res.data
        let error = {}
          if(data.error){
          error.addressError = " Please Add valid Delivery Address"
          setErrors(error)
          dispatch(updateSnackBarMessage("Invalid Pincode"))
          dispatch(updateSnackBarSeverity("error"))
          dispatch(updateSnackBarOpen(true))
          // console.error(data.error)
        }
          else if(data.courier_company_id){
          setErrors({})
          setCourier_details(data)
            let delivery = (parseFloat(data.freight_charge + data.cod_charges) ).toFixed(2)
          setDeliveryCharge(delivery)
          // console.log(total,tax,delivery,handling_fee)
          // if(paymentMethod === "cod"){
          // }
            if(paymentMethod === "cashfree"){
              setSubtotal((parseFloat(total) + parseFloat(incoming_tax) + parseFloat(delivery) + parseFloat((parseFloat (total)+parseFloat(delivery)) * (platform_fee+ handling_fee))).toFixed(2))
          }
            else{
            setSubtotal((parseFloat(total) + parseFloat(incoming_tax) + parseFloat(delivery) + parseFloat(total * (platform_fee))).toFixed(2))

          }
        }
        setIsLoading(false)
        }).catch((err)=>{
        console.error(err)
        // setIsLoading(false)
        dispatch(updateSnackBarMessage("  Failed  "))
        dispatch(updateSnackBarSeverity("error"))
        dispatch(updateSnackBarOpen(true))
      })
    }
    else{
      let delivery = 0
      setDeliveryCharge(parseFloat(0).toFixed(2))
      if(paymentMethod === "cashfree"){
        setSubtotal((parseFloat(total) + parseFloat(incoming_tax) + parseFloat(delivery) + parseFloat((parseFloat(total)+parseFloat(delivery)) * (platform_fee+handling_fee))).toFixed(2))
      }
      else{
        setSubtotal((parseFloat(total) + parseFloat(incoming_tax) + parseFloat(delivery) + (parseFloat(total) * platform_fee)).toFixed(2))
      }
      setIsLoading(false)
    }
  }





  const updateQuantity = async (object, condition) => {

    let products = [...cart]
    const newCart = products.map(item => {
      if (condition && item.product?.id == object.product?.id) {
        // console.log("if condition")
        let updated = { ...item }
        updated.quantity += 1

        if (item.id) {
          CustomAxios.patch(`cart/${item.id}/`, {
            quantity: updated.quantity,
            totalprice:updated.quantity*(parseFloat(updated.price)+ Math.round(updated?.price * (updated.product?.tax/100) ) )
          })
          return updated
        }
        else {

          return updated

        }

      }
      else if (!condition && item.product?.id == object.product?.id) {

        let updated = { ...item }
        updated.quantity -= 1

        if (item.id && updated.quantity >= 1) {
          // console.log(updated.quantity)
          CustomAxios.patch(`cart/${item.id}/`, {
            quantity: updated.quantity,
            totalprice:updated.quantity*(parseFloat(updated.price)+ Math.round(updated?.price * (updated.product?.tax/100) ) )
          })
          return updated
        }
        else if (item.id && updated.quantity < 1) {
          CustomAxios.delete(`cart/${item.id}/`)
          return updated
        }
        else {

          return updated

        }



      }
      else {
        return item

      }

    })

    // console.log(newCart)
    // setProduct(products)
    dispatch(updateCart(newCart.filter((item) => !item.quantity < 1)))
    localStorage.setItem("cart", JSON.stringify(newCart.filter((item) => !item.quantity < 1)))
    // setIsLoading(true)
    dispatch(updateSnackBarMessage("Successfully Updated the  Cart!"))
    dispatch(updateSnackBarSeverity("success"))

    dispatch(updateSnackBarOpen(true))
  }
  useEffect(()=>{
    if (deliveryType==1){
      CustomAxios.get("pickup-address/").then(res=>{
        // console.log(res)
        setPickup(res.data.pickupadddress)})

    }
  },[deliveryType])
  const handleChange = (panel) => (event, isExpanded) => {
    // console.log(userpackage)
    if(panel=="panel1"){
      setExpanded(isExpanded ? panel:'panel1') ;
    }

    else if(panel=='panel2'){
      if(!shippingAddress){
        deliveryType==2?setErrors({addressSelection : "Please Choose Delivery Address" }): setErrors({addressSelection : "Please Choose Pickup Address" })
      }
      else{
        setErrors({addressSelection:""})
        setExpanded(isExpanded && shipping ? panel : 'panel1');
      }
    }
    else if(panel=='panel3'){
      if(!shippingAddress){
        deliveryType==2?setErrors({addressSelection : "Please Choose Delivery Address" }): setErrors({addressSelection : "Please Choose Pickup Address" })
      }
      else{
        setErrors({addressSelection:""})
        setExpanded(isExpanded && paymentMethod?panel:shipping?'panel2':'panel1');
      }
    }
    else if(panel=='panel4'){
      // // console.log(paymentMethod,isExpanded)
      setExpanded( paymentMethod?panel:userpackage?'panel3':shipping?'panel2':'panel1');
    }

  };

  useEffect(()=>{
    if ( userreponse?.account?.referred_by_id==2){
      navigate("/our_product")
    }
  },[userreponse])

  useEffect(()=>{
    CustomAxios.get('get-delivery-settings/1/').then((res)=>{
      let res_data = res.data
        if(res_data.deliverySettings_id){
        setDeliveryWaiveLimit(res_data.waiver_amt)
      }
        else if(res_data.error){
        dispatch(updateSnackBarOpen(true))
        dispatch(updateSnackBarSeverity('error'))
        dispatch(updateSnackBarMessage("Something Went wrong."))
        // setIsLoading(true)

      }
    }).catch((err)=>{
        // console.error(err)
    })
  },[])

  const handle_PaymentMethodChange = (val)=>{

    let total_amt = parseFloat(total) + parseFloat(total * platform_fee)
    switch (val) {
      case "cashfree":
        total_amt += ((parseFloat(total)+parseFloat(parseFloat(deliveryCharge))) * handling_fee)
        if(deliveryType === 2){
          total_amt += parseFloat(deliveryCharge)
        }
        break;
      case "cod":
        total_amt += parseFloat(deliveryCharge)
        break;
      default:
        break;
    }
    
    setSubtotal(total_amt.toFixed(2))


  }

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return new Intl.DateTimeFormat('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    }).format(date);
  };
const seleCoupon=()=>{

  if(!selectedVoucher){
setOpenCoupon(true)
  }
  else{
setOpenCoupon(false)
  }

}
  const applyCoupon=(item)=>{
    // console.log("????????????????????????apply coupon",total,item.min_purchase,item.min_purchase<=total)
    if (parseFloat(item.min_purchase)<=parseFloat(total)){
      setSelectedVoucher(item);
      setOpenCoupon(false)
    }
    else  {
      // console.log("?????????????????vlaue update")
      dispatch(updateSnackBarMessage(`Please purchase minimum ${item.min_purchase} to avail this coupon `))
        dispatch(updateSnackBarSeverity("error"))

        dispatch(updateSnackBarOpen(true))
    }
    
  }
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });


  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };
  
  const list = (anchor) => (
    <Box
      sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250,padding:"0px 12px",maxHeight:"400px",borderRadius:"10px"}}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
      
    >
      {/* <Box sx={{padding:"0px 12px",maxHeight:"400px",borderRadius:"10px"}}> */}

     
       <Box sx={{display:"flex",alignItems:"center",width:"100%",justifyContent :"space-between",bgcolor:"#fff",position:"fixed",padding:"12px 0px"}}>
          <Typography sx={{ fontSize: "16px", lineHeight: "20px", fontWeight: 700 }}>
          Your Available Cash Incentives
          </Typography>
          {/* <CloseIcon sx={{color:'#6F7883',cursor:"pointer"}} onClick={()=>setOpenCoupon(false)} /> */}
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column", marginTop:"48px",padding:"8px 0px" ,gap:"12px"}}>
          {userReponse.voucher?.map(item => <Box sx={{ display: "flex",flexDirection:'row' }}>
            <Box sx={{ bgcolor: "#2DD4BF",  padding: "8px", display: "flex", flexDirection: "column", gap: "4px",alignItems:"center",justifyContent:"center" }}>
              <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", gap: "4px" }}>

                <Typography sx={{ fontSize: "32px", lineHeight: "40px", weight: "700", color: "#fff", }}>{item.voucher.offer_percentage}</Typography>
                <Box>
                  <Typography sx={{ fontSize: "14px", lineHeight: "16px", weight: "700", color: "#fff", }}>%</Typography>
                  <Typography sx={{ fontSize: "14px", lineHeight: "16px", weight: "700", color: "#fff", }}>OFF</Typography>
                </Box>
              </Box>
              <Box>
              <Typography sx={{ fontSize: "12px", lineHeight: "16px", textAlign: "center", color: "#fff" }}>{item.voucher.splitted_text}</Typography>
              </Box>
              <Divider variant="" sx={{ borderStyle: "dashed" }} />
              <Box>
                <Typography sx={{ fontSize: "12px", lineHeight: "16px", textAlign: "center", color: "#fff" }}>
                  Valid Till
                </Typography>
                <Typography sx={{ fontSize: "14px", lineHeight: "16px", textAlign: "center", fontWeight: "700", color: "#fff" }}>
                  {formatDate(item.expired)}
                </Typography>
              </Box>
            </Box>
            <Box sx={{ bgcolor: "#F0FDFA",  width: "100%", padding: "8px", display: "flex", flexDirection: "row", justifyContent: "space-between" }} >

              <Box sx={{display: "flex", flexDirection: "column", justifyContent: "space-between",gap:{xs:"15px",sm:"0px"} }}>
                <Box>
                  <Typography sx={{ fontSize: "16px", lineHeight: "20px", fontWeight: "700", }}>Congratulations! </Typography>
                  <Typography sx={{ fontSize: "12px", lineHeight: "20px", fontWeight: "500", }}>{item.voucher.Description}</Typography>
                </Box>

                <Typography sx={{ fontSize: "10px", lineHeight: "16px", fontWeight: "400", }}>Valid on online orders only. Cannot be combined with other offers.</Typography>

              </Box>
              <Box sx={{ display: "flex", alignSelf: "end" }}>

                <Box sx={{ bgcolor: "#2DD4BF", padding: '4px 8px', borderRadius: "4px",cursor:'pointer' }} onClick={() => applyCoupon(item) }>
                  <Typography sx={{color:"#fff",fontSize:"14px"}} >Apply</Typography>
                </Box>
              </Box>
            </Box>


          </Box>)}
        
          
         
        </Box>
        </Box>
    // </Box>
  );

  return <Stack>

    <Box sx={{overflowY:cartSidebar?"hidden":"auto",height:"100vh", bgcolor: { xs: "#fff", md: "grey.200" }, minHeight: "100vh", display: "flex", alignItems: "start", justifyContent: "center" }}>
      <Layout />
      <MessageBar />
      <Box sx={{ alignSelf: "start", marginTop: { xs: "77px", md: "140px" }, padding: { xs: "20px", md: "0px" }, marginBottom: "100px", justifySelf: "center", display: "flex", flexDirection: { xs: "column", md: "row" }, justifyContent: "center", maxWidth: "1280px", width: "100%", gap: { xs: "0px", md: "36px" } }}>

        <Box elevation={1} sx={{ width: { xs: "100%", md: "60%" }, display: "flex", flexDirection: "column", gap: { xs: "0px", md: "0px" } }}>
          {/* <Box component={isLaptop ? Paper : Box} elevation={1} sx={{ padding: "20px", }}>
                      <Box sx={{ display: "flex", flexDirection: 'column', gap: "16px", width: "100%" }}>
                          <Box>
                              <Typography sx={formStyle.label} color="grey.700">Contact Number</Typography>
                              <Box sx={{ display: "flex", alignItems: "center" }}>

                                  {/* <Typography sx={{}} color="">+91</Typography> 
                                  <InputBase value="+91" disabled sx={{ border: "1px solid", borderColor: "grey.400", width: "60px", borderRadius: "5px", height: "48px", fontSize: "16px", padding: "14px", color: "grey.700", }}> </InputBase>
                                  <InputBase sx={formStyle.inputBase} value={mobile} onChange={(e) => dispatch(updateMobile(e.target.value))} />
                              </Box>
                              {/* {errors.userName && <Typography sx={{color:"error.light"}}>{errors.userName}</Typography>} 
                          </Box>

                          <Button sx={formStyle.button} variant="contained" onClick={updateMobile}>Update</Button>
                      </Box>

                  </Box> */}
          {/* <Box component={isLaptop ? Paper : Box} elevation={1} sx={{ padding: {xs:"10px",sm:"20px"} }}>
                      <Box sx={{ display: "flex", flexDirection: 'column', gap: "16px", width: "100%" }}>
                          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                              <Typography sx={formStyle.label} color="grey.700">Billing Address</Typography>
                              <Typography sx={[formStyle.label, { cursor: "pointer" }]} color="primary" onClick={() =>{dispatch(updateAddressModal(true));dispatch(updateAddress({}))}}>+ Add</Typography>

                              {/* {errors.userName && <Typography sx={{color:"error.light"}}>{errors.userName}</Typography>} 
                          </Box>
                          <Grid container sx={{display:"flex"}}>


                                  {address.filter(item=>item.address_type=="billing").map(item => {
                                      return <Grid xs={12} md={4} sm={6} item sx={{ width: { xs: "100%", sm: "100%", md: "100%", padding: "10px" } }} onClick={()=>setBilling(item.id)}><Box  sm={3} item sx={{ border: "1px solid", padding: "12px",borderColor:billing==item.id ? "primary.light":"grey.500",borderRadius:"10px" }}>
                                          <Box sx={{display:"flex",alignItems:"center",justifyContent:"space-between"}}><Typography sx={{fontWeight:"600",textTransform:"capitalize"}}>{item.address_type}</Typography> <Box sx={{display:"flex",gap:"4px"}}><EditOutlinedIcon sx={{color:"primary.light"}} onClick={()=>editAddress(item)} /></Box> </Box>
                                          <Typography sx={{fontWeight:"500"}}> {item.title},</Typography>
                                          <Typography sx={{whiteSpace:"pre-wrap"}}>{item.address}, {item.town_city}, {item.state}, {item.country}, {item.pin}.</Typography>
                                          <Typography > Mobile No:{item.mobileno} </Typography>

                                      </Box>
                                      </Grid>

                                  })}
                              </Grid>
                              
                              {errors.billing && <Typography sx={{ color: "error.light" }}>{errors.billing}</Typography>}

                          {/* <Button sx={formStyle.button} variant="contained" >Update</Button> 
                      </Box>

                  </Box> */}
          <Accordion  expanded={expanded=="panel1"} onChange={handleChange('panel1')}>

            <AccordionSummary
              // expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"

            >
              <Box  sx={{display:"flex",flexDirection:{xs:"column",sm:"row"},justifyContent:"space-between",width:"100%"}}>
                              <Box sx={{width:{xs:"100%",sm:"50%",display:"flex",flexDirection:"column" ,gap:"32px"}}}>
                  <Typography sx={{ fontSize: "16px", fontWeight: "600", }} color="grey.800"> <span>1.</span> Delivery / Pickup ?</Typography>
                              {expanded !== 'panel1' && shipping&& <Typography sx={[formStyle.label, { cursor: "pointer" }]} color="primary" > {deliveryType==2? "Change Delivery Address":"Change Pickup Address"}</Typography>}                            
                </Box>

                              {expanded !== 'panel1' && shipping&& shippingAddress &&<Box sm={3} item sx={{ border: "1px solid", padding: "12px", borderColor: shipping == shippingAddress.id ? "primary.light" : "grey.500", borderRadius: "10px" }}>
                  <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}><Typography sx={{ fontWeight: "600", textTransform: "capitalize" }}> {shippingAddress.title},</Typography><Box sx={{ display: "flex", gap: "4px" }}></Box> </Box>

                  <Typography sx={{ whiteSpace: "pre-wrap" }}>{shippingAddress.address}, {shippingAddress.town_city}, {shippingAddress.state}, {shippingAddress.country}, {shippingAddress.pin}.</Typography>
                  <Typography > Mobile No:{shippingAddress.mobileno} </Typography>
                        {errors.addressError && <Typography sx={{color:"error.light"}}>{errors.addressError}</Typography>}
                </Box>}
                      
              </Box>

            </AccordionSummary>
            <AccordionDetails sx={{ padding: { xs: "10px", sm: "20px" } }}>
              {/* <Box component={isLaptop ? Paper : Box} elevation={1} sx={{ padding: { xs: "10px", sm: "20px" } }}> */}
                <Box sx={{ display: "flex", flexDirection: 'column',  width: "100%" }}>
                <RadioGroup
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="controlled-radio-buttons-group"
                  sx={{ flexDirection: "row" }}
                  value={deliveryType}
                  // sx={{display:"flex"}}
                    onChange={(e) => {setDeliveryType(e.target.value);setErrors({});setShippingAddress();setPaymentMethod('')}}
                  ><FormControlLabel value="2" control={<Radio   />} sx={{ textAlign: "center",color:deliveryType==1&& "grey", fontSize: "12px", "& .MuiFormControlLabel-label": { fontWeight: 700, fontSize: "16px" } }} label="Delivery To Address" />
                    <FormControlLabel value="1" control={<Radio />} sx={{ textAlign: "center",color:deliveryType==2&& "grey", fontSize: "12px", "& .MuiFormControlLabel-label": { fontWeight: 700, fontSize: "16px" } }} label="Pickup From" />

                </RadioGroup>
                  {deliveryType==2&&<Box sx={{ display: "flex", justifyContent: "space-between" }}>
                    <Typography sx={[formStyle.label, { cursor: "pointer" }]} color="primary" onClick={() => { dispatch(updateAddressModal(true)); dispatch(updateAddress({})) }}>+ Add</Typography>

                  {/* {errors.userName && <Typography sx={{color:"error.light"}}>{errors.userName}</Typography>} */}
                </Box>}
                <Grid container sx={{ display: "flex" }}>


                  {deliveryType==2? address.map(item => {
                    return <Grid xs={12} md={4} sm={6} item sx={{ width: { xs: "100%", sm: "100%", md: "100%", padding: "10px" ,cursor:"pointer"},}} onClick={() =>{ setShipping(item.id);setShippingAddress(item);setExpanded("panel2");setErrors({addressSelection:""});getDelivery_charge(total,totalTax,item.pin,null)}}><Box sm={3} item sx={{ border: "1px solid", padding: "12px", borderColor: shipping == item.id ? "primary.light" : "grey.500", borderRadius: "10px" ,"&:hover":{borderColor:"primary.light"} }}>
                      <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}><Typography sx={{ fontWeight: "600", textTransform: "capitalize" }}> {item.title},</Typography><Box sx={{ display: "flex", gap: "4px" }}><EditOutlinedIcon sx={{ color: "primary.light" }} onClick={() => editAddress(item)} /></Box> </Box>

                        <Typography sx={{ whiteSpace: "pre-wrap" }}>{item.address}, {item.town_city}, {item.state}, {item.country}, {item.pin}.</Typography>
                      <Typography > Mobile No:{item.mobileno} </Typography>

                    </Box>
                    </Grid>

                    }):pickup?.map(item => {
                      return <Grid xs={12} md={4} sm={6} item sx={{ width: { xs: "100%", sm: "100%", md: "100%", padding: "10px" ,cursor:"pointer"},}} onClick={() =>{ setShipping(item.id);setShippingAddress(item);setExpanded("panel2");setErrors({addressSelection:""});getDelivery_charge(total,totalTax,item.pin,null)}}><Box sm={3} item sx={{ border: "1px solid", padding: "12px", borderColor: shipping == item.id ? "primary.light" : "grey.500", borderRadius: "10px" ,"&:hover":{borderColor:"primary.light"} }}>
                      <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}><Typography sx={{ fontWeight: "600", textTransform: "capitalize" }}> {item.title},</Typography><Box sx={{ display: "flex", gap: "4px" }}></Box> </Box>

                        <Typography sx={{ whiteSpace: "pre-wrap" }}>{item.address}, {item.town_city}, {item.state}, {item.country}, {item.pin}.</Typography>
                      <Typography > Mobile No:{item.mobileno} </Typography>

                    </Box>
                    </Grid>

                  })}
                </Grid>
                {errors.shipping && <Typography sx={{ color: "error.light" }}>{errors.shipping}</Typography>}
                  {errors.addressSelection && <Typography sx={{color:"error.light"}}>{errors.addressSelection}</Typography>}

                {/* <Button sx={formStyle.button} variant="contained" >Update</Button> */}
              </Box>

              {/* </Box> */}
            </AccordionDetails>
          </Accordion>
          {/* <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
            <AccordionSummary
              // expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <Box  sx={{display:"flex",flexDirection:{xs:"column",sm:"row"},justifyContent:"space-between",width:"100%"}}>
                            <Typography sx={{ fontSize: "16px", fontWeight: "600", }} color="grey.800">2. Membership Package Selection</Typography>
                            {expanded !== 'panel2' && userpackage&&<Typography sx={{ textAlign: "center", fontSize: "16px",fontWeight:700 ,bgcolor:userpackage === "A" ? "#00C561" :
                            userpackage === "B" ? "#0096f0" :
                            userpackage === "C" ? "#f06700" :
                            userpackage === "D" ? "#b200f0" :
                            userpackage === "AA" ? "#0035f0" : "transparent",padding:"8px 12px",borderRadius:"24px",color:"#fff"}}> Package {userpackage} </Typography>}
                            </Box>
            </AccordionSummary>
            <AccordionDetails sx={{ bgcolor: "primary.contrastText", padding: { xs: "10px", sm: "20px" }}}>
              {/* <Box component={isLaptop ? Paper : Box} sx={{ bgcolor: "primary.contrastText", padding: "20px" }}> 


                <Typography sx={{ fontSize: "14px", fontWeight: "500", marginBottom: "20px" }}>Please select the package you want from the list</Typography>
                <Typography sx={{ fontSize: "14px", fontWeight: "400", marginBottom: "20px" }}>Each package (D, C, B, A, AA) offers unique benefits and earnings. <span style={{ color: "rgba(39, 161, 255, 1)", cursor: "pointer", textDecoration: "underline" }} onClick={() => handleCopyurl()}>Refer members</span> and purchase progressively.</Typography>
                {/*  <span style={{ color: "rgba(39, 161, 255, 1)", cursor: "pointer", textDecoration: "underline" }} onClick={() => navigate('/dashboard')}>Earnings accumulate</span> in your respective package upon successful activation. 
                <Box sx={{ borderRadius: "5px", padding: "4px" }}>
                  {/* <Typography sx={{textAlign:"center",fontSize:"12px",fontWeight:"600"}}>Cash On Delivery</Typography> 
                  <RadioGroup
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="controlled-radio-buttons-group"
                    sx={{ flexDirection: "column" }}
                    value={address.address_type}
                    onChange={(e) => {dispatch(updatePackage(e.target.value));setExpanded("panel3")}}
                  >
                    <FormControlLabel value="D" control={<Radio />} sx={{ textAlign: "center", fontSize: "12px", "& .MuiFormControlLabel-label": { fontWeight: 700, fontSize: "16px" } }} label="Package D  : Rs 1,120/-" />
                    <FormControlLabel value="C" control={<Radio />} sx={{ textAlign: "center", fontSize: "12px", "& .MuiFormControlLabel-label": { fontWeight: 700, fontSize: "16px" } }} label="Package C  : Rs 3,360/-" />
                    <FormControlLabel value="B" control={<Radio />} sx={{ textAlign: "center", fontSize: "12px", "& .MuiFormControlLabel-label": { fontWeight: 700, fontSize: "16px" } }} label="Package B  : Rs 11,200/-" />
                    <FormControlLabel value="A" control={<Radio />} sx={{ textAlign: "center", fontSize: "12px", "& .MuiFormControlLabel-label": { fontWeight: 700, fontSize: "16px" } }} label="Package A  : Rs 56,000/-" />
                    <FormControlLabel value="AA" control={<Radio />} sx={{ textAlign: "center", fontSize: "12px", "& .MuiFormControlLabel-label": { fontWeight: 700, fontSize: "16px" } }} label="Package AA : Rs 1,12,000/-" />
                  </RadioGroup>
                {/* </Box> 
                {errors.package && <Typography sx={{ color: "error.light" }}>{errors.package}</Typography>}
              </Box>
            </AccordionDetails>
          </Accordion> */}
           <Accordion expanded={(courier_details?.courier_company_id!==undefined || deliveryType==1 )&& shippingAddress && expanded === 'panel2'} onChange={handleChange('panel2')}>
            <AccordionSummary
              // expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <Box  sx={{display:"flex",flexDirection:{xs:"column",sm:"row"},justifyContent:"space-between",width:"100%"}}>
                              <Typography sx={{ fontSize: "16px", fontWeight: "600",width:{xs:"100%",sm:"50%"} }} color="grey.800"> <span>2.</span>  Payment Method</Typography>
                              {expanded !== 'panel2' &&<Typography sx={{ textAlign: "center", fontSize: "16px",fontWeight:700 }}> {paymentMethod === "cashfree" ?<Box component='img' src={razorpayicon} sx={{width:"100px"}} /> : paymentMethod ==="cod"?"Pay On Delivery": paymentMethod ==="mc"?"MAYI - Pay":paymentMethod ==="cash"?"Cash-Pickup":null}</Typography>}
              </Box>
            </AccordionSummary>
            <AccordionDetails sx={{ padding: { xs: "10px", sm: "20px" } }}>
              {/* <Box component={isLaptop ? Paper : Box} elevation={1} sx={{ padding: { xs: "10px", sm: "20px" } }}> */}
              <Box sx={{ display: "flex", flexDirection: 'column', gap: "16px", width: "100%" }}>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <RadioGroup
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="controlled-radio-buttons-group"
                    sx={{ flexDirection: "column" }}
                    value={paymentMethod}
                    // defaultValue="cashfree"
                    onChange={(e) => {setPaymentMethod(e.target.value);setExpanded("panel3"); handle_PaymentMethodChange(e.target.value)}}
                  >

                                      <FormControlLabel value="cashfree"  control={<Radio />} sx={{ textAlign: "center", fontSize: "12px", display:"flex",alignItems:"center",justifyContent:"center","& .MuiFormControlLabel-label": { fontWeight: 700, fontSize: "16px" } }} label={<Box >
                      {/* <Box component='img' src={razorpayicon} sx={{width:"75px"}} /> */}
                                      <Box component='img' src={upi} sx={{width:"50px",padding:"8px"}} />
                                      <Box component='img' src={card} sx={{width:"40px",padding:"8px"}} />
                                      <Box component='img' src={netbank} sx={{width:"40px",padding:"8px"}} />
                                      </Box> } >

                    </FormControlLabel>
                    <FormControlLabel value="mc" control={<Radio disabled={userreponse?.mc?.balance > subTotal ? false : true} />} sx={{ textAlign: "center", marginTop:"20px",fontSize: "12px", "& .MuiFormControlLabel-label": { fontWeight: 700, fontSize: "16px" } }} 
                      label={<Stack sx={{alignItems:"flex-start"}}>
                        <Typography sx={{fontWeight: 700, fontSize: "16px"}}>MAYI - Pay</Typography>
                        <Typography sx={{fontWeight: 400, fontSize: "12px"}}>Purchase balance: ₹{userreponse?.mc?.balance}</Typography>

                      </Stack>}
                    />
                    {deliveryType ==1 &&<FormControlLabel value="cash" control={<Radio  />} sx={{ textAlign: "center", marginTop:"20px",fontSize: "12px", "& .MuiFormControlLabel-label": { fontWeight: 700, fontSize: "16px" } }} label="Cash On Pickup" />}
                    {/* {deliveryType ==2 &&<FormControlLabel  value="cod" control={<Radio  />} sx={{ textAlign: "center", marginTop:"20px",fontSize: "12px", "& .MuiFormControlLabel-label": { fontWeight: 700, fontSize: "16px" } }} label="Pay On Delivery" />} */}
                    {/* <FormControlLabel value="B" control={<Radio />} sx={{ textAlign: "center", fontSize: "12px", "& .MuiFormControlLabel-label": { fontWeight: 700, fontSize: "16px" } }} label="Package B  : Rs 11,200/-" />
                    <FormControlLabel value="A" control={<Radio />} sx={{ textAlign: "center", fontSize: "12px", "& .MuiFormControlLabel-label": { fontWeight: 700, fontSize: "16px" } }} label="Package A  : Rs 56,000/-" />
                    <FormControlLabel value="AA" control={<Radio />} sx={{ textAlign: "center", fontSize: "12px", "& .MuiFormControlLabel-label": { fontWeight: 700, fontSize: "16px" } }} label="Package AA : Rs 1,12,000/-" /> */}
                  </RadioGroup>

                </Box>
              </Box>
              {/* </Box> */}
            </AccordionDetails>
          </Accordion>
          <Accordion expanded={(courier_details?.courier_company_id!==undefined || deliveryType==1 ) && expanded === 'panel3'} onChange={handleChange('panel3')}>
            <AccordionSummary
              // expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
                    <Typography sx={{ fontSize: "16px", fontWeight: "600",}} color="grey.800"> <span>3.</span>  Review Items and Order </Typography>

            </AccordionSummary>
            <AccordionDetails sx={{ padding: { xs: "10px", sm: "20px" } }}>
              {/* <Box component={isLaptop ? Paper : Box} elevation={1} sx={{ padding: { xs: "10px", sm: "20px" } }}> */}
              <Box sx={{ display: "flex", flexDirection: 'column', gap: "16px", width: "100%" }}>
                <Box sx={{}}>
                  <Box sx={{}}>

                    {cart.length > 0 && cart.map(item => {

                      {/* <ShoppingBagIcon sx={{ color: "primary.light", opacity: "0.7", fontSize: "150px" }} /> */ }
                      return <><Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", padding:"10px" }} >
                        <Box sx={{ display: "flex", alignItems: "center", gap: "15px" }}>

                          <Box component='img' sx={{ width: {xs:"50px",sm:"100px"}, height: {xs:"50px",sm:"100px"} }} src={item?.product?.cover_details?.original || prodOne} />
                          <Box sx={{ display: "flex", flexDirection: "column", }}>
                            <Typography sx={{ fontSize: "14px", fontWeight: "600" }}> {item.product?.name}</Typography>
                            <Typography sx={{ fontSize: "8px", fontWeight: "400", color: "grey.500", displa: "flex", alignItems: "center", justifyContent: "center", fontSize: "12px" }}> {item.quantity}<CloseIcon sx={{ fontSize: "14px", textAlign: "center" }} />{item.product?.unit}</Typography>
                            <Typography sx={{ fontSize: "16px", color: "primary.light", fontWeight: "600", marginTop: "8px", marginBottom: "4px" }}> <CurrencyRupeeIcon sx={{ fontSize: "14px", fontWeight: 600 }} />{(parseFloat(item.product.selling_price) + Math.round(item.product.selling_price * (item.product?.tax/100) )).toFixed(2)}</Typography>

                            <Box sx={{ display: "flex", flexDirection: 'row', gap: "4px", bgcolor: "grey.100", padding: "4px 12px", borderTopRightRadius: "20px", borderTopLeftRadius: "20px", borderBottomLeftRadius: "20px", borderBottomRightRadius: "20px", justifyContent: "space-between", alignItems: "center", maxWidth: "90px",width:"90px" }}>


                              <Typography onClick={() => isLoading? null :updateQuantity(item, false)} sx={{ fontSize: "14px", fontWeight: "400", cursor: "pointer" }}>
                                -
                              </Typography>
                              <Typography sx={{ fontSize: "14px", fontWeight: "400", }}>
                                {item.quantity}
                              </Typography>
                              <Typography onClick={() => isLoading? null :updateQuantity(item, true)} sx={{ fontSize: "14px", fontWeight: "400", cursor: "pointer" }}>
                                +
                              </Typography>

                            </Box>
                          </Box>

                        </Box>
                        <Box sx={{ display: "flex", gap: "12px", alignItems: "center", marginTop: "10px" }}>

                            <Typography sx={{ display: "flex", alignItems: "center", fontSize: "16px", fontWeight: "600" }}><CurrencyRupeeIcon sx={{ fontSize: "16px", fontWeight: "600" }} />{parseFloat( item.quantity * ( parseFloat(item.product?.selling_price) + Math.round(item.product?.selling_price * (item.product?.tax/100) ) ) ).toFixed(2)}</Typography>
                          <CloseIcon onClick={() => clearCart(item)} sx={{ width: "28px", height: "28px", color: "grey.500", borderRadius: "50px", padding: "5px", "&:hover": { bgcolor: "error.light", color: "error.dark", cursor: "pointer" }, transition: ".3s ease-in-out" }} />

                        </Box>


                      </Box>

                        <Divider /></>

                    })}
                    {isLaptop &&
                      <><Box sx={{ display: "flex", flexDirection: "row", marginTop: "20px", alignItems: "center", gap: "36px", justifyContent: "space-between" }}>
                          {/* <Box> */}
                          <Button disabled={(orderPlaceDisabled||isLoading||userreponse?.account?.referred_by_id==2 ||  paymentMethod == '' || !shippingAddress || cart.length == 0 )?true:false} variant="contained" sx={{ textTransform: "none", height: "3rem", fontWeight: 600, fontSize: "1p4x", "&:hover": { bgcolor: "primary.light", color: "primary.contrastText", }, width: "250px" }}  onClick={placeOrder} >Place Order</Button>
                          {/* {userreponse?.account?.referred_by_id==68 &&<Typography sx={{fontSize:"14px",textAlign:"left",alignSelf:"start"}}><span style={{color:"rgb(39,162,253,1)",cursor:"pointer"}} onClick={()=>setOpen(true)}>Click Here</span> to update Sponsor ID!</Typography>} */}
                          {/* </Box> */}


                        <Box sx={{ paddingRight: "36px" }}>
                          {
                              !isLoading?
                                <Box sx={{ display: 'flex', justifyContent: "space-between", paddingTop: "8px" }}><Typography sx={{ fontSize: "20px", fontWeight: "600" }}> Total</Typography><Typography sx={{ fontWeight: "600",fontSize:"20px" }}><CurrencyRupeeIcon sx={{ fontSize: "20px", fontWeight: 600 }} />{subTotal}</Typography></Box>
                              :
                              <Box sx={{ display: 'flex', justifyContent: "space-between", paddingTop: "8px" }}>
                                <Skeleton>
                                  <Typography sx={{ fontSize: "20px", fontWeight: "600" }}> Total</Typography>
                                </Skeleton>
                                <Skeleton>
                                  <Typography sx={{ fontWeight: "600",fontSize:"20px" }}><CurrencyRupeeIcon sx={{ fontSize: "20px", fontWeight: 600 }} />{subTotal}</Typography>
                                </Skeleton>
                              </Box>
                          }

                        </Box>

                      </Box>
                        <Box sx={{ display: "flex", alignItems: "center", marginTop: "20px" }}>


                          <Typography sx={{ fontWeight: 400, fontSize: "14px", whiteSpace: "pre-wrap" }} >By placing your order, you agree to MAYI IQ's <a href="https://mayiiq.com/terms-service/" style={{ textDecoration: "none", color: "rgba(39, 161, 255, 1)" }} > Terms&nbsp;of&nbsp;Services</a >&nbsp;and&nbsp;<a style={{ textDecoration: "none", color: "rgba(39, 161, 255, 1)" }} href="https://mayiiq.com/privacy-policy/">Privacy Policy</a></Typography>
                        </Box>
                      </>}
                  </Box>
                    {deliveryType === 2 && <Stack direction="row" sx={{pt:"16px",gap:"8px"}}>
                        <Typography sx={{color:"grey.600"}}>Estimated Delivery by</Typography>
                        <Typography sx={{fontWeight:600}}>{courier_details?.etd}</Typography>
                  </Stack>}
                </Box>
              </Box>
              {/* </Box> */}
            </AccordionDetails>
          </Accordion>

        </Box>
        <Box component={isLaptop ? Paper : Box} sx={{ height: "max-content", width: { xs: "calc( 100%-32px )", md: "30%", },marginTop:{xs:"10px",md:"0px"}, position: "sticky", top: "100px", bgcolor: "#fff", padding: "20px", borderRadius: "5px" }} elevation={1}>


          <Box sx={{ display: "flex", flexDirection: { xs: "column-reverse", md: "column" } }}>
            <Box>
              <Box sx={{ width: "100%", display: "flex", flexDirection: "column",alignItems:"center", marginTop: { xs: "20px", md: "0px" } }}>


              <Box>
                          <Button disabled={(orderPlaceDisabled||isLoading||userreponse?.account?.referred_by_id==2 || paymentMethod == '' ||!shippingAddress || cart.length == 0)?true:false} variant="contained" sx={{ textTransform: "none", height: "3rem", fontWeight: 600, fontSize: "1p4x", "&:hover": { bgcolor: "primary.light", color: "primary.contrastText", }, width: "250px" }}  onClick={placeOrder} >Place Order</Button>
                          {/* {userreponse?.account?.referred_by_id==68 &&<Typography sx={{fontSize:"14px",textAlign:"left",alignSelf:"start"}}><span style={{color:"rgb(39,162,253,1)",cursor:"pointer"}} onClick={()=>setOpen(true)}>Click Here</span> to update Sponsor ID!</Typography>} */}
              </Box>
              </Box>
              <Box sx={{ display: "flex", alignItems: "center", marginTop: "20px", marginBottom: "10px" }}>


                <Typography sx={{ fontWeight: 400, fontSize: "12px", whiteSpace: "pre-wrap", textAlign: "center" }} >By placing your order, you agree to MAYI IQ's <a href="https://mayiiq.com/terms-service/" style={{ textDecoration: "none", color: "rgba(39, 161, 255, 1)" }} > Terms&nbsp;of&nbsp;Services</a >&nbsp;and&nbsp;<a style={{ textDecoration: "none", color: "rgba(39, 161, 255, 1)" }} href="https://mayiiq.com/privacy-policy/">Privacy Policy</a></Typography>
              </Box>
              {!isMobile && <Divider />}

            </Box>
            {userReponse.voucher?.length>0&&<>
            <Box sx={{paddingBottom:"10px"}}>
            <Typography sx={[formStyle.label, { textAlign: "center", marginTop: "20px", fontSize: "16px", fontWeight: "600" }]}>
              Your Available Cash Incentives
              </Typography>
              {
                isMobile?
                <div>
      {[ 'bottom'].map((anchor) => (
        <React.Fragment key={anchor}>
           {/* <Button onClick={}>{anchor}</Button> */}
           <InputBase value={selectedVoucher?selectedVoucher.voucher.name:""} sx={[formStyle.inputBase,{cursor:"pointer",textTransform:"capitalize"}]} onClick={toggleDrawer(anchor, true)} placeholder="Apply Coupon"  endAdornment={selectedVoucher? <HighlightOffIcon sx={{color:"#DA342E"}} onClick={()=>{setOpenCoupon(false);setSelectedVoucher()}} />  : <AddIcon sx={{color:"#1099F4"}} onClick={()=>toggleDrawer(anchor, true)} />}></InputBase>
          <Drawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
          >
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))}
    </div>:  <InputBase value={selectedVoucher?selectedVoucher.voucher.name:""} sx={[formStyle.inputBase,{cursor:"pointer",textTransform:"capitalize"}]} onClick={seleCoupon} placeholder="Apply Coupon"  endAdornment={selectedVoucher? <HighlightOffIcon sx={{color:"#DA342E"}} onClick={()=>{setOpenCoupon(false);setSelectedVoucher()}} />  : <AddIcon sx={{color:"#1099F4"}} onClick={()=>{setOpenCoupon(true)}} />}></InputBase>
              }
             
              {selectedVoucher&&<Box sx={{display:"flex",gap:"3px",marginTop:"12px"}}><Typography sx={{fontSize:"12px",fontWeight:"700",lineHeight:"16px",color:"#008740"}}> Cash Incentives Applied! </Typography>
              <Typography sx={{fontSize:"12px",fontWeight:"500",lineHeight:"16px",color:"#000"}}> You Saved <CurrencyRupeeIcon sx={{fontSize:"12px"}}/>{discount}</Typography></Box>}
            </Box>
            <Divider />
            </>}
            <Box>
              <Typography sx={[formStyle.label, { textAlign: "center", marginTop: "20px",fontSize: "16px", fontWeight: "600"  }]}>
                Order Summary
              </Typography>
              {!isLoading ?
                <Box sx={{ paddingBottom: "8px", paddingTop: "8px" }}>
                      <Box sx={{ display: 'flex', justifyContent: "space-between", }}><Typography sx={{ color: "grey.600", fontSize: "14px",display:"flex",alignItems:"center" }}> Sub Total<Tooltip arrow title="Inclusive of GST" placement="top"><InfoOutlined sx={{fontSize:"14px",ml:"5px"}}/></Tooltip></Typography><Typography sx={{ color: "grey.600" }}><CurrencyRupeeIcon sx={{ fontSize: "14px" }} />{showingTotal}</Typography></Box>
                  {/* <Box sx={{ display: 'flex', justifyContent: "space-between", }}><Typography sx={{ color: "grey.600", fontSize: "14px" }}> Tax </Typography><Typography sx={{ color: "grey.600" }}><CurrencyRupeeIcon sx={{ fontSize: "14px" }} />{totalTax}</Typography></Box> */}
                  <Box sx={{ display: 'flex', justifyContent: "space-between", }}>
                        <Typography sx={{ color: "grey.600", fontSize: "14px",width:{xs:"180",sm:"190px"} }}> Delivery Fees</Typography>
                    <Typography sx={{ color: "grey.600" }}><CurrencyRupeeIcon sx={{ fontSize: "14px" }} />{deliveryCharge}</Typography>
                  </Box>
                      <Box sx={{ display: 'flex', justifyContent: "space-between", }}><Typography sx={{ color: "grey.600", fontSize: "14px" }}> Transaction Fees</Typography><Typography sx={{ color: "grey.600" }}><CurrencyRupeeIcon sx={{ fontSize: "14px" }} />{ ((parseFloat(total)+parseFloat(deliveryCharge) )* (platform_fee + (paymentMethod === "cash" || paymentMethod === "cod" ? 0 : paymentMethod === "cashfree" && handling_fee))).toFixed(2)}</Typography></Box>
                      {selectedVoucher&&<Box sx={{ display: 'flex', justifyContent: "space-between", }}><Typography sx={{ color: "grey.600", fontSize: "14px" }}>Applied Cash Incentives</Typography><Typography sx={{ color: "grey.600" }}>-<CurrencyRupeeIcon sx={{ fontSize: "14px" }} />{discount}</Typography></Box>}
                </Box>
                :
                <Box sx={{ paddingBottom: "8px", paddingTop: "8px" }}>
                  <Box sx={{ display: 'flex', justifyContent: "space-between", }}>
                    <Skeleton>
                      <Typography sx={{ color: "grey.600", fontSize: "14px" }}> Sub Total</Typography>
                    </Skeleton>
                    <Skeleton>
                      <Typography sx={{ color: "grey.600" }}><CurrencyRupeeIcon sx={{ fontSize: "14px" }} />{showingTotal}</Typography>
                    </Skeleton>
                  </Box>
                  {/* <Box sx={{ display: 'flex', justifyContent: "space-between", }}>
                          <Skeleton>
                              <Typography sx={{ color: "grey.600", fontSize: "16px" }}> Tax </Typography>.
                          </Skeleton>
                          <Skeleton>
                              <Typography sx={{ color: "grey.600" }}><CurrencyRupeeIcon sx={{ fontSize: "16px" }} />{totalTax}</Typography>
                          </Skeleton>
                        </Box> */}
                  <Box sx={{ display: 'flex', justifyContent: "space-between", }}>
                    <Skeleton>
                      <Typography sx={{ color: "grey.600", fontSize: "14px" }}>  Delivery Fees </Typography>
                    </Skeleton>
                    <Skeleton>
                      <Typography sx={{ color: "grey.600" }}><CurrencyRupeeIcon sx={{ fontSize: "14px" }} />{deliveryCharge}</Typography>
                    </Skeleton>
                  </Box>
                  <Box sx={{ display: 'flex', justifyContent: "space-between", }}>
                    <Skeleton>
                      <Typography sx={{ color: "grey.600", fontSize: "14px" }}>  Transaction Fees </Typography>
                    </Skeleton>
                    <Skeleton>
                      <Typography sx={{ color: "grey.600" }}><CurrencyRupeeIcon sx={{ fontSize: "14px" }} />{((parseFloat(total)+parseFloat(deliveryCharge)) * (platform_fee+ (paymentMethod === "cash" || paymentMethod === "cod" ? 0 : paymentMethod === "cashfree" && handling_fee))).toFixed(2)}</Typography>
                    </Skeleton>
                  </Box>
                  {selectedVoucher&&
                  <Box sx={{ display: 'flex', justifyContent: "space-between", }}>
                    <Skeleton>
                      <Typography sx={{ color: "grey.600", fontSize: "14px" }}>Applied Cash Incentives</Typography>
                    </Skeleton>
                    <Skeleton>
                      <Typography sx={{ color: "grey.600" }}>-<CurrencyRupeeIcon sx={{ fontSize: "14px" }} />{discount}</Typography>
                    </Skeleton>
                  </Box>}

                </Box>
              }



              <Divider />

              <Box sx={{ paddingBottom: "10px" }}>
                {
                  !isLoading?
                    <Box sx={{ display: 'flex', justifyContent: "space-between", paddingTop: "12px" }}><Typography sx={{ fontSize: "16px", fontWeight: "600" }}> Total</Typography><Typography sx={{ fontWeight: "600" }}><CurrencyRupeeIcon sx={{ fontSize: "16px", fontWeight: 600 }} />{subTotal}</Typography></Box>
                    :
                    <Box sx={{ display: 'flex', justifyContent: "space-between", paddingTop: "12px" }}>
                      <Skeleton>
                        <Typography sx={{ fontSize: "16px", fontWeight: "600" }}> Total</Typography>
                      </Skeleton>
                      <Skeleton>
                        <Typography sx={{ fontWeight: "600" }}><CurrencyRupeeIcon sx={{ fontSize: "16px", fontWeight: 600 }} />{subTotal}</Typography>
                      </Skeleton>
                    </Box>
                }

              </Box>
              <Divider />

            </Box>


          </Box>
        </Box>
      </Box>
    </Box>
    <Modal open={openCoupon} onClose={couponChoose}>
      <Box sx={{ width: isMobile ? "calc(100% - 64px)" : "550px", background: "#fff", left: "50%", top: "50%", position: "absolute", transform: 'translate(-50%, -50%)', padding: "24px", border: "none", display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center",gap:"20px" }}>

        <Box sx={{display:"flex",alignItems:"center",justifyContent :"space-between",width:"100%"}}>
          <Typography sx={{ fontSize: "18px", lineHeight: "28px", fontWeight: 700 }}>
          Your Available Cash Incentives
          </Typography>
          <CloseIcon sx={{color:'#6F7883',cursor:"pointer"}} onClick={()=>setOpenCoupon(false)} />
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column", gap: "20px" }}>
          {userReponse.voucher?.map(item => <Box sx={{ display: "flex",flexDirection:{xs:"column",sm:"row"} }}>
            <Box sx={{ bgcolor: "#2DD4BF", height: "100%", padding: "15px", display: "flex", flexDirection: "column", gap: "4px" }}>
              <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", gap: "4px" }}>

                <Typography sx={{ fontSize: "54px", lineHeight: "72px", weight: "700", color: "#fff", }}>{item.voucher.offer_percentage}</Typography>
                <Box>
                  <Typography sx={{ fontSize: "18px", lineHeight: "24px", weight: "700", color: "#fff", }}>%</Typography>
                  <Typography sx={{ fontSize: "18px", lineHeight: "24px", weight: "700", color: "#fff", }}>OFF</Typography>
                </Box>
              </Box>
              <Box>
              <Typography sx={{ fontSize: "12px", lineHeight: "16px", textAlign: "center", color: "#fff" }}>{item.voucher.splitted_text}</Typography>
              </Box>
              <Divider variant="" sx={{ borderStyle: "dashed" }} />
              <Box>
                <Typography sx={{ fontSize: "12px", lineHeight: "16px", textAlign: "center", color: "#fff" }}>
                  Valid Till
                </Typography>
                <Typography sx={{ fontSize: "14px", lineHeight: "16px", textAlign: "center", fontWeight: "700", color: "#fff" }}>
                  {formatDate(item.expired)}
                </Typography>
              </Box>
            </Box>
            <Box sx={{ bgcolor: "#F0FDFA",  width: "100%", padding: "15px", display: "flex", flexDirection: {xs:"column",sm:"row"}, gap: "24px", justifyContent: "space-between" }} >

              <Box sx={{display: "flex", flexDirection: "column", justifyContent: "space-between",gap:{xs:"15px",sm:"0px"} }}>
                <Box>
                  <Typography sx={{ fontSize: "18px", lineHeight: "20px", fontWeight: "700", }}>Congratulations! </Typography>
                  <Typography sx={{ fontSize: "14px", lineHeight: "20px", fontWeight: "500", }}>{item.voucher.Description}</Typography>
                </Box>

                <Typography sx={{ fontSize: "12px", lineHeight: "16px", fontWeight: "400", }}>Valid on online orders only. Cannot be combined with other offers.</Typography>

              </Box>
              <Box sx={{ display: "flex", alignSelf: "end" }}>

                <Box sx={{ bgcolor: "#2DD4BF", padding: '8px 12px', borderRadius: "4px",cursor:'pointer' }} onClick={() => applyCoupon(item) }>
                  <Typography sx={{color:"#fff",}} >Apply</Typography>
                </Box>
              </Box>
            </Box>


          </Box>)}
        </Box>



      </Box>
    </Modal> 
    <Footer />
  </Stack>

}
export default Checkout