import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';



export default function InventProductList() {
  return (
    <TableContainer component={Paper} sx={{bgcolor:"#fff"}}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead >
          <TableRow>
            <TableCell sx={{fontWeight:"600"}}>Product</TableCell>
            <TableCell sx={{fontWeight:"600"}} align="right">SKU</TableCell>
            <TableCell sx={{fontWeight:"600"}} align="right">Quantity</TableCell>
            <TableCell sx={{fontWeight:"600"}} align="right">Sold Quantity</TableCell>
       
            <TableCell sx={{fontWeight:"600"}} align="right">Actions</TableCell>
            
          </TableRow>
        </TableHead>
        <TableBody>
          
            <TableRow
              
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              
            </TableRow>
          
        </TableBody>
      </Table>
    </TableContainer>
  );
}
