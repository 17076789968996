import { Box,Paper,Button,Typography } from "@mui/material"
import { card_styles } from "./cards"
import { Link } from "react-router-dom"
import ProductList from "./productList"
import InventProductList from "./invetoryprodList"

const InventoryComponent=()=>{

return (
    <Box sx={{ width: "100%",display:"flex",flexDirection:"column", gap:"32px" }} >

                <Paper sx={{ width: "calc(100% - 64px)", bgcolor: "#fff", padding: "32px", display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                    <Typography sx={card_styles.header}>Products</Typography>
                    {/* <Button style={card_styles.Button} variant="contained" component={Link} to="/product/1" >+ Add Products</Button> */}
                </Paper>
                <InventProductList/>

            </Box>
)
}

export default InventoryComponent