
import { Box, Button, Divider, InputBase, InputLabel, Paper, Typography, InputAdornment, IconButton, Modal } from "@mui/material"
import { alpha, styled } from '@mui/material/styles'
import passwordImage from "../assets/Background.jpg"
import { updateEmail, updateMobile, updateUserName } from "../redux/user_reducer"
import mayi_logo from "../assets/mayi_logo.png"
import { useMediaQuery } from 'react-responsive';
import Layout from "../layout"
import SideBarList from "../components/list"
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useState } from "react";
import axios from "axios"
import CustomAxios from '../utils/CustomAxios';
import CircularProgress from '@mui/material/CircularProgress';
import forgotPasswordImage from "../assets/Frame 91-2.jpg"
import { inputStyles } from "./signup_form"
import { useEffect } from "react"
import TimerOutlinedIcon from '@mui/icons-material/TimerOutlined';
const PasswordChangeForm = () => {
    const dispatch = useDispatch()
    const [showOldPassword, setShowOldPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [isloading, setIsloading] = useState(false)
    const [oldPassword, setOldPassword] = useState("")
    const [newPassword, setNewPassword] = useState("")
    const [confirmPassword, setConfirmPassword] = useState("")
    const id=useSelector(state=>state.user.id)
    const authtoken = localStorage.getItem('authtoken');
    const isLaptop = useMediaQuery({ query: '(min-width: 900px)' });
    const Email = useSelector(state => state.user.email)
    const firstname = useSelector(state => state.user.first_name)
    const lastname = useSelector(state => state.user.last_name)
    const [open,setOpen]=useState(false)
    const [errors, setErrors] = useState({})
    const navigate = useNavigate()
    const param = useParams()
    const [counter, setCounter] = useState();
    // const [counter, setCounter] = useState(0);
    // // console.log(authtoken)
    if (authtoken == "") {
        // console.log("authtoken not empty")
    }
    const userName = useSelector(state => state.user.username)
   

    const handleSubmit = async () => {
        const payload = {
            email: Email,
            first_name: firstname,
            last_name: lastname,
            password: confirmPassword,
            referralcode: "",
            is_active:true,
            registration_type: "google" 
        };
        // console.log(firstname)
        // console.log(lastname)
        // console.log(Email)
        // console.log(confirmPassword)
        

        setIsloading(true);
        try {
            const response = await CustomAxios.post(`signup/`, payload);
            setIsloading(false);
            if (response.data.message === "Registration Successful!") {
                navigate("/login");
            } else {
                setErrors({ passworderror: response.data.message });
            }
        } catch (error) {
            setIsloading(false);
            // console.error(error);
        }
    };

    // const handleKeyPress = (e) => {
    //     if (e.key === 'Enter') {
    //         handleSubmit();
    //     }
    // };


    


    const handleChangepassword = async () => {
   


        let payload
        
        const error = {
            oldpassworderror: "",
            passworderror: "",
            newpassworderror: "",
            confirmpassworderror: ""
        }
    
        if (newPassword.length < 8 || !/[A-Z]/.test(newPassword) || !/[a-z]/.test(newPassword) || !/\d/.test(newPassword) || !/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/.test(newPassword)
        ) {
            error.passworderror = "A minimum of 8 characters password containing a combination of uppercase, lowercase letters, special characters and numbers is required.";
            setErrors(error);
            return; 
        } 
        
        
        else if (newPassword !== confirmPassword) {
            error.confirmpassworderror = "Confirm password does not match the new password.";
            setErrors(error);
            return; 
        }
    
        
        else if (newPassword === oldPassword) {
            error.newpassworderror = "New Password can't be the same as the old password.";
            setErrors(error);
            return; 
        }
    
        
        setErrors({
            oldpassworderror: "",
            passworderror: "",
            newpassworderror: "",
            confirmpassworderror: ""
        });
    
        
        if (param.id === 'change') {
            payload = {
                id: id,
                param: param.id,
                old_password: oldPassword,
                password: confirmPassword
            };
        } else {
            payload = {
                id: id,
                param: param.id,
                password: confirmPassword
            };
        }
    
        
        try {
            setIsloading(true);
            const response = await CustomAxios.post(`reset_password/`, payload);
            setIsloading(false);
            
            // Handle response messages
            if (response.data.message === "Password Successfully Changed") {
                if (authtoken) {
                    console.log(authtoken);
                    setOpen(true);
                    setCounter(5);
                } else {
                    navigate('/login');
                }
            } else {
                if (response.data.message === "You have given a wrong password") {
                    error.oldpassworderror = response.data.message;
                } else {
                    error.passworderror = response.data.message;
                }
                setErrors(error); 
            }
        } catch (error) {
            
            setIsloading(false);
            // console.error("An error occurred during password change:", error);
        }
    }
    
    useEffect(() => {
        counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
        
        counter==0 && navigate("/login")
        counter==0 &&  localStorage.clear()
      }, [counter]);
    const handleTogglePasswordVisibility = (visible) => {
        if (visible == 'old') {
            setShowOldPassword((prevShowPassword) => !prevShowPassword);
        }
        else if (visible == 'new') {
            setShowNewPassword((prevShowPassword) => !prevShowPassword);
        }
        else if (visible == 'confirm') {
            setShowConfirmPassword((prevShowPassword) => !prevShowPassword);
        }

    };
    const handleClose = () => {
        // dispatch(updateAddressModal(false))
        setOpen(false);
    };
    const isMobile = useMediaQuery({ maxWidth: 600 }); // Adjust the breakpoint as needed
    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            handleChangepassword();
        }
    };
    // // console.log(errors)
    const homePage=()=>{
        navigate("/")
    }
    const isTabletHori = useMediaQuery({ query: '(min-width: 1200px)' });
    // // console.log(param)
    // // console.log(param)



    return (
        <Box component={!isMobile && param.id=="change" && Paper} sx={param.id == "forgot" ? { left: "50%", top: "50%", position: "absolute", transform: 'translate(-50%, -50%)',display:"flex",justifyContent:"space-between",alignItems:{xs:"center",sm:"initial"},flexDirection: {sm:"row", xs:"column"},width: { md: "750px", xs: "100%" }, maxWidth:{xs:"100%" ,md:"750px"},height:{xs:"auto",sm:"auto",md:"auto"}}:{ bgcolor: { xs:"#fff",sm:"grey.100"}, width:"100%",height:authtoken&&"100vh" }} >
            {param.id == "change" && <Layout />}
            <Box sx={param.id == "change" ?{ alignSelf: "start", marginTop:authtoken&&{xs:"77px", sm:"140px"},marginBottom:authtoken&&"100px", display: "flex", justifyContent: "center", width: "100%", gap: "36px" }:{}}>
                {isTabletHori && authtoken && param.id == "change" && <Paper sx={{ height: "max-content",width:"20%",position:"sticky",top:"140px" }} elevation={1}>
                    {SideBarList('left')}
                </Paper>}
                <Box component={!isMobile && param.id == "change" && Paper} sx={{ left: "50%", top: "50%", position:!authtoken && "absolute" , transform: !authtoken &&'translate(-50%, -50%)', display: "flex", alignItems: { xs: "center", sm: "initial" }, justifyContent: "center", flexDirection: { sm: "row", xs: "column" }, width:{ md:!authtoken? "750px": "95%", xs: "100%",lg:!authtoken? "750px":param.id == "forgot"? "100%":"65%" } , maxWidth: { xs: "100%", sm:!authtoken? "750px":"95%",lg:!authtoken? "750px": param.id == "forgot"? "100%":"65%" }, height:!isMobile&&!isLaptop?"100vh":"auto" }} >
                    {param.id=="forgot" && <Box src={forgotPasswordImage} sx={{ width: { sm: "50%", xs: "100%" }, backgroundSize: "auto", objectFit: "cover" }} component="img" />}
                    <Box sx={[inputStyles.paper,{width:{ sm:param.id == "change"? "100%":"100%",xs:"calc( 100%  )"},maxWidth: param.id == "change"?"100%":"100%", height:!isMobile&&!isLaptop?"100vh":"auto" }]}>
                        {param.id != "change" && <><Box src={mayi_logo} component='img' sx={inputStyles.logo} onClick={homePage} />
                            {param.id!="signup"&&<Typography sx={inputStyles.subHeading} color="grey.900"> Change password</Typography>}</>}
                        <Box sx={{ display: "flex", flexDirection: 'column', gap: "16px", width: "100%" }}>
                            {param.id == "change" && <Box>
                                <Typography sx={inputStyles.label} color="grey.700">Old Password </Typography>
                                <InputBase type={showOldPassword ? 'text' : 'password'} sx={[inputStyles.inputBase,{maxWidth: !authtoken?"350px":"100%"}]} onKeyDown={(e) => handleKeyPress(e)} onChange={(e) => setOldPassword(e.target.value)} endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton onClick={() => handleTogglePasswordVisibility('old')} edge="end">
                                            {showOldPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>

                                } />
                                {errors.oldpassworderror && <Typography sx={{ color: "error.light" }}>{errors.oldpassworderror}</Typography>}
                            </Box>}

                            <Box>
                                <Typography sx={inputStyles.label} color="grey.700">New Password</Typography>
                                <InputBase type={showNewPassword ? 'text' : 'password'} sx={[inputStyles.inputBase,{maxWidth: !authtoken?"350px":"100%"}]} onKeyDown={(e) => handleKeyPress(e)} onChange={(e) => setNewPassword(e.target.value)} endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton onClick={() => handleTogglePasswordVisibility('new')} edge="end">
                                            {showNewPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                } />
                                {errors.newpassworderror && <Typography sx={{ color: "error.light" }}>{errors.newpassworderror}</Typography>}
                            </Box>
                            <Box>
                                <Typography sx={inputStyles.label} color="grey.700">Confirm Password</Typography>
                                <InputBase type={showConfirmPassword ? 'text' : 'password'} sx={[inputStyles.inputBase,{maxWidth: !authtoken?"350px":"100%"}]} onKeyDown={(e) => handleKeyPress(e)} onChange={(e) => setConfirmPassword(e.target.value)} endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton onClick={() => handleTogglePasswordVisibility('confirm')} edge="end">
                                            {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                } />
                                {errors.confirmpassworderror && <Typography sx={{ color: "error.light" }}>{errors.confirmpassworderror}</Typography>}
                            </Box>
                            {errors.passworderror && <Typography sx={{ color: "error.light" }}>{errors.passworderror}</Typography>}
                            {param.id=="signup"?  <Button variant="contained" sx={[inputStyles.button,{alignSelf:authtoken && "end"}]} onClick={handleSubmit}>{isloading? <CircularProgress color="inherit" size={30}/> : "Submit"}</Button>:
                            <Button variant="contained" sx={[inputStyles.button,{alignSelf:authtoken && "end"}]} onClick={handleChangepassword}>{isloading? <CircularProgress color="inherit" size={30}/> : "Change"}</Button>}

                        </Box>
                    </Box>

                </Box>
            </Box>
            <Modal open={open}
            // onClose={handleClose}
            aria-labelledby="child-modal-title"
            aria-describedby="child-modal-description"

        >
            <Box sx={{ width: isMobile ? "calc(100% - 64px)" : "600px", background: "#fff", left: "50%", top: "50%", position: "absolute", transform: 'translate(-50%, -50%)', padding: "32px", border: "none",display:"flex",justifyContent:"center",flexDirection:"column",alignItems:"center" }}>
            <TimerOutlinedIcon sx={{fontSize:"32px",color:"primary.light", textAlign: "center"}}/>
                <Typography sx={{ textAlign: "center",color:"primary.light",fontSize:"20px" }}>Password Changed Successfully</Typography>
               <Typography style={{ textAlign: "center",color:"primary.light", fontSize:"14px"}}>For security reasons you will be logged out in 5 seconds.  Please login again with your new password to continue using your account.</Typography>
             
               <Typography sx={{ textAlign: "center",color:"primary.light", fontSize:"14px"}}>{counter}Sec</Typography>
                
               
            </Box>
            </Modal>
        </Box>
    )

}


export default PasswordChangeForm
