
import { Box,Paper,Typography,TableContainer,Table,TableCell,TableHead,TableRow,TableBody, Button } from "@mui/material"
import { styles } from "./cards_old";
import CustomAxios from "../utils/CustomAxios";
import { useEffect ,useState} from "react"; 
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import { MultiSelect } from 'primereact/multiselect';
import { Calendar } from "primereact/calendar";
const WithdrawHistory=()=>{
    const [withdrawRequest,setWithdrawRequest]=useState([])
    const [completedWithdraw,setCompletedWithdraw]=useState([])
    const [requestedWithdraw,setRequestedWithdra]=useState([])
    let [filteredWithdraw,setFilteredWithdraw]=useState([])
    const [withdrawDates,setWithdrawDates]=useState([])
    const [withdrawStatus,setWithdrawStatus]=useState("")
    useEffect(()=>{
        withdraRequest()
    },[])
    const withdraRequest=async()=>{
        await CustomAxios.get("Withdraw_Request_admin/").then(res=>setWithdrawRequest(res.data))
    }
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = date.getDate().toString().padStart(2, '0');
        const month = date.toLocaleString('default', { month: 'short' });
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
    };
    const withdraw_statuses = [
        { name: 'Debited'},
        { name: 'Cancelled'},
        // { name: 'Requested'},
        // { name: 'Inprogress'},
 
    ];
    let filtered
    const withdrawStatusMapping =
        { "debited": 'Debited',
        'cancelled': 'Cancelled',
        'requested': 'Requested',
        'inprogress': 'Inprogress'}
        useEffect(()=>{
            // console.log(withdrawStatus)
            filtered = withdrawStatus.length
     ? withdrawRequest.filter((transaction) => 
     withdrawStatus.some((type) =>type.name === withdrawStatusMapping[transaction.status])
    )
     : withdrawRequest;
    
       setFilteredWithdraw(filtered)
    },[withdrawStatus,withdrawRequest])
    useEffect(()=>{
        // console.log("??????????????????",withdrawDates,)
        filtered = withdrawDates.length ? withdrawRequest?.filter(withdrawRequest => {
            // console.log(withdrawRequest.created_on,withdrawDates)
        const transactionDate = new Date(withdrawRequest.created_on).setHours(0, 0, 0, 0);
        // console.log(transactionDate)
        const startDate = new Date(withdrawDates[0]).setHours(0, 0, 0, 0);
        const endDate = new Date(withdrawDates[1]).setHours(23, 59, 59, 999);
        return transactionDate >= startDate && transactionDate <= endDate;
    }) : withdrawRequest;
    setFilteredWithdraw(filtered)
    
       },[withdrawDates,withdrawRequest])
    const downloadExcel=()=>{

        const jsonData=requestedWithdraw.map(item=>{
                const obj={
                    "Beneficiary Name":item.bank.account_name,
                    "Beneficiary Account Number":item.bank.account_number,
                    "IFSC Code":item.bank.ifsc_code,
                    "Bank Name":item.bank.bank_name,
                    "Amount":item.amount,
                    "Requested Date":formatDate(item.created_on)
    
                }
                return obj
           
            
        })
        // console.log(jsonData)
        const worksheet = XLSX.utils.json_to_sheet(jsonData);

        // Create a new workbook and append the worksheet
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
      
        // Generate a binary string representation of the workbook
        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
      
        // Create a Blob object from the binary string and save it
        const excelBlob = new Blob([excelBuffer], { type: 'application/octet-stream' });
        saveAs(excelBlob, "Beneficiary_account.xlsx");
    
    }
    const statusUpdate=async(id,value)=>{
        await CustomAxios.put(`update_withdraw/${id}`,{
            status:value

        }).then(res=>{
            if(res.data.response=="successfully updated"){
                withdraRequest()
            }
        })

    }
    useEffect(()=>{
       
        if(withdrawRequest){
            
        let completed=withdrawRequest?.filter(item=>item.status=="completed"||item.status=="cancelled" )
        setCompletedWithdraw(completed)
            // let array=[]
        let array=withdrawRequest?.filter(item=>item.status=="requested"||item.status=="inprogress" )
        setRequestedWithdra(array)
      
        }
        
        
    },[withdrawRequest])

    return(
        <Box sx={{ width: "100%", display: "flex", flexDirection: "column", gap: "32px" }}>
        <Paper sx={{ width: "100%", bgcolor: "#fff", display: "flex",flexDirection:"column" }}>
            <Box sx={{display: "flex", alignItems: "center", justifyContent: "space-between",padding:"32px" }}>
            <Typography sx={styles.header}>Withdraw Requests</Typography>
            <Button disabled={requestedWithdraw.length==0} onClick={downloadExcel} variant="contained">Download</Button>
            </Box>
           
            <TableContainer sx={{ maxHeight: "36vh" }}>
                    <Table  stickyHeader aria-label="sticky table" sx={{ minWidth: 650 }}>
                        <TableHead >
                            <TableRow>
                                <TableCell sx={{ fontWeight: "600", textAlign: "center" }}>Beneficiary Name </TableCell>
                                <TableCell sx={{ fontWeight: "600", textAlign: "center" }}>Beneficiary Account Number</TableCell>
                                <TableCell sx={{ fontWeight: "600", textAlign: "center" }} align="right">IFSC Code</TableCell>
                                <TableCell sx={{ fontWeight: "600", textAlign: "center" }} align="right">Bank Name</TableCell>

                                <TableCell sx={{ fontWeight: "600", textAlign: "center" }} align="right">Amount</TableCell>
                                <TableCell sx={{ fontWeight: "600", textAlign: "center" }} align="right">Requested Date </TableCell>
                                <TableCell sx={{ fontWeight: "600", textAlign: "center" }} align="right">Status</TableCell>
                                <TableCell sx={{ fontWeight: "600", textAlign: "center" }} align="right">Actions </TableCell>
                                
                                
                                {/* <TableCell sx={{ fontWeight: "600", textAlign: "center" }} align="right">Package</TableCell> */}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {requestedWithdraw.map(item=>{return (<> {item.status!=="completed"&&item.status!=="cancelled"&& <TableRow key={item.id}>
                            <TableCell sx={{  textAlign: "center",textTransform:"capitalize"  }}>{item.bank?.account_name}</TableCell>
                            <TableCell sx={{  textAlign: "center" }}>{item.bank?.account_number}</TableCell>
                            <TableCell sx={{  textAlign: "center" ,textTransform:"capitalize"}}>{item.bank?.ifsc_code?.toUpperCase()}</TableCell>
                            <TableCell sx={{  textAlign: "center" ,textTransform:"capitalize"}}>{item.bank?.bank_name}</TableCell>
                            <TableCell sx={{  textAlign: "center" }}>{item.amount}</TableCell>
                            
                            <TableCell sx={{  textAlign: "center" }}>{formatDate(item.created_on)}</TableCell>
                            <TableCell sx={{  textAlign: "center",textTransform:"capitalize" }}>{item.status}</TableCell>
                            {item.status!=="cancelled"&& item.status !== "completed" &&
                            <TableCell  sx={{  textAlign: "center" }} > {item.status=="completed"?"completed":<Button  sx={{textTransform:"none" ,backgroundColor:item.status=="requested"?"#F59E0B":"#22C55E","&:hover":{backgroundColor:item.status=="requested"?"#F59E0B":"#22C55E"}}} variant="contained" onClick={()=>statusUpdate(item.id,item.status=="requested"?"inprogress":item.status=="inprogress"?"debited":"debited")}>{item.status=="requested"?"Move to Inprogress":"Move to Complete"}</Button>}</TableCell>}
                            </TableRow>}</>)})

                            }     
                        </TableBody>
                    </Table>
                </TableContainer>
        </Paper>
        <Box component={Paper}>
        <Box sx={{display: "flex", alignItems: "center", justifyContent: "space-between",padding:"32px" }}>
            <Typography sx={styles.header}>Withdraw History</Typography>
            
            </Box>
            <Box sx={{display:"flex",flexDirection:{sm:"row",xs:"column"},gap:"24px",paddingLeft:"24px"}}>
                           <Box sx={{ display: "flex", position: "relative", alignItems: "center",maxWidth: "220px",width:"100%" }}>
                                <Calendar value={withdrawDates} onChange={(e) => setWithdrawDates(e.value)} selectionMode="range" readOnlyInput hideOnRangeSelection placeholder="DD/MM/YY - DD/MM/YY" style={{ border: "1px solid", borderColor: "#CBD5E1", width: "100%", height: "36px", fontSize: "14px", padding: "0px", color: "grey.700", borderRadius: "4px", maxWidth: "220px",fontWeight:"400",boxShadow:"none",outline:"none"}} />
                                <CalendarTodayOutlinedIcon sx={{position:"absolute",right:"10px",pointerEvents: "none",color:"grey",width:"20px",height:"20px"}}/>
                           </Box>
                            <MultiSelect value={withdrawStatus} onChange={(e)=>setWithdrawStatus(e.value)} options={withdraw_statuses} optionLabel="name" placeholder=" Status" maxSelectedLabels={3} className="w-full md:w-20rem" style={{ border: "1px solid", borderColor: "#CBD5E1", width: "100%", height: "36px", fontSize: "14px", padding: "0px", color: "grey.700", borderRadius: "4px", maxWidth: "190px",fontWeight:"400",boxShadow:"none",outline:"none" }} />
                            {/* <MultiSelect value={selectedWallet} onChange={(e)=>setSelectedWallet(e.value)} options={wallet_types} optionLabel="name" placeholder="Wallet" maxSelectedLabels={3} className="w-full md:w-20rem" style={{ border: "1px solid", borderColor: "#CBD5E1", width: "100%", height: "36px", fontSize: "14px", padding: "0px", color: "grey.700", borderRadius: "4px", maxWidth: "190px",fontWeight:"400",boxShadow:"none" }} /> */}
                        </Box>
            <TableContainer sx={{ maxHeight: "36vh" }}>
                    <Table  stickyHeader aria-label="sticky table" sx={{ minWidth: 650 }}>
                        <TableHead >
                            <TableRow>
                                <TableCell sx={{ fontWeight: "600", textAlign: "center" }}>Beneficiary Name </TableCell>
                                <TableCell sx={{ fontWeight: "600", textAlign: "center" }}>Beneficiary Account Number</TableCell>
                                <TableCell sx={{ fontWeight: "600", textAlign: "center" }} align="right">IFSC Code</TableCell>
                                <TableCell sx={{ fontWeight: "600", textAlign: "center" }} align="right">Bank Name</TableCell>

                                <TableCell sx={{ fontWeight: "600", textAlign: "center" }} align="right">Amount</TableCell>
                                <TableCell sx={{ fontWeight: "600", textAlign: "center" }} align="right">Requested Date </TableCell>
                                <TableCell sx={{ fontWeight: "600", textAlign: "center" }} align="right">Status</TableCell>
                                {/* <TableCell sx={{ fontWeight: "600", textAlign: "center" }} align="right">Actions </TableCell> */}
                                
                                
                                {/* <TableCell sx={{ fontWeight: "600", textAlign: "center" }} align="right">Package</TableCell> */}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {filteredWithdraw.map(item=>{return (<> {item.status!=="requested"&&item.status!=="inprogress"&& <TableRow key={item.id}>
                            <TableCell sx={{  textAlign: "center",textTransform:"capitalize"  }}>{item.bank?.account_name}</TableCell>
                            <TableCell sx={{  textAlign: "center" }}>{item.bank?.account_number}</TableCell>
                            <TableCell sx={{  textAlign: "center" ,textTransform:"capitalize"}}>{item.bank?.ifsc_code?.toUpperCase()}</TableCell>
                            <TableCell sx={{  textAlign: "center" ,textTransform:"capitalize"}}>{item.bank?.bank_name}</TableCell>
                            <TableCell sx={{  textAlign: "center" }}>{item.amount}</TableCell>
                            
                            <TableCell sx={{  textAlign: "center" }}>{formatDate(item.created_on)}</TableCell>
                            <TableCell sx={{  textAlign: "center",textTransform:"capitalize" }}>{item.status}</TableCell>
                            {/* {item.status!=="cancelled"&& item.status !== "completed" &&
                            <TableCell sx={{  textAlign: "center" }} > {item.status=="completed"?"completed":<Button sx={{textTransform:"none" ,backgroundColor:item.status=="requested"?"#F59E0B":"#22C55E","&:hover":{backgroundColor:item.status=="requested"?"#F59E0B":"#22C55E"}}} variant="contained" onClick={()=>statusUpdate(item.id,item.status=="requested"?"inprogress":item.status=="inprogress"?"completed":"completed")}>{item.status=="requested"?"Move to Inprogress":"Move to Complete"}</Button>}</TableCell>} */}
                            </TableRow>}</>)})

                            }     
                        </TableBody>
                    </Table>
                </TableContainer>
        </Box>
        </Box>
    )
}
export default WithdrawHistory