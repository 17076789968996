import { Box, Divider,Typography } from "@mui/material"
import welness from "./assets/129646.jpg"
import wisdom from "./assets/9275114.jpg"
import wealth from "./assets/4616.jpg"
import relationship from "./assets/778834.jpg"
import awareness from "./assets/147.jpg"
import Header from "../components/header"
import Footer from "../components/footer"
import { Link, useNavigate } from "react-router-dom"
import { easeInOut, motion, useAnimation, useInView, useScroll, useTransform } from "framer-motion"
import { useEffect } from "react";


const culture = {
    heading: {letterSpacing:"5px",color:"grey.500",fontSize:"14px",lineHeight:"26px",fontFamily:"'Poppins',sans-serif"},
    title: {color:"grey.900",fontSize:"24px",fontWeight:"700",letterSpacing:"-1px",fontFamily:"'Poppins',sans-serif",lineHeight:"1.2em",marginBottom:"26px"},
    content: {color:"grey.700",fontSize:"14px",fontFamily:"'Poppins',sans-serif",lineHeight:"26px"}
}

const CulturePage = () => {
    useEffect(()=>{
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;

    },[])
   const navigate = useNavigate()
    return <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", width: "100%", overflow: "hidden" }}>
        <Header />


        <Box sx={{ width: "100%",display:"flex",alignItems:"center",justifyContent:"center",flexDirection:"column"}}>
            <Box sx={{ marginTop: "80px", height: "150px", bgcolor: "grey.200", width: "100%" ,display:"flex",justifyContent:"center" }}>
                <Box sx={{padding:{xs:"16px",sm:"24px"},display:"flex",alignItems:"center",flexDirection: { xs: "column", sm: "row" },justifyContent:"space-between",maxWidth: "1440px",width:"100%"}}> 
                        <Typography sx={{fontSize:"30px",fontWeight:"500"}}>MAYI IQ Culture</Typography>
                        <Box sx={{display:"flex",justifyContent:"space-between",gap:"10px"}}>
                            <Typography onClick = {()=>navigate("/")} sx={{fontSize:"12px",fontWeight:"400",color:"#ea4d2a",cursor:"pointer"}}>HOME</Typography>
                            <Typography sx={{fontSize:"12px",fontWeight:"400"}}>{">"}</Typography>
                            <Typography sx={{fontSize:"12px",fontWeight:"400",color:"grey.600"}}>MAYI CULTURE</Typography>
                        </Box>

                </Box>

            </Box>
            <Box sx={{ maxWidth: "1440px",padding:{xs:"16px",sm:"24px"}}}>
                <Divider sx={{ marginTop: "20px", marginBottom: "10px" }} />
                <motion.div initial={{ opacity: 0, x: 200 }} whileInView={{x:0,opacity:1}} transition={{easings:"ease-in-out",duration:2}} ><Box sx={{ width: { xs: "100%", md: "100%" }, display: "flex", justifyContent: "center",marginTop:"70px",flexDirection:{sm:"row",xs:"column"}}}>
                    
                        <Box component="img" src={welness} sx={{borderRadius:"12px",width:{sm:"50%",xs:"100%"},height:"100%",maxWidth:"430px"}} />
                        <Box sx={{width:{sm:"50%",xs:"100%"},padding:{xs:"24px 0px",sm:"0px 24px"}}}>

                            <Typography sx={culture.heading}>HEALTH</Typography>
                            <Typography sx={culture.title}>Cultivating Holistic Wellness</Typography>
                            <Typography sx={culture.content}>Through our teachings, individuals gain a deeper understanding of their bodies and minds, learning to cultivate resilience, balance, and inner peace. By nurturing physical health and emotional equilibrium, we empower individuals to unlock their full potential and live life to the fullest.</Typography>
                            <br/>
                            <Typography sx={culture.content}>History serves as a stark reminder that wealth devoid of wisdom can lead to ruin. Hence, it's crucial to instill in ourselves and our loved ones the values of diligence, integrity, and compassion. By leveraging our wealth to create opportunities rather than handouts, we can contribute to the creation of a resilient and industrious society.</Typography>
                            <br/>
                            <Typography sx={culture.content}>Investing in education, job creation, and sustainable projects is paramount. We mustn't succumb to the temptation of indiscriminate charity but rather empower individuals to stand on their own feet with dignity and purpose. Through strategic giving and mentorship, we can nurture a generation of conscientious leaders who are committed to ethical growth and societal welfare.</Typography>
                            <br/>
                            <Typography sx={culture.content}>For us, health is not just a destination but a lifelong journey—an ongoing process of self-discovery and self-care. Join us as we embark on this transformative path towards holistic wellness, where vibrant health serves as the cornerstone of a fulfilling and meaningful life.</Typography>
                            <br/>
                            
                        </Box>
                </Box>
                        </motion.div>
                <Divider sx={{ marginTop: "20px", marginBottom: "10px" }} />
                <motion.div initial={{ opacity: 0, x: -200 }} whileInView={{x:0,opacity:1}} transition={{easings:"ease-in-out",duration:2}} ><Box sx={{ width: { xs: "100%", md: "100%" }, display: "flex", justifyContent: "center",marginTop:"70px",flexDirection:{sm:"row",xs:"column"} }}>
                    
                        <Box component="img" src={wisdom}  sx={{borderRadius:"12px",width:{sm:"50%",xs:"100%"},height:"100%",maxWidth:"430px"}}/>
                        <Box sx={{width:{sm:"50%",xs:"100%"},padding:{xs:"24px 0px",sm:"0px 24px"}}}>

                            <Typography sx={culture.heading}>EMBRACING WISDOM</Typography>
                            <Typography sx={culture.title}>A Path to Balance and Fulfillment</Typography>
                            <Typography sx={culture.content}>True wisdom transcends age and education; it emerges from a life of mindfulness and deep introspection. In a world where wisdom is scarce, those who cultivate it unlock the keys to inner peace and liberation, right here on earth. Wisdom empowers us to navigate the complexities of life with grace, guiding us to strike a harmonious balance between work, family, wealth, and spirituality. It's not merely a product of experience or scholarly achievements but a reflection of our depth of thought and our ability to introspect. Let us not mistake age or education for wisdom; rather, let us look to those who embody it, regardless of their societal status. Our grandparents, for instance, may not have been educated, yet their wisdom was boundless.</Typography>
                            <br/>
                            <Typography sx={culture.content}>As we journey through life, let us harness wisdom to cultivate balance in all aspects of our existence. Let wisdom be our compass as we navigate the intricate terrain of work, family dynamics, health, wealth, and spiritual growth. This, indeed, is the essence of a truly balanced life. And as we embark on this journey, let us not forget the power of gratitude, for it is the cornerstone of a life well-lived.</Typography>
                            <br/>
                            
                        </Box>
                    
                </Box>
                </motion.div>
                <Divider sx={{ marginTop: "20px", marginBottom: "10px" }} />
                <motion.div initial={{ opacity: 0, x: 200 }} whileInView={{x:0,opacity:1}} transition={{easings:"ease-in-out",duration:2}} ><Box sx={{ width: { xs: "100%", md: "100%" }, display: "flex", justifyContent: "center",marginTop:"70px",flexDirection:{sm:"row",xs:"column"} }}>
                    
                        <Box component="img" src={wealth}  sx={{borderRadius:"12px",width:{sm:"50%",xs:"100%"},height:"100%",maxWidth:"430px"}}/>
                        <Box sx={{width:{sm:"50%",xs:"100%"},padding:{xs:"24px 0px",sm:"0px 24px"}}}>

                            <Typography sx={culture.heading}>WEALTH WITH PURPOSE</Typography>
                            <Typography sx={culture.title}>A Path to Responsibility and Ethical Growth</Typography>
                            <Typography sx={culture.content}>Wealth isn't just about accumulating riches; it's about stewardship and mindful allocation. At the heart of true wealth lies a profound responsibility—to use our resources for the betterment of humanity, the protection of animals, and the preservation of nature. It's imperative to impart this wisdom to our families, fostering a culture of respect and ethical awareness.</Typography>
                            <br/>
                            <Typography sx={culture.content}>History serves as a stark reminder that wealth devoid of wisdom can lead to ruin. Hence, it's crucial to instill in ourselves and our loved ones the values of diligence, integrity, and compassion. By leveraging our wealth to create opportunities rather than handouts, we can contribute to the creation of a resilient and industrious society.</Typography>
                            <br/>
                            <Typography sx={culture.content}>Investing in education, job creation, and sustainable projects is paramount. We mustn't succumb to the temptation of indiscriminate charity but rather empower individuals to stand on their own feet with dignity and purpose. Through strategic giving and mentorship, we can nurture a generation of conscientious leaders who are committed to ethical growth and societal welfare.</Typography>
                            <Typography sx={culture.content}>It's not enough to amass wealth; we must also scrutinize its sources and impact. Ethical investing entails aligning our financial endeavors with our moral compass, ensuring that our profits contribute to the greater good. Whether it's supporting businesses that uphold ethical standards or shunning ventures that compromise human rights and environmental integrity, every investment decision carries ethical implications.</Typography>
                            <br/>
                            <Typography sx={culture.content}>Ultimately, wealth is not just about financial abundance; it's about the conscientious stewardship of resources for the betterment of all. By fostering a culture of responsibility, ethical investment, and mindful consumption, we can pave the way for a brighter and more equitable future for generations to come. Let us embark on this journey of wealth with purpose and integrity, knowing that our actions today will shape the world of tomorrow.</Typography>
                            
                        </Box>
                    
                </Box>
                </motion.div>
                <Divider sx={{ marginTop: "20px", marginBottom: "10px" }} />
                <motion.div initial={{ opacity: 0, x: -200 }} whileInView={{x:0,opacity:1}} transition={{easings:"ease-in-out",duration:2}} ><Box sx={{ width: { xs: "100%", md: "100%" }, display: "flex", justifyContent: "center",marginTop:"70px",flexDirection:{sm:"row",xs:"column"} }}>
                    
                        <Box component="img" src={relationship}  sx={{borderRadius:"12px",width:{sm:"50%",xs:"100%"},height:"100%",maxWidth:"430px"}} />
                        <Box sx={{width:{sm:"50%",xs:"100%"},padding:{xs:"24px 0px",sm:"0px 24px"}}}>

                            <Typography sx={culture.heading}>RELATIONSHIP</Typography>
                            <Typography sx={culture.title}>The Art of Negotiation and Compassionate Connection</Typography>
                            <Typography sx={culture.content}>Every interaction is a negotiation, whether we're crossing the road or planning a vacation. Yet, when it comes to relationships, this aspect often gets overlooked amidst turmoil. We must remember to separate the subject matter from the individual and approach conflicts with empathy and understanding.</Typography>
                            <br/>
                            <Typography sx={culture.content}>Softness towards people and firmness towards the issue at hand pave the way for mutually beneficial resolutions. It's about finding win-win solutions through respectful dialogue and collaboration.</Typography>
                            <br/>
                            <Typography sx={culture.content}>However, this doesn't mean compromising on fundamental truths or ethical principles. Rather, it's about finding solutions that serve the greater good while upholding integrity and honesty.</Typography>
                            <br/>
                            <Typography sx={culture.content}>Building relationships isn't confined to personal spheres; it extends to every interaction, even with strangers. Simple gestures of gratitude towards waitstaff or acquaintances can nurture positive connections that endure.</Typography>
                            <br/>
                            <Typography sx={culture.content}>True success lies not in seeking recognition but in selflessly serving others. When we approach relationships with the intention of contributing to others' well-being, success naturally follows.</Typography>
                            <br/>
                            <Typography sx={culture.content}>In times of adversity, it's these steadfast companions who stand by us, demonstrating unwavering loyalty and selflessness. Their presence is more precious than any material wealth, for they embody the true essence of companionship and support.</Typography>
                            <br/>
                            <Typography sx={culture.content}>As Henry Ford famously said, "Take away my factories, take away my money, but leave me my people, and I will rebuild everything anew." These relationships are the bedrock of our lives, sustaining us through the darkest of times.</Typography>
                            <br/>
                            <Typography sx={culture.content}>So, as we navigate the intricacies of relationships, let's remember to cultivate empathy, foster understanding, and cherish the bonds that enrich our lives.</Typography>
                            <br/>
                            
                        </Box>
                    
                </Box>
                </motion.div>
                <Divider sx={{ marginTop: "20px", marginBottom: "10px" }} />
                <motion.div initial={{ opacity: 0, x: 200 }} whileInView={{x:0,opacity:1}} transition={{easings:"ease-in-out",duration:2}} ><Box sx={{ width: { xs: "100%", md: "100%" }, display: "flex", justifyContent: "center",marginTop:"70px" ,flexDirection:{sm:"row",xs:"column"} }}>
                    
                        <Box component="img" src={awareness} sx={{borderRadius:"12px",width:{sm:"50%",xs:"100%"},height:"100%",maxWidth:"430px"}} />
                        <Box sx={{width:{sm:"50%",xs:"100%"},padding:{xs:"24px 0px",sm:"0px 24px"}}}>

                            <Typography sx={culture.heading}>SELF AWARENESS</Typography>
                            <Typography sx={culture.title}>lluminating the Path to Liberation</Typography>
                            <Typography sx={culture.content}>The journey of self-awareness begins with introspection—delving deep into the essence of who we are and what truly matters to us. It's about unraveling the layers of our being to uncover the root of our happiness, our sorrows, and our deepest desires.</Typography>
                            <br/>
                            <Typography sx={culture.content}>Contrary to popular belief, the self is not synonymous with the ego. While the ego may shape our cultural identities, it often blinds us to the wisdom that lies within. An inflated ego can lead us astray, clouding our judgment and fueling destructive tendencies.</Typography>
                            <br/>
                            <Typography sx={culture.content}>To continue on the path of self-awareness is to confront the ego with understanding—not to suppress it, but to transcend its limitations. Through prayer, introspection, and acts of compassion, we can dissolve the barriers that separate us from our true nature.</Typography>
                            <br/>
                            <Typography sx={culture.content}>Love, kindness, and compassion serve as beacons of light, guiding us towards self-realization and inner wisdom. By nurturing a spirit of unity and empathy, we can transcend the confines of the ego and embrace the interconnectedness of all beings.</Typography>
                            <br/>
                            <Typography sx={culture.content}>In the pursuit of self-awareness, clarity emerges—a clarity that liberates us from the shackles of suffering and illuminates the path to true freedom. It is through understanding the self that we unlock the door to profound peace and fulfillment.</Typography>
                            <br/>
                            
                        </Box>
                </Box>
            </motion.div>
            </Box>

        </Box>
        <Footer/>

    </Box>
}

export default CulturePage