import { Box, Button, Dialog, Modal, TextField, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { updateTransactionPinModal, updateTransactionPinVerification } from "../redux/user_reducer";
import { useMediaQuery } from "react-responsive";
import CloseIcon from '@mui/icons-material/Close';
import { MuiOtpInput } from "mui-one-time-password-input";
import CustomAxios from "../utils/CustomAxios";
import { useCallback, useEffect, useState } from "react";
import { updateSnackBarMessage, updateSnackBarOpen, updateSnackBarSeverity } from "../redux/snackbar";
import CircularProgress from '@mui/material/CircularProgress';

export function TransactionPinVerification() {
    const transactionPinModal = useSelector(state => state.user.transactionPinModal)
    const dispatch = useDispatch()
    const [isloading, setIsloading] = useState(false)
    const isMobile = useMediaQuery({ query: '(max-width: 600px)' });
    const isLaptop = useMediaQuery({ query: '(max-width: 900px)' });
    const [ transactionpin , setTransactionPin ] = useState(Array(6).fill('')) 
    const selecteIdForTransaction = useSelector(state => state.user.selecteIdForTransaction)
    let id = parseInt(useSelector(state => state.user.id))

    // console.log(transactionPinModal)
    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            handleComplete(); 
        }
      };
      const handleComplete = async(value) => { 
        setIsloading(true)
        await CustomAxios.post(`transactionPin-verification/`,
        {
            id:id,     
            transactionpin:transactionpin.join(''),
        }
    ).then(({data})=>{
        // console.log(data)
        if(data.message == "transactionpin verified"){
            dispatch(updateTransactionPinVerification("verified"))
            dispatch(updateTransactionPinModal(false))
            setTransactionPin(Array(6).fill(''))
            setIsloading(false)
        }else{
            dispatch(updateSnackBarSeverity('error'))
            dispatch(updateSnackBarMessage(data.message))
            dispatch(updateSnackBarOpen(true))
            setIsloading(false)

        }
}).catch(error=>{
    setIsloading(false)
    
})
  }
//   const handleChange = (newValue) => {
//     setTransactionPin(newValue)
// }
    const handleChange = useCallback((e, index) => {
        const value = e.target.value;
        if (/^\d$/.test(value)) {
        setTransactionPin((prevOtp) => {
            const newOtp = [...prevOtp];
            newOtp[index] = value;
            return newOtp;
        });

        // Automatically move to the next input box
        if (index < transactionpin.length - 1) {
            const nextInput = document.getElementById(`otp-input-${index + 1}`);
            if (nextInput) nextInput.focus();
        }
        }
    }, [transactionpin?.length]);

    const handleKeyUp = useCallback((e, index) => {
        if (e.key === 'Backspace') {
          setTransactionPin((prevOtp) => {
            const newOtp = [...prevOtp];
            if (newOtp[index] === '') {
              if (index > 0) {
                newOtp[index - 1] = '';
                const prevInput = document.getElementById(`otp-input-${index - 1}`);
                if (prevInput) prevInput.focus();
              }
            } else {
              newOtp[index] = '';
            }
            return newOtp;
          });
        }
      }, []);

      useEffect(()=>{
        if(!transactionPinModal){
            setTransactionPin(Array(6).fill(''))
        }
      },[transactionPinModal])
// console.log(selecteIdForTransaction)
return (<Box>
        <Modal
            open={transactionPinModal}
            onClose={()=>dispatch(updateTransactionPinModal(false))}
            aria-labelledby="responsive-dialog-title"
            sx={{ height: "100vh", left: "50%", top: "50%", position: "absolute", transform: 'translate(-50%, -50%)', border: "none", display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center", width: "100%" }}
        > 
    <Box sx={{maxWidth:isMobile?"100%":"383px",width:"100%",padding:isMobile?"16px":"24px",gap:"16px",minHeight:"276px",display:"flex",flexDirection:"column",background:"white"}}>
                <Box sx={{ display: "flex", flexDirection: "column", gap: "16px",width:"100%", }}>
                <Box sx={{ display: "flex", flexDirection: "column", gap: "4px",width:"100%", }}>
                    <Box sx={{ display: "flex", justifyContent: "space-between",width:"100%" }}>
                        <Typography sx={{ fontWeight: 600, fontSize: "16px", lineHeight: "24px" }} color="primary">Transaction PIN</Typography>
                        <CloseIcon onClick={() => {dispatch(updateTransactionPinModal(false))}} sx={{ cursor: "pointer" }} />
                    </Box>
                    <Typography sx={{ fontWeight: 400, fontSize: "16px", lineHeight: "24px" }} >Enter theTransaction PIN</Typography>
                    </Box>
                    <Box sx={{display:"flex",flexDirection:"column",gap:"12px"}}>
                 <Typography sx={{ fontWeight: 400, fontSize: "14px",lineHeight:"20px"}} color="grey.900">Please enter the Transaction PIN below to complete the transaction.</Typography>
                {/* <MuiOtpInput gap={isMobile?.1:1} sx={{padding:"5px"}} value={transactionpin}  type="password" onKeyDown={(e)=>handleKeyPress(e)} onChange={handleChange}
                 autoFocus
                 renderInput={(props) => (
                   <TextField
                   {...props}
                   value={transactionpin[props.id] ? '*' : ''}
                   inputProps={{
                     ...props.inputProps,
                     maxLength: 1, // Ensure only one character per input
                     style: { textAlign: 'center' }, // Center the text in the input field
                   }}
                   />
                 )}
                 separator={<span>-</span>}
                /> */}

            <Box sx={{ display: 'flex', justifyContent: 'space-between', }}>
                        {transactionpin?.map((_, index) => (
                        <TextField
                            key={index}
                            id={`otp-input-${index}`}
                            value={transactionpin[index] ? '*' : ''}
                            onChange={(e) => handleChange(e, index)}
                            onKeyUp={(e) => handleKeyUp(e, index)}
                            inputProps={{
                            maxLength: 1,
                            style: { textAlign: 'center', fontSize: '24px',height:"48px",width:"48px"},
                            }}
                            onKeyDown={(e)=>handleKeyPress(e)}
                            sx={{ textAlign: 'center', fontSize: '24px',height:48,width:48,'& .MuiInputBase-root': { height: '100%'}}}
                        />
                        ))}
                </Box>
               <Button disabled={transactionpin.length<6?true:false} variant="contained" sx={{width:"100%",height:"48px",textTransform:"none"}} onClick={()=>handleComplete()}>{isloading? <CircularProgress color="inherit" size={30}/> : 'Submit'}</Button>
            </Box>
            </Box>
            </Box>
        </Modal>
    </Box>)
}