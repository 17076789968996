import { useNavigate } from "react-router-dom"
import { Box,Typography } from "@mui/material"

import { style } from "./refund_policy"
import Footer from "../components/footer"
import { useEffect } from "react";
import Header1 from "../components/header1"


const PrivacyPolicy =()=>{
    const navigate=useNavigate()
    useEffect(()=>{
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;

    },[])
   return <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", width: "100%", overflow: "hidden" }}>
   <Header1 />
   <Box sx={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
       <Box sx={{ marginTop: {xs:"77px",md:"118px"}, height: "150px", bgcolor: "grey.200", width: "100%", display: "flex", justifyContent: "center" }}>
           <Box sx={{ padding: { xs: "16px", sm: "24px" }, display: "flex", alignItems: "center", flexDirection: { xs: "column", sm: "row" }, justifyContent: "space-between", maxWidth: "1440px", width: "100%" }}>
               <Typography sx={{ fontSize: "30px", fontWeight: "500" }}>Privacy Policy</Typography>
               <Box sx={{ display: "flex", justifyContent: "space-between", gap: "10px" }}>
                   <Typography onClick={() => navigate("/")} sx={{ fontSize: "12px", fontWeight: "400", color: "#ea4d2a", cursor: "pointer" }}>Home</Typography>
                   <Typography sx={{ fontSize: "12px", fontWeight: "400" }}>{">"}</Typography>
                   <Typography sx={{ fontSize: "12px", fontWeight: "400", color: "grey.600" }}>PRIVACY POLICY</Typography>
               </Box>

           </Box>

       </Box>
       <Box sx={{ maxWidth: "1440px", padding: { xs: "16px", sm: "24px" } }}>
           {/* <Divider sx={{ marginTop: "20px", marginBottom: "10px" }} /> */}
           <Box sx={{ width: "100%", display: "flex", justifyContent: "center", marginTop: "70px", flexDirection: "column", gap: "24px" }}>
               <Box>

                   <Typography sx={style.title}>Who we are</Typography>
                   <Typography sx={style.content}>Our website address is: https://mayiiq.com</Typography>
               </Box>
               <Box>

                   <Typography sx={style.title}>Comments</Typography>
                   <Typography sx={style.content}>When visitors leave comments on the site we collect the data shown in the comments form, and also the visitor’s IP address and browser user agent string to help spam detection.</Typography>
                   <br />
                   
               </Box>
               <Box>

                   <Typography sx={style.title}>Media</Typography>
                   <Typography sx={style.content}>If you upload images to the website, you should avoid uploading images with embedded location data (EXIF GPS) included. Visitors to the website can download and extract any location data from images on the website.</Typography>
                   <br />
                   
               </Box>
               <Box>

                   <Typography sx={style.title}>Cookies</Typography>
                   <Typography sx={style.content}>If you leave a comment on our site you may opt-in to saving your name, email address and website in cookies. These are for your convenience so that you do not have to fill in your details again when you leave another comment. These cookies will last for one year.</Typography>
                   <br />
                   <Typography sx={style.content}>If you visit our login page, we will set a temporary cookie to determine if your browser accepts cookies. This cookie contains no personal data and is discarded when you close your browser.</Typography>
                   <br />
                   <Typography sx={style.content}>When you log in, we will also set up several cookies to save your login information and your screen display choices. Login cookies last for two days, and screen options cookies last for a year. If you select “Remember Me”, your login will persist for two weeks. If you log out of your account, the login cookies will be removed.</Typography>
                   <br />
                   <Typography sx={style.content}>If you edit or publish an article, an additional cookie will be saved in your browser. This cookie includes no personal data and simply indicates the post ID of the article you just edited. It expires after 1 day.</Typography>
                   <br />
                   
               </Box>
               <Box>

                   <Typography sx={style.title}>Embedded content from other websites</Typography>
                   <Typography sx={style.content}>Articles on this site may include embedded content (e.g. videos, images, articles, etc.). Embedded content from other websites behaves in the exact same way as if the visitor has visited the other website.</Typography>
                   <br />
                   <Typography sx={style.content}>These websites may collect data about you, use cookies, embed additional third-party tracking, and monitor your interaction with that embedded content, including tracking your interaction with the embedded content if you have an account and are logged in to that website.</Typography>
                   <br />
                  
               </Box>
               <Box>

                   <Typography sx={style.title}>Google Analytics</Typography>
                   <Typography sx={style.content}>Google Analytics is a web analytics service offered by Google that tracks and reports website traffic. Google uses the data collected to track and monitor the use of our Service. This data is shared with other Google services. Google may use the collected data to contextualize and personalize the ads of its own advertising network. You can opt-out of having made your activity on the Service available to Google Analytics by installing the Google Analytics opt-out browser add-on. The add-on prevents the Google Analytics JavaScript (ga.js, analytics.js, and dc.js) from sharing information with Google Analytics about visits activity. For more information on the privacy practices of Google, please visit the Google Privacy & Terms web page: https://policies.google.com/privacy?hl=en</Typography>
                   <br />
                   
               </Box>
              
               <Box>

                   <Typography sx={style.title}>Links to other sites</Typography>
                   <Typography sx={style.content}>Our Service may contain links to other sites that are not operated by us. If you click on a third party link, you will be directed to that third party’s site. We strongly advise you to review the Privacy Policy of every site you visit. We have no control over and assume no responsibility for the content, privacy policies or practices of any third party sites or services.</Typography>
                   <br />
                   
               </Box>
               <Box>

                   <Typography sx={style.title}>Children’s privacy</Typography>
                   <Typography sx={style.content}>Our Service does not address anyone under the age of 18 (“Children”). We do not knowingly collect personally identifiable information from anyone under the age of 18. If you are a parent or guardian and you are aware that your Children has provided us with Personal Data, please contact us. If we become aware that we have collected Personal Data from children without verification of parental consent, we take steps to remove that information from our servers.</Typography>
                   <br />
                   {/* <Typography sx={style.content}>We will replace the item or issue a refund, depending on the availability of the product and your preference.</Typography>
                   <br /> */}
               </Box>
               <Box>

                   <Typography sx={style.title}>Changes to privacy policy</Typography>
                   <Typography sx={style.content}>We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page. We will let you know via email and/or a prominent notice on our Service, prior to the change becoming effective and update the “effective date” at the top of this Privacy Policy. You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.</Typography>
                   <br />
                   {/* <Typography sx={style.content}>We will replace the item or issue a refund, depending on the availability of the product and your preference.</Typography>
                   <br /> */}
               </Box>
               <Box>

                   <Typography sx={style.title}>Who we share your data with</Typography>
                   <Typography sx={style.content}>If you request a password reset, your IP address will be included in the reset email.</Typography>
                   <br />
                   {/* <Typography sx={style.content}>We will replace the item or issue a refund, depending on the availability of the product and your preference.</Typography>
                   <br /> */}
               </Box>
               <Box>

                   <Typography sx={style.title}>How long we retain your data</Typography>
                   <Typography sx={style.content}>If you leave a comment, the comment and its metadata are retained indefinitely. This is so we can recognize and approve any follow-up comments automatically instead of holding them in a moderation queue.</Typography>
                   <br />
                   <Typography sx={style.content}>For users that register on our website (if any), we also store the personal information they provide in their user profile. All users can see, edit, or delete their personal information at any time (except they cannot change their username). Website administrators can also see and edit that information.</Typography>
                   <br />
               </Box>
               <Box>

                   <Typography sx={style.title}>What rights you have over your data</Typography>
                   <Typography sx={style.content}>If you have an account on this site, or have left comments, you can request to receive an exported file of the personal data we hold about you, including any data you have provided to us. You can also request that we erase any personal data we hold about you. This does not include any data we are obliged to keep for administrative, legal, or security purposes.</Typography>
                   <br />
                   {/* <Typography sx={style.content}>We will replace the item or issue a refund, depending on the availability of the product and your preference.</Typography>
                   <br /> */}
               </Box>
               <Box>

                   <Typography sx={style.title}>Where your data is sent</Typography>
                   <Typography sx={style.content}>Visitor comments may be checked through an automated spam detection service.</Typography>
                   <br />
                   <Typography sx={style.content}>FOR ANY QUESTIONS OR CONCERNS, PLEASE CONTACT US AT:<a style={{color:"rgb(39,162,253,1)",cursor:"pointer",textDecoration:"none"}} href="mailto:privacy@mayiiq.com" > privacy@mayiiq.com</a></Typography>
                   <br />
               </Box>
               
           </Box>
       </Box>
       <Footer />

   </Box>
</Box>

}
export default PrivacyPolicy