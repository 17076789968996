
import { Box, Button, Divider, InputBase, InputLabel, Paper, Typography , InputAdornment, IconButton } from "@mui/material"
import { alpha, styled } from '@mui/material/styles'
import { updateEmail, updateMobile, updatePassword, updateFirstName, updateLastName, updateRole,updateId, updateProfile, updateMemberResponse, updateOtpCheck, updateUserName } from "../redux/user_reducer"
import mayi_logo from "../assets/mayi_logo.png"
import {inputStyles} from "./signup_form"
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useMediaQuery } from "react-responsive"
import axios from "axios"
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react"
import CustomAxios from '../utils/CustomAxios';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import {jwtDecode} from 'jwt-decode';
import loginImage from "../assets/loginHero.png"
import CircularProgress from '@mui/material/CircularProgress';
import { useEffect } from "react";
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
// import { GoogleLogin } from '@react-oauth/google'; // Import GoogleLogin component
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

// import jwt from "jsonwebtoken"
const LoginForm = () => {
    const dispatch = useDispatch()
    const [showPassword, setShowPassword] = useState(false);
const visibility=()=>{
    // // console.log("password visible")
}
const handleTogglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
};
const userName=useSelector(state=>state.user.user_name)
const isMobile = useMediaQuery({ maxWidth: 600 });
const email = useSelector(state => state.user.email )
const password = useSelector(state => state.user.password )
const [response,setResponse]=useState("")
const [isloading, setIsloading] = useState(false)
const [errors,setErrors]=useState({})
const { executeRecaptcha } = useGoogleReCaptcha();
const navigate=useNavigate()
const clientId = "521447616048-relr5c80sac2rudgmfub8kantovfc5pe.apps.googleusercontent.com"; // Replace with your actual client ID

const verifyCaptcha = ()=>{
    if (!executeRecaptcha) {
        return;
    }
  
    executeRecaptcha('login_page').then((token)=>{
        handleSummit(token)
        // console.log("////////////////token////////////",token)
    })
    // return token
}



const handleSummit = () => {
    const formData = {
        email_or_username: userName,
        password: password
    };
    const isValid = validateForm(formData);
    if (isValid) {
        login();
    }
};
const authtoken=localStorage.getItem("authtoken")
useEffect(()=>{
    if (authtoken){
        navigate("/")
    }

},[])

const login=async()=>{
    setIsloading(true)
    await CustomAxios.post(`login/`,
        {
            email_or_username:userName,
           
            password:password,
            // recaptcha_token: recap_token
        }
    ).then(({data})=>{
        // const decoded=jwt.decode()
        // console.log(data)
        setIsloading(false)
        if(data.message=='Successfully identified user'){
            dispatch(updatePassword(""))
            dispatch(updateUserName(""))
            const decode=jwtDecode(data.access_token)
            localStorage.setItem("address",JSON.stringify(data.add))
            localStorage.setItem('authtoken', data.access_token);
            localStorage.setItem('refreshToken', data.refresh_token);
            // console.log(data.add)
            // console.log(decode)
            dispatch(updateMemberResponse(data))
            dispatch(updateFirstName(decode.first_name))
            dispatch(updateLastName(decode.last_name))
            dispatch(updateEmail(decode.email))
            dispatch(updateRole(decode.role))
            dispatch(updateId(decode.id))
            dispatch(updateMobile(data.user.mobileno))
            if (data.att.length>0){
                dispatch(updateProfile(data.att[0].original))
            }
            dispatch(updateEmail(decode.email))
            navigate("/")
        }
        else if (data.message == "Account is not verified"){
            dispatch(updateOtpCheck("login"))
            dispatch(updateEmail(data.data.email))
            dispatch(updateMobile(data.data.mobileno))
            dispatch(updateId(data.data.id))
            navigate("/otp-verification")
        }
        else{
            dispatch(updateOtpCheck('login'));
            setResponse(data.message )
        }           
        
    }).catch(error=>{
        setIsloading(false)
        // console.log(error)
    })



}
const validateForm = (formData) => {
    let valid = true;
    const newErrors = {};

    if (!formData.email_or_username.trim()) {
        newErrors.email_or_username = 'Please enter an username.';
        valid = false;
    }

    if (!formData.password.trim()) {
        newErrors.password = 'Enter your password.';
        valid = false;
    }

    setErrors(newErrors);
    return valid;
};
  const homePage=()=>{
    navigate("/")
}

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSummit(); 
    }
  };
  useEffect(()=>{
    dispatch(updateEmail(""))
    dispatch(updatePassword(""))
  },[])
//   const isMobile = useMediaQuery({ maxWidth: 600 });
    const isLaptop = useMediaQuery({ query: '(min-width: 900px)' });

    const handleGoogleSuccess = async (credentialResponse) => {
        const details = jwtDecode(credentialResponse.credential);
        // console.log(details);
        const { given_name, family_name, email } = details;
        // console.log(given_name, family_name, email);
    
        const payload = {
            email: email,
            first_name: given_name,
            last_name:  "family_name" in details?family_name:"",
            referralcode: "",
            is_active: true,
            registration_type: "google"
        };
    
        // console.log(given_name);
        // console.log(family_name);
        // console.log(email);
    
        setIsloading(true);
        try {
            const response = await CustomAxios.post(`signup/`, payload);
            setIsloading(false);
    
            if (response.data.message === "Registration Successful!") {
                const decode = jwtDecode(response.data.access_token);
                localStorage.setItem("address", JSON.stringify(response.data.add));
                localStorage.setItem('authtoken', response.data.access_token);
                localStorage.setItem('refreshToken', response.data.refresh_token);
                // console.log(response.data.add);
                // console.log(decode);
                dispatch(updateMemberResponse(response.data));
                dispatch(updateFirstName(decode.first_name));
                dispatch(updateLastName(decode.last_name));
                dispatch(updateEmail(decode.email));
                dispatch(updateRole(decode.role));
                dispatch(updateId(decode.id));
                if (response.data.att.length > 0) {
                    dispatch(updateProfile(response.data.att[0].original));
                }
                dispatch(updateEmail(decode.email));
                navigate("/");
            } else {
                setErrors({ passworderror: response.data.message });
            }
        } catch (error) {
            setIsloading(false);
            console.error(error);
        }
    };
    



    // const handleGoogleSuccess = async (credentialResponse) => {
    //     const details = jwtDecode(credentialResponse.credential);
    //     // console.log(details);
    //     const { given_name, family_name, email } = details;
    //     // console.log(given_name, family_name, email);
    
        
    //         const payload = {
    //             email: email,
    //             first_name: given_name,
    //             last_name: family_name,
    //             // password: '', 
    //             referralcode: "",
    //             is_active: true,
    //             registration_type: "google"
    //         };
    
    //         // console.log(given_name);
    //         // console.log(family_name);
    //         // console.log(email);
    
    //         setIsloading(true);
    //         try {
    //             const response = await CustomAxios.post(`signup/`, payload);
    //             setIsloading(false);
    //             if (response.data.message === "Registration Successful!") {
    //                 // console.log("hii")
    //                 navigate("/");
    //             } else {
    //                 setErrors({ passworderror: response.data.message });
    //             }
    //         } catch (error) {
    //             setIsloading(false);
    //             console.error(error);
    //         }
                
    // };

    return (
        <GoogleOAuthProvider clientId={clientId}>
        <Box component={!isMobile&&Paper} sx={{ left: "50%", top: "50%", position: "absolute", transform: 'translate(-50%, -50%)',display:"flex",alignItems:{xs:"center",sm:"i;nitial"},flexDirection: {sm:"row", xs:"column"},width: { md: "440px", xs: "100%",sm:"440px" }, maxWidth:{xs:"100%" ,md:"600px",sm:"600px"},height:{xs:"100%",sm:"auto",md:"auto"},minHeight:{xs:"100vh",md:"auto",sm:"auto"}}} >
        <Box src={loginImage} sx={{display:{md:"none",sm:"none",xs:"block"},width: {md:"50%", xs:"100%",sm:"50%"},height:{xs:"128px",sm:"100vh",md:"80vh"}, overflowY:"scroll",backgroundSize:"auto",objectFit:"cover",minHeight:{xs:"128px"}}} component="img" />
            
            <Box sx={{width:"100%",padding:"32px",display:"flex",flexDirection:"column",alignItems:"center"}}>

           
            <Box src={mayi_logo} component='img' sx={inputStyles.logo} onClick={homePage}/>
                <Typography sx={inputStyles.subHeading} color="grey.900"> Welcome back!</Typography>
                <Typography sx={inputStyles.constent} color="grey.900"> Sign in to access your account and explore.</Typography>
            <Box sx={{ display: "flex", flexDirection: "column", gap: "16px",width:"100%" }}>
                
                
                <Box>
                <Typography sx={inputStyles.label} color="grey.700">Username <span style={{ color: "red" }}>*</span></Typography>
                            <InputBase
                                sx={inputStyles.inputBase}
                                placeholder="Enter Username"
                                onKeyDown={handleKeyPress}
                                onChange={(e) => dispatch(updateUserName(e.target.value))}
                            />
                            {errors.email_or_username && <Typography sx={{ color: "error.light" }}>{errors.email_or_username}</Typography>}

                </Box>
                <Box>
                    <Box sx={{display:"flex",justifyContent:"space-between"}}>
                    <Typography sx={inputStyles.label} color="grey.700">Password <span style={{color:"red"}}>*</span></Typography>
                   
                    </Box>
                    <InputBase placeholder="Enter password" type={showPassword ? 'text' : 'password'} sx={inputStyles.inputBase} onKeyDown={(e)=>handleKeyPress(e)} onChange={(e) => dispatch(updatePassword(e.target.value))} endAdornment={
                        <InputAdornment position="end">
                            <IconButton onClick={handleTogglePasswordVisibility} edge="end">
                                {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                        </InputAdornment>
                    } />
                    {errors.password && <Typography sx={{color:"error.light"}}>{errors.password}</Typography>}
                    
                    <Typography sx={{color:"error.light"}}>{response}</Typography>

                </Box>
                <Box sx={{width:"100%",display:"flex",justifyContent:"center"}}>
                <Link to="/forgot-password" style={{textDecoration:"none"}}><Typography sx={{fontSize:"14px",textDecoration:"none",textDecorationLine:"underline",textAlign:"center"}} color="primary">Forgot password?</Typography></Link></Box>
                
                <Button variant="contained" sx={inputStyles.button} onClick={handleSummit}>{isloading? <CircularProgress color="inherit" size={30}/> : "Sign in"}</Button>             
                <Box sx={{display:'flex',justifyContent:"center"}}>
                <Typography sx={{textAlign:"center",fontSize:"14px"}} > Don't have any account?&nbsp;</Typography><Link to="/signup" style={{textDecoration:"none",fontSize:"14px"}}><Typography sx={{textDecoration:"none",textDecorationLine:"underline",fontSize:"14px"}} color="primary">Sign up</Typography></Link>

                </Box>
                {/* <Divider sx={{marginTop:"24px",marginBottom:"20px",fontSize:"14px"}} >Or</Divider>
                <Box sx={{ display: "flex", justifyContent: "center", marginTop: "10px" }}>
                            <GoogleLogin
                                onSuccess={handleGoogleSuccess}
                                // onError={handleGoogleError}
                            />
                        </Box> */}
                
              
                {/* <Box sx={{ display: "flex", justifyContent: "center", marginTop: "10px" }}>
                            <GoogleLogin
                                onSuccess={handleGoogleSuccess}
                                // onError={handleGoogleError}
                            />
                        </Box> */}
                {/* </Box> */}
            </Box>
            </Box>
        </Box>
        </GoogleOAuthProvider>
    )


}

export default LoginForm