import { Box, Button, Typography ,keyframes} from "@mui/material";
import { landingPage_styles } from "./landing_page";
import intro_image from "./landing_assets/Mask group.svg"
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion"
export function Introduction() {

    const isMobile = useMediaQuery({ maxWidth: 600 });
    const isLaptop = useMediaQuery({ minWidth: 900  });
    const navigate = useNavigate()
    const shine = keyframes`
  0% {
    transform: translateX(0px) rotate(25deg);
  }
  100% {
    transform: translateX(2000px) rotate(25deg);
  }
`;

const boxStyle = {
  background: "#00C2FF",
  height: "62px",
  width: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: "10px",
  position: "relative",
  overflow: "hidden",
  marginTop:isMobile?"77px":"118px",
  '&:before': {
    content: '""',
    position: 'absolute',
    top: '-20px',
    left: 0,
    height: '100px',
    width: '100px',
    background: 'rgba(255,255,255, 0.3)',
    filter: 'blur(5px)',
    boxShadow: '0 0 10px rgba(255,255,255, 0.1)',
    transform: 'translateX(-100%)',
    animation: `${shine} 2s linear infinite`,
  },
};
    return (<>
    {isLaptop?<Box sx={boxStyle}>
                    <Typography sx={{ fontSize: {xs:"16px",md:"22px"}, fontWeight: "500", lineHeight: "33px" }}>Exciting Changes Ahead <Typography sx={{ fontSize: {xs:"16px",md:"20px"}, fontWeight: "700", lineHeight:{xs:"24px", md:"30px"},background:"yellow",padding:  {xs:"4px 20px",md:"8px 20px"},borderRadius:"23px",display:"inline" }}>  A Fresh New Look with Enhanced User Experience!</Typography></Typography>
                </Box>:<Box sx={[boxStyle,{background:"#00C2FF",minHeight:"62px",width:"100%",display:"flex",justifyContent:"center",alignItems:"center",gap:"10px"}]}>
                    <Typography sx={{ fontSize: {xs:"16px",md:"22px"}, fontWeight: "500", lineHeight: "33px" }}> <Typography sx={{ fontSize: {xs:"16px",md:"20px"}, fontWeight: "700", lineHeight:{xs:"24px", md:"30px"},background:"yellow",padding:  {xs:"4px 10px",md:"8px 20px"},borderRadius:"23px",display:"inline" }}>Exciting Changes Ahead!</Typography> Check Us Out. </Typography>
                </Box>

    }
        
        <Box sx={{ ...landingPage_styles.introduction.page_prop }}>
            
            <Box sx={{display:"flex",flexDirection:"column",gap:"16px",width:"100%",alignItems:"center"}}>
                
                 <motion.div  viewport={{ once: true, amount: 0.8 }} initial={{ opacity: 0, y: 100 }} whileInView={{y:0.5,opacity:1}} transition={{easings:"ease-in-out",duration:.8}}><Typography sx={{ ...landingPage_styles.introduction.heading1, ...landingPage_styles.fontFamily }}>INTRODUCING</Typography></motion.div>
                 <motion.div  viewport={{ once: true, amount: 0.8 }} initial={{ opacity: 0, y: 100 }} whileInView={{y:0.5,opacity:1}} transition={{easings:"ease-in-out",duration:.8}}><Typography sx={{ ...landingPage_styles.introduction.heading2, ...landingPage_styles.fontFamily  }}>The First Innovative {isLaptop&&<br/>} Holistic Wellness Social Enterprise Movement. <span style={landingPage_styles.introduction.heading2_gradient}>Now Proudly in India</span> </Typography></motion.div>
                 <motion.div  viewport={{ once: true, amount: 0.8 }} initial={{ opacity: 0, y: 100 }} whileInView={{y:0.5,opacity:1}} transition={{easings:"ease-in-out",duration:.8}}><Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <Button onClick={()=>navigate('/signup')} sx={{...landingPage_styles.introduction.button,...landingPage_styles.buttonAnimation}}><Typography sx={landingPage_styles.introduction.buttonText}>Signup for Free <ArrowForwardIcon/></Typography></Button>
                </Box></motion.div>
                <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    
                    <Box component="img" src={intro_image} sx={landingPage_styles.introduction.image}></Box>
                </Box>
            </Box>
        </Box></>)
}