import { Box, Button, Typography, keyframes } from "@mui/material";
import hero from "../../src/staticPageComponents/assets/Hero_1 (2).png"
import {easeInOut, motion, useAnimation, useInView, useScroll, useTransform} from "framer-motion"
import { useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";

const shine = keyframes`
  0% {
    transform: translateX(0px) rotate(25deg);
  }
  100% {
    transform: translateX(2000px) rotate(25deg);
  }
`;


export function Introduction (params){
    const isMobile = useMediaQuery({ maxWidth: 600 });
    const isLaptop = useMediaQuery({ maxWidth: 900 });
    const boxStyle = {
        background: "#00C2FF",
        minHeight: "62px",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        gap: "10px",
        position: "relative",
        overflow: "hidden",
        marginTop:"84px",
        '&:before': {
          content: '""',
          position: 'absolute',
          top: '-20px',
          left: 0,
          height: '100px',
          width: '100px',
          background: 'rgba(255,255,255, 0.3)',
          filter: 'blur(5px)',
          boxShadow: '0 0 10px rgba(255,255,255, 0.1)',
          transform: 'translateX(-100%)',
          animation: `${shine} 2s linear infinite`,
        },
      };
    
    const navigate = useNavigate()
    return(
    <Box sx={{height:{xs:"120vh",md:"115vh"},width:"100%",display:"flex",flexDirection:"column",}}>
        {isLaptop?<Box sx={boxStyle}>
                    <Typography sx={{ fontSize: {xs:"16px",md:"22px"}, fontWeight: "500", lineHeight: "33px" }}> <Typography sx={{ fontSize: {xs:"16px",md:"20px"}, fontWeight: "700", lineHeight:{xs:"24px", md:"30px"},background:"yellow",padding:  {xs:"4px 10px",md:"8px 20px"},borderRadius:"23px",display:"inline" }}>Exciting Changes Ahead!</Typography> Visit Us Back.</Typography>
                </Box>:<Box sx={[boxStyle,{background:"#00C2FF",minHeight:"62px",width:"100%",display:"flex",justifyContent:"center",alignItems:"center",gap:"10px"}]}>
                <Typography sx={{ fontSize: {xs:"16px",md:"22px"}, fontWeight: "500", lineHeight: "33px" }}>Exciting Changes Ahead <Typography sx={{ fontSize: {xs:"16px",md:"20px"}, fontWeight: "700", lineHeight:{xs:"24px", md:"30px"},background:"yellow",padding:  {xs:"4px 20px",md:"8px 20px"},borderRadius:"23px",display:"inline" }}>  A Fresh New Look with Enhanced User Experience!</Typography></Typography>
                </Box>

    }
    
    <Box id="info" ref={params.static} sx={{width:"100%",display:"flex",justifyContent:"center",marginBottom:"150px",background:"#eff1f3",padding:{xs:"16px",sm:"16px",zIndex:3}}}>
        <Box sx={{display:"flex",gap:"20px",alignItems:"center",maxWidth:"1440px",width:"100%",flexDirection:isMobile|| isLaptop?"column":"row"}}>
            <Box sx={{display:"flex",flexDirection:"column",alignItems:"center",width:isMobile|| isLaptop?"100%":"60%",textAlign:isMobile|| isLaptop?"left":"auto"}}>
                <Box sx={{width:"100%"}}>
                <Box sx={{display:"flex",textAlign:"left",width:"100%",justifyContent:"left"}}>
               <motion.div initial={{opacity:0,x:150}} animate={{opacity:1,x:0}} transition={{delay:.2,easings:"ease-in-out",duration:1}}> <Typography sx={{color:"grey.500",fontSize:isMobile|| isLaptop?"19.5px":"2.2em",fontWeight:"300",lineHeight:"1em",letterSpacing:isMobile|| isLaptop?"1.5px":"2.5px",marginBottom:"4px",fontFamily:"Poppins"}}>INTRODUCING</Typography></motion.div> 
            </Box>
            <motion.div initial={{opacity:0,x:150}} animate={{opacity:1,x:0}} transition={{delay:.7,easings:"ease-in-out",duration:1}}> <Typography sx={{color:"black",fontSize:isMobile|| isLaptop?"52px":"4.8em",fontWeight:"800",lineHeight:"1em",letterSpacing:"-2px",marginBottom:"1.5rem",width:"100%",fontFamily:"Poppins"}}>The First Innovative</Typography></motion.div> 
                <Box sx={{display:"flex",textAlign:"left",width:"100%",marginBottom:"1.5rem",justifyContent:"right"}}>
                <motion.div initial={{opacity:0,x:150}} animate={{opacity:1,x:0}} transition={{delay:.5,easings:"ease-in-out",duration:1}} style={{width:"100%"}}> <Typography sx={{color:"grey.600",fontSize:isMobile|| isLaptop?"16.9px":"1.5em",fontWeight:"300",lineHeight:"26px",textAlign:"left",fontFamily:"Poppins",width:"100%"}}>Holistic Wellness Social Enterprise Movement</Typography></motion.div> 
                </Box>
                <motion.div initial={{opacity:0,x:150}} animate={{opacity:1,x:0}} transition={{delay:1,easings:"ease-in-out",duration:1}}><Box sx={{display:"flex",gap:"1.5rem",alignItems:"center",justifyContent:isMobile|| isLaptop?"left":"center"}}>

                    <Typography sx={{color:"grey.600",fontSize:isMobile|| isLaptop?"15.6px":"1.2em",fontWeight:"300",lineHeight:"26px",fontFamily:"Poppins"}}>Starting at</Typography>
                    <Typography sx={{color:"#EF524E",fontSize:isMobile|| isLaptop?"2.4em":"3em",fontWeight:"700",lineHeight:"26px",letterSpacing:"-2px",fontFamily:"Poppins"}}>Rs. 1120</Typography>
                   { !isMobile && <Button variant="contained"  sx={{width:isMobile?"100%":"220px",maxWidth:"220px",height:"54px",padding: "24px", borderRadius: "50px" ,textTransform:"none", fontFamily: "Poppins",}} onClick={()=> navigate("/signup")}><Typography sx={{fontWeight:700,fontFamily:"Poppins",fontSize:"15px"}}>Sign Up for Free</Typography></Button>}

                </Box></motion.div> 
                { isMobile && <Button variant="contained"  sx={{width:isMobile?"100%":"220px",maxWidth:"220px",marginTop:"16px",height:"54px",padding: "24px", borderRadius: "50px" ,textTransform:"none", fontFamily: "Poppins",}} onClick={()=> navigate("/signup")}><Typography sx={{fontWeight:700,fontFamily:"Poppins",fontSize:"15px"}}>Sign Up for Free</Typography></Button>}

                </Box>
           
            </Box>
            <Box sx={{display:"flex",flexDirection:"column",alignItems:"center",width:isMobile|| isLaptop?"100%":"50%",justifyContent:"flex-end"}}>
        <motion.div initial={{ opacity: 0, y: 150 }} whileInView={{y:0,opacity:1}}  viewport={{once: false}} transition={{duration:1.5,ease:easeInOut}}>
        <Box component='img' src={hero} sx={{height:isMobile|| isLaptop?"240px":"801px",maxWidth:isMobile|| isLaptop?"325px":"1400px",marginRight:isMobile|| isLaptop?"auto":"-500px",marginBottom:isMobile|| isLaptop?"auto":"-200px",overflow:"visible",objectFit:"cover"}}>
                </Box>
        </motion.div>
           

            </Box>

        </Box>
    </Box>
    <svg style={{marginTop:"-150px",background:"#eff1f3",zIndex:"-2"}} xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 1920 215" preserveAspectRatio="xMinYMin"><path fill="#FFF" d="M-13,24c3.03-0.28,7.53-0.66,13-1c26.45-1.65,46.73-0.59,57,0c76.61,4.41,118,9,118,9  c70.55,7.83,105.82,11.74,151,19c32.98,5.3,65.87,11.44,179,37c145.79,32.94,138.71,33.5,203,47c85.09,17.87,149.08,31.32,237,40  c113.11,11.17,203.29,8.29,234,7c16.74-0.7,63.49-4.13,157-11c107.74-7.91,117.84-9.32,153-10c52.52-1.02,97.8,0.79,153,3  c21.27,0.85,54.96,2.38,96,5c37.55,2.4,68.64,4.91,119,9c30.97,2.52,56.12,4.64,72,6c0,13.33,0,26.67,0,40c-646,0-1292,0-1938,0  C-10.33,157.33-11.67,90.67-13,24z"></path></svg>
    </Box>
    )
}