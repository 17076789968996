
import { Box } from "@mui/material"
import LoginForm from "../forms/login_form"
import background from "../assets/loginbackground.jpeg"
import { useMediaQuery } from "react-responsive"
import { GoogleReCaptchaProvider} from "react-google-recaptcha-v3";

const Login=()=>{
    const isMobile = useMediaQuery({ maxWidth: 600 });
    const isLaptop = useMediaQuery({ query: '(min-width: 900px)' });
    


    return( <Box sx={{width:"100%",bgcolor:"black",display: "flex", alignItems: 'center', justifyContent: "center", flexDirection: 'column'}}>
   {!isMobile&& <Box  sx={{height:"100vh",width:"100%"}} component='img' src={background} />} 
    {/* <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_CAPTCHA_SITEKEY}> */}
        <LoginForm />
    {/* </GoogleReCaptchaProvider> */}
        </Box>
    )
}

export default Login