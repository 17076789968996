
import { Box } from "@mui/material"
import PasswordChangeForm from "../forms/password_change"
import background from "../assets/Background.jpg"
import { useMediaQuery } from "react-responsive"
import { Link, useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
const PasswordChange=()=>{
    const authtoken = localStorage.getItem("authtoken")
    const param = useParams()
    // // console.log(authtoken)
    const isMobile = useMediaQuery({ maxWidth: 600 });
    const isTablet= useMediaQuery({ maxWidth: 900,minWidth:600 });
    const isLaptop = useMediaQuery({ query: '(min-width: 900px)' });
    // console.log(param)
    useEffect(()=>{
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    
    },[])
    return(
         <Box sx={{width:"100%",display: "flex", alignItems: 'center', justifyContent: "center", flexDirection: 'column'}}>
        {!isMobile && !isTablet && param.id!="change" ? <Box  sx={{height:"100vh",width:"100%"}} component='img' src={background} />:<></>} 
         <PasswordChangeForm />
         </Box>
    )
}

export default PasswordChange