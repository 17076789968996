import { createSlice } from "@reduxjs/toolkit";


const initialState = {
    activeSideBar:"pr",
    sideBar:false,
    cartSidebar:false,

    
};


const sideBar = createSlice({
    name: "sideBar",
    initialState: initialState,
    reducers: {
        updateActiveSideBAr:(state, { type, payload }) => {
            
            state.activeSideBar = payload
        },
        updateSideBar:(state, { type, payload }) => {
            // console.log(payload)
            state.sideBar = payload
        },
        updateCartSideBar:(state, { type, payload }) => {
            
            state.cartSidebar = payload
        },
    }
    })

    export const { 
      updateActiveSideBAr,updateSideBar,updateCartSideBar
    } = sideBar.actions
    
    
    
    export default sideBar.reducer;