import { Modal, Box, Typography, InputBase, Button, RadioGroup, Radio, FormControlLabel } from "@mui/material"
import React, { useEffect, useState } from "react";

import { UseSelector, useDispatch, useSelector } from "react-redux";
import { updateAddress, updateAddressModal, updateFullAdress, updateAllAddress } from "../redux/user_reducer";
import { formStyle } from "../modules/profile";
import { useMediaQuery } from "react-responsive";
import axios from "axios";
import CustomAxios from '../utils/CustomAxios';
const Address = () => {
    const [open, setOpen] = React.useState(false);
    const dispatch = useDispatch()
    const addressModal = useSelector((state) => state.user.addressModal)
    const address = useSelector(state => state.user.address)
    const [errors, setErrors] = useState({})
    const id = useSelector(state => state.user.id)
    const res=useSelector(state=>state.user.member_response)
    // // console.log(addressModal)
    useEffect(() => {
        // // console.log(addressModal)
        setOpen(addressModal);
    }, [addressModal])
    const handleOpen = () => {
        setOpen();
    };
    const handleClose = () => {
        dispatch(updateAddressModal(false))
        setOpen(false);
    };
    const validation = (address) => {

        let valid = true;
        const newErrors = {};
        // // console.log(formData)
        // Name validation
        // if (!address.address_type?.trim()) {
        //     newErrors.address_type = 'Please Select Address Type';
        //     valid = false;
        // }
        if (!address.title?.trim()) {
            newErrors.title = 'Please Enter your title here';
            valid = false;
        }

        if (!address.town_city?.trim()) {
            newErrors.town_city = 'Please Enter your city here';
            valid = false;
        }
        // if (!address.country?.trim()) {
        //     newErrors.country = 'Please Enter your country here';
        //     valid = false;

        // }
        if (!address.pin?.trim()) {
            newErrors.pin = 'Please Enter your Zipcode here';
            valid = false;

        }
        if (!address.address?.trim()) {
            newErrors.address = 'Please Enter your street here';
            valid = false;

        }
        if (!address.state?.trim()) {
            newErrors.state = 'Please Enter your state here';
            valid = false;

        }
        // // console.log(address?.mobileno?.length)
        if (address?.mobileno?.length < 10){
            newErrors.mobile = 'Enter a 10-digit number only.'
            valid = false;
        }
        if (address?.mobileno?.length > 10 || !address.mobileno?.trim()){
            newErrors.mobile = 'Enter a valid mobile numer'
            valid = false;
        }


        // // console.log(newErrors)
        //
        // // Set errors state
        setErrors(newErrors);

        return valid;



    }
    const handleSubmit = (del) => {

        // handleClose()

        const validate = validation(address)
        if (validate) {
            submitAddress(del)
        }


    }
    const submitAddress = async (del) => {
// // console.log(del)
        if (address.id && del!=='delete') {
            await CustomAxios.patch(`addressupdate/${address.id}`, {
                account: res.account.id,
                address_type: address.address_type,
                address: address.address,
                title: address.title,
                country: "India",
                town_city: address.town_city,
                state: address.state,
                pin: address.pin,
                mobileno: address.mobileno,
                created_by: res.user.id,
                updated_by: res.user.id

            }).then(() => {
                handleClose()
            })

        }
        else if( del!=='delete') {
            await CustomAxios.post(  `address/`, {
                account: res.account.id,
                address_type: address.address_type,
                address: address.address,
                title: address.title,
                country: "India",
                town_city: address.town_city,
                state: address.state,
                pin: address.pin,
                mobileno: address.mobileno,
                created_by: res.user.id,
                updated_by:res.user.id

            }).then(() => {
                handleClose()
            })

        }
        else if(del=='delete'){
            await CustomAxios.delete(`addressupdate/${address.id}`).then(()=>handleClose())
            localStorage.removeItem("address")
        }


        if(res.account){

            const useraddress = await CustomAxios.get(`address/${res.account?.id}`)
            // // console.log(useraddress)
            localStorage.setItem("address", JSON.stringify(useraddress.data))
            dispatch(updateAllAddress(useraddress.data))
        }
    }

    const isMobile = useMediaQuery({ maxWidth: 600 });

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="child-modal-title"
            aria-describedby="child-modal-description"

        >
            <Box sx={{ width: isMobile ? "calc(100% - 64px)" : "600px", background: "#fff", left: "50%", top: "50%", position: "absolute", transform: 'translate(-50%, -50%)', padding: "32px", border: "none" }}>
                <Typography sx={{ textAlign: "center", }}>{address.id ? "Edit Address" : "Add New Address"}</Typography>
                <Box sx={{ display: "flex", flexDirection: 'column', gap: "16px", width: "100%" }}>
                    {/* <Box >
                        <Typography sx={formStyle.label} color="grey.700"> Type</Typography>
                        <RadioGroup
                            aria-labelledby="demo-controlled-radio-buttons-group"
                            name="controlled-radio-buttons-group"
                            sx={{ flexDirection: "row" }}
                            value={address.address_type}
                            onChange={(e) => dispatch(updateAddress({ ...address, address_type: e.target.value }))}
                        >
                            <FormControlLabel value="billing" control={<Radio />} label="Billing" />
                            <FormControlLabel value="shipping" control={<Radio />} label="Shipping" />
                        </RadioGroup>
                        {errors.address_type && <Typography sx={{ color: "error.light" }}>{errors.address_type}</Typography>}
                    </Box> */}

                    <Box >
                        <Typography sx={formStyle.label} color="grey.700"> Title</Typography>
                        <InputBase sx={formStyle.inputBase} value={address.title} onChange={(e) => dispatch(updateAddress({ ...address, title: e.target.value }))} />
                        {errors.title && <Typography sx={{ color: "error.light" }}>{errors.title}</Typography>}
                    </Box>
                    <Box>
                    <Typography sx={formStyle.label} color="grey.700"> Mobile No.</Typography>
                    <Box sx={{ display: "flex", alignItems: "center" }}>

                    <Box sx={{display:"flex", flexDirection:"column", gap:"8px", width:"100%"}}>
                    <Box sx={{display:"flex", flexDirection:"row", width:"100%"}}>
                        <InputBase value="+91" disabled sx={{ border: "1px solid", borderColor: "grey.400", width: "60px", borderRadius: "5px", height: "48px", fontSize: "16px", padding: "14px", color: "grey.700" }}> </InputBase>
                        <InputBase sx={formStyle.inputBase} value={address.mobileno} onChange={(e) => dispatch(updateAddress({ ...address, mobileno: e.target.value }))} />
                        </Box>
                        {errors.mobile && <Typography sx={{ color: "error.light" }}>{errors.mobile}</Typography>}
                        </Box>
                    </Box>
                    </Box>
                    
                    <Box sx={{ display: "flex", width: "100%", gap: "36px" }}>
                        <Box sx={{ width: "100%" }}>
                            <Typography sx={formStyle.label} color="grey.700"> Country</Typography>
                            <InputBase readOnly sx={formStyle.inputBase} value={"India"} onChange={(e) => dispatch(updateAddress({ ...address, country: e.target.value }))} />
                            {errors.country && <Typography sx={{ color: "error.light" }}>{errors.country}</Typography>}
                        </Box>

                        <Box sx={{ width: "100%" }}>
                            <Typography sx={formStyle.label} color="grey.700"> City</Typography>
                            <InputBase sx={formStyle.inputBase} value={address.town_city} onChange={(e) => dispatch(updateAddress({ ...address, town_city: e.target.value }))} />
                            {errors.town_city && <Typography sx={{ color: "error.light" }}>{errors.town_city}</Typography>}
                        </Box>
                    </Box>
                    <Box sx={{ display: "flex", width: "100%", gap: "36px" }}>


                        <Box sx={{ width: "100%" }}>
                            <Typography sx={formStyle.label} color="grey.700"> State</Typography>
                            <InputBase sx={formStyle.inputBase} value={address.state} onChange={(e) => dispatch(updateAddress({ ...address, state: e.target.value }))} />
                            {errors.state && <Typography sx={{ color: "error.light" }}>{errors.state}</Typography>}
                        </Box>
                        <Box sx={{ width: "100%" }}>
                            <Typography sx={formStyle.label} color="grey.700"> ZIP</Typography>
                            <InputBase sx={formStyle.inputBase} value={address.pin} onChange={(e) => dispatch(updateAddress({ ...address, pin: e.target.value }))} />
                            {errors.pin && <Typography sx={{ color: "error.light" }}>{errors.pin}</Typography>}
                        </Box>
                    </Box>
                    <Box>
                        <Typography sx={formStyle.label} color="grey.700">Street Address</Typography>

                        <InputBase multiline rows={5} sx={formStyle.textBox} value={address.address} onChange={(e) => dispatch(updateAddress({ ...address, address: e.target.value }))} />
                        {errors.address && <Typography sx={{ color: "error.light" }}>{errors.address}</Typography>}

                        {/* {errors.userName && <Typography sx={{color:"error.light"}}>{errors.userName}</Typography>} */}
                    </Box>
                    <Box sx={{display:"flex",width:"100%",gap:"24px",justifyContent:"space-between"}}>
                        {address.id&&<Button sx={ { height: "3rem",padding:"0rem 1.25rem",textTransform: "none", fontWeight: 600, fontSize: "14px", maxWidth: "150px", alignSelf: "flex-end",borderRadius:"5px" }} color="error" variant="contained" onClick={()=>handleSubmit('delete')} >Delete</Button>}
                        <Box sx={{display:"flex", gap:"8px"}}>
                        <Button sx={ { height: "3rem",padding:"0rem 1.25rem",textTransform: "none", fontWeight: 600, fontSize: "14px", maxWidth: "150px", alignSelf: "flex-end",borderRadius:"5px" }} color="primary" variant="contained" onClick={handleClose} >Cancel</Button>
                        <Button sx={ { height: "3rem",padding:"0rem 1.25rem",textTransform: "none", fontWeight: 600, fontSize: "14px", maxWidth: "150px", alignSelf: "flex-end",borderRadius:"5px" }} color="primary" variant="contained" onClick={handleSubmit} >{address.id ? "Edit" : "Add"}</Button>
                        </Box>
                    </Box>



                </Box>
            </Box>
        </Modal>
    )


}

export default Address
