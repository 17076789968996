
import { Box, Button, Paper, Typography } from "@mui/material"

import { useDispatch } from "react-redux"
import { updateActiveSideBAr } from "../redux/active_bar"
import AdminSidebar from "../components/adminSideBar"
import Header from "../components/header"
import Header1 from "../components/header1"
import Products from "../components/product"
import OrderComponent from "../components/Orders"
import { useParams } from "react-router-dom"
import Order_details from "../components/order_details"


const Orders = () => {
    const { orderId } = useParams()
    // // console.log(orderId)
    const dispatch = useDispatch()
    // // console.log("its is orders page")

    return <>

        <Box sx={{ bgcolor: { sm: "grey.100", xs: "#fff" }, minHeight: "calc(100vh - 120px)" }}>
            <Header1 />
            <Box sx={{ display: "flex", paddingTop: "144px",gap:"24px",mx:"24px" , position:"relative"}}>
                <AdminSidebar />
                {
                    orderId ?
                        <Order_details orderId={orderId && orderId} />
                        :
                        <OrderComponent />
                }

            </Box>




        </Box>

    </>

}
export default Orders