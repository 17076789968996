import { createSlice } from "@reduxjs/toolkit";


let initialState = {
    message:"",
    severity:"",
    open:false
    
};


let SnackBar = createSlice({
    name: "snackbar",
    initialState: initialState,
    reducers: {
        updateSnackBarMessage:(state, { type, payload }) => {
            
            state.message = payload
        },
        updateSnackBarSeverity:(state, { type, payload }) => {
            
            state.severity = payload
        },
        updateSnackBarOpen:(state, { type, payload }) => {
            
            state.open = payload
        },
    }
    })

    export let { 
      updateSnackBarMessage,updateSnackBarSeverity,updateSnackBarOpen
    } = SnackBar.actions
    
    
    
    export default SnackBar.reducer;