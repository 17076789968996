import * as React from 'react';
import Box from '@mui/material/Box';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';

import { useSelector, useDispatch } from 'react-redux';
import { updateSideBar } from '../redux/active_bar';

import SideBarList from './list';
const Sidebar = () => {
    const dispatch = useDispatch()
    const [state, setState] = React.useState({
        right: false,
    });
    const sidebar = useSelector(state => state.sideBar.sideBar)
    React.useEffect(() => {
        // // console.log("toggle bar", sidebar)
        // toggleDrawer('right',sidebar)
        setState({ ...state, ['left']: sidebar })
    }, [sidebar])

    const toggleDrawer = (anchor, open) => (event) => {
        // // console.log(anchor)
        if (
            // sidebar
            event &&
            event.type === 'keydown' &&
            (event.key === 'Tab' || event.key === 'Shift')
        ) {
            return;
        }
        if (!open) {
            dispatch(updateSideBar(false))
        }
        setState({ ...state, [anchor]: open });
    };

    return (
        <div>

            <React.Fragment key={'bottom'}>
                {/* <Button onClick={toggleDrawer('right', true)} sx={{zIndex:"10"}}>{'right'}</Button> */}
                <SwipeableDrawer
                    anchor={"left"}
                    open={state["left"]}
                    onClose={toggleDrawer("left", false)}
                    onOpen={toggleDrawer("left", true)}
                    sx={{marginBottom:"200px"}}
                >
                    {SideBarList("left")}
                </SwipeableDrawer>
            </React.Fragment>

        </div>
    );
}
export default Sidebar