import { Box, } from "@mui/material"
import { Link } from "react-router-dom"
import ProductList from "./productList"
import { useEffect } from "react"
import { useDispatch } from "react-redux"
import { updateProduct } from "../redux/products"
const Products=()=>{
    const dispatch = useDispatch()
    useEffect(()=>{
        dispatch(updateProduct({}))
    },[])

return (
    <Box sx={{  width: "100%",display:"flex",flexDirection:"column", gap:"32px", maxWidth:"calc(100% - 327px)" }} >
        <ProductList/>
    </Box>
)
}

export default Products