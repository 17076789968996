import { Box, Button, Typography } from "@mui/material";
import { useMediaQuery } from "react-responsive";
import Footer from "../components/footer";
import Header from "../components/header";
import finance from "../staticPageComponents/assets/finance.png"
import { Link, useNavigate } from "react-router-dom";
import EastIcon from '@mui/icons-material/East';
import { useEffect } from "react";



export function FinancialSystem() {
    const isMobile = useMediaQuery({ maxWidth: 600 });
    const symbol = '>';
    const navigate = useNavigate();
const financialSystem = {
    header: { color: "black.500", fontSize: "32px", fontFamily: "'Poppins',sans-serif", fontWeight: "700", width: "100%", textAlign: "center", marginBottom: "44px" },
    title: { color: "grey.900", fontSize: "16px", fontWeight: "700", letterSpacing: "-1px", fontFamily: "'Poppins',sans-serif" },
    mayi: { color: "grey.500", fontSize: "16px", fontWeight: "400", letterSpacing: "-1px", fontFamily: "'Poppins',sans-serif", textTransform: "uppercase", letterSpacing: "5px", textAlign: "center", width: "100%" },
    content: { color: "grey.700", fontSize: "14px", fontFamily: "'Poppins',sans-serif", lineHeight: "26px" ,maxWidth:"550px",width:"100%"},
    image: { maxWidth: isMobile?"300px":"556px", width: "100%", height:isMobile?"255px": "481px",position:isMobile?"auto":"sticky",top:!isMobile&&"100px" }
}
useEffect(()=>{
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;

},[])
    return (
        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", width: "100%", overflow: "hidden" }}>
            <Header />
            <Box sx={{ display: "flex",height:isMobile?"100%":"calc(100vh - 84px)" , width: "100%", alignItems: "center", flexDirection: "column", marginTop: "84px",overflow:"auto"}}>
            <Box sx={{bgcolor:"grey.200",width:"100%",display:"flex",justifyContent:"center"}}>
                <Box sx={{ maxWidth: "1440px", width: "100%", display: "flex", alignItems:isMobile?"flex-start":"center",justifyContent:isMobile?"center":"space-between",height:"140px",flexDirection:isMobile?"column":"row",padding:isMobile&&"20px"}}>
                        <Typography sx={{fontSize:"30px",fontWeight:"500"}}>Financial System</Typography>
                        <Box sx={{display:"flex",justifyContent:"space-between",gap:"10px"}}>
                            <Typography onClick = {()=>navigate("/")} sx={{fontSize:"12px",fontWeight:"400",color:"#ea4d2a",cursor:"pointer"}}>HOME</Typography>
                            <Typography sx={{fontSize:"12px",fontWeight:"400"}}>{symbol}</Typography>
                            <Typography sx={{fontSize:"12px",fontWeight:"400",color:"grey.600"}}>FINANCIAL SYSTEM</Typography>
                        </Box>
                </Box>
            </Box>
                <Box sx={{ maxWidth: "1440px", width: "100%", display: "flex", flexDirection: "column",marginTop:"80px" }}>
                    <Box sx={{width:"100%",display:"flex",justifyContent:"center",marginBottom:"20px"}}>
                    <Box sx={{width:"50px",height:"6px",bgcolor:"primary.light",borderRadius:"10px",textAlign:"center"}}></Box>
                    </Box>
                    <Typography sx={financialSystem.mayi}>mayi iq</Typography>
                    <Typography sx={financialSystem.header}>Empowering Holistic Entrepreneurship</Typography>
                    <Box sx={{ display: "flex", gap: "20px",flexDirection:isMobile?"column":"row",padding:"20px" }}>
                        <Box sx={{ width: isMobile?"100%":"50%" ,display:"flex",justifyContent:"center"}}>
                            <Box sx={financialSystem.image} component="img" src={finance} ></Box>
                        </Box>
                        <Box sx={{ width: isMobile?"100%":"50%",display:"flex",flexDirection:"column",gap:"20px" }}>
                            <Typography sx={financialSystem.content}>In the realm of investments, it's rare to discover opportunities that not only address socio-economic needs but also prioritize the full realization of human potential.</Typography>

                            <Typography sx={financialSystem.content}> Nearly four decades ago, the seeds of MAYI IQ were planted organically, setting the stage for an extraordinary journey. What began as a vision has blossomed into a beacon of hope, inviting individuals to explore their inner capabilities for the greater good of humanity.</Typography>

                            <Typography sx={financialSystem.content}> Today, we stand at the precipice of what could be deemed as the ultimate fusion of business, wellness, and enlightenment.</Typography>

                            <Typography sx={financialSystem.content}>  At MAYI IQ, we offer more than just financial gains; we provide a platform for holistic growth and fulfillment. Our model transcends traditional investment paradigms, embracing a socially responsible approach where everyone has an equal chance to thrive.</Typography>

                            <Typography sx={financialSystem.content}>  Our ethos is rooted in intelligence, professionalism, innovation, and above all, ethical integrity. We've redefined the concept of Social Enterprise, making it not only viable but also highly rewarding.</Typography>

                            <Typography sx={financialSystem.content}>  Unlike conventional investment schemes, which often prioritize short-term gains over long-term sustainability, MAYI IQ strikes a balance between security and innovation. With over two decades of experience in the wellness and network marketing industries, coupled with stringent regulatory compliance, we offer a level of stability that is unmatched.</Typography>

                            <Typography sx={financialSystem.content}>  As we expand our footprint into new territories like India, we invite you to join us on this journey of growth and prosperity. With MAYI IQ, you have the opportunity to double your earnings with minimal effort, thanks to our unified financial system and wellness-focused products.</Typography>

                            <Typography sx={financialSystem.content}>  Central to our mission are the Five Pillars of MAYI: Holistic Health, Ethical Wealth, Universal Wisdom, Nurturing Relationships, and Realizing Potential. These pillars guide our commitment to giving back to society and nurturing a sense of community among our members.</Typography>

                            <Typography sx={financialSystem.content}>  Our unique Financial Freedom plan seamlessly integrates natural wellness with financial prosperity, eliminating the complexities of traditional network marketing. Say goodbye to mandatory monthly commitments, excessive inventory, and endless meetings. With MAYI IQ, you can unlock five streams of income from day one, with payments processed weekly and no lifetime renewal fees.</Typography>

                            <Typography sx={financialSystem.content}>  We believe in reinvesting profits into innovation and product development, ensuring continuous growth and technological advancement. With a leadership team boasting over 40 years of collective wisdom, we're confident in our ability to empower every member towards lifelong financial freedom and wellbeing.</Typography>

                            <Typography sx={financialSystem.content}>  So why wait? Choose MAYI IQ as your vehicle for growth, happiness, and financial liberation. Join us today on a journey towards a brighter tomorrow.</Typography>
                        
                            <Button component={Link}  to ="/signup" variant="contained" sx={{maxWidth:"550px", width: "100%", height: "38px", borderRadius: "50px" ,textTransform:"none",marginTop:"30px"}}><Typography sx={{fontWeight:"400",fontSize:isMobile?"11px":"15px",color:"#fff",textAlign:"center"}}>Become a member to enjoy benefits  <EastIcon/></Typography></Button>
                        
                        </Box>
                    </Box>
                </Box>
                <Footer />
            </Box>

        </Box>)
}