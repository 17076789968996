import { Box, Button, Typography } from "@mui/material";
import { landingPage_styles } from "./landing_page";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { useEffect, useState, useRef } from "react";
import axios from "axios";

import {  keyframes } from '@mui/system';
const scroll = keyframes`
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
`; 

export function GrowNetwork() {
    const navigate = useNavigate();
    const [products, setProducts] = useState([]);
    const scrollContainerRef = useRef(null);

    useEffect(() => {
        getProducts();
    }, []);

    useEffect(() => {
        const scrollInterval = setInterval(() => {
            if (scrollContainerRef.current) {
                const { scrollLeft, scrollWidth, clientWidth } = scrollContainerRef.current;
                const maxScrollLeft = scrollWidth - clientWidth;

                if (scrollLeft < maxScrollLeft) {
                    scrollContainerRef.current.scrollBy({ left: 1, behavior: 'smooth' }); // Adjust scroll amount for smoothness
                } else {
                    scrollContainerRef.current.scrollTo({ left: 0, behavior: 'smooth' });
                }
            }
        }, 10); // Decrease interval for smoother scrolling

        return () => clearInterval(scrollInterval);
    }, [products]);

    const getProducts = async () => {
        await axios(`${process.env.REACT_APP_BACKEND_BASE_URL}products/`)
            .then(({ data }) => {
                setProducts(data); // Get all products
            });
    };
    const productDetails = (id) => {
        navigate(`/product_details/${id}`)
    }
    return (
        <Box sx={{ ...landingPage_styles.whoWeAre.page_prop, zIndex: 2, bgcolor: "#F9F9FC" }}>
            <Box sx={{ display: "flex", flexDirection: "column", gap: { xs: "8px", md: "12px" } }}>
                <motion.div  viewport={{ once: true, amount: 0.8 }}  initial={{ opacity: 0, y: 100 }} whileInView={{ y: 0.5, opacity: 1 }} transition={{ easings: "ease-in-out", duration: .8 }}>
                    <Typography sx={{ ...landingPage_styles.whoWeAre.heading, color: "#F67423", textAlign: "center", textTransform: "uppercase" }}>
                        Grow Your Network
                    </Typography>
                </motion.div>
                <motion.div  viewport={{ once: true, amount: 0.8 }}  initial={{ opacity: 0, y: 100 }} whileInView={{ y: 0.5, opacity: 1 }} transition={{ easings: "ease-in-out", duration: .8 }}>
                    <Typography sx={{ ...landingPage_styles.whoWeAre.headingBold, textAlign: "center" }}>
                        Find Your Natural Balance with Ayurveda
                    </Typography>
                </motion.div>
                <motion.div  viewport={{ once: true, amount: 0.8 }}  initial={{ opacity: 0, y: 100 }} whileInView={{ y: 0.5, opacity: 1 }} transition={{ easings: "ease-in-out", duration: .8 }}>
                    <Typography sx={{ ...landingPage_styles.whoWeAre.content, textAlign: "center" }}>
                        Explore our collection of authentic Ayurvedic products. Discover natural solutions for a healthier, happier you!
                    </Typography>
                </motion.div>
            </Box>

            <Box sx={{  overflow: 'hidden',whiteSpace: 'nowrap',position: 'relative',width: '100%','&:hover > div' :{   animationPlayState: 'paused',   transform: 'scale(1.5)' },padding:"32px 0px"}}>
                {products.map((prod, index) => (
                    <Box sx={{display: 'inline-block',animation: `${scroll} 10s linear infinite`, minWidth: { xs: "216px"}, width: "216px",marginRight:"20px",'&:hover':{transform:"scale(1.1)"}  }}>
                    <Box key={index} sx={{ display: "flex", height: "308px", flexDirection: "column", gap: "20px", boxShadow: "4px 4px 20px 0px #0000001A", padding: { xs: "16px 20px", md: "16px 20px" }, alignItems: "center", minWidth: { xs: "216px"}, width: "216px", bgcolor: "white",cursor:"pointer"}} onClick={() => productDetails(prod.id)}>
                        <Box sx={{ maxWidth: { xs: "198px", md: "180px" }, minWidth: "127px" }} component="img" src={prod.cover_details?.original} alt={prod.name}></Box>
                        <Typography sx={{ fontSize: "16px", lineHeight: "24px", fontWeight: "600", textAlign: "center", width: "100%",textWrap:"wrap" }}>{prod.name}</Typography>
                    </Box>
                    </Box>
                ))}
                 </Box>

            <motion.div  viewport={{ once: true, amount: 0.8 }}  initial={{ opacity: 0, y: 100 }} whileInView={{ y: 0.5, opacity: 1 }} transition={{ easings: "ease-in-out", duration: .8 }}>
                <Button variant="contained" onClick={() => navigate('/our_product')} sx={{ ...landingPage_styles.contactUs.button, ...landingPage_styles.buttonAnimation }}>
                    <Typography sx={landingPage_styles.contactUs.buttonText}>Explore Products <ArrowForwardIcon /></Typography>
                </Button>
            </motion.div>
        </Box>
    );
}
