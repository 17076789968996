import * as React from 'react';
import { useEffect } from 'react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import CustomAxios from '../utils/CustomAxios';
import { useDispatch, useSelector } from 'react-redux';
import { updateAllOrders } from '../redux/orders';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';



export default function OrderList({texttoFilter}) {
  const dispatch = useDispatch()
  const allOrders = useSelector(state => state.orders.AllOrders)
  const navigate = useNavigate()
  const filtertext = texttoFilter
  useEffect(() => {
    getOrders()
  }, [])

  const getOrders = () => {
    CustomAxios.get('listOrders/').then((res) => {
      // console.log(res.data)
      dispatch(updateAllOrders(res.data))
    })
  }

  return (
    <TableContainer component={Paper} sx={{ bgcolor: "#fff" }}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead >
          <TableRow>
            {/* <TableCell sx={{ fontWeight: "600", textAlign: "center" }}>Tracking Number</TableCell> */}
            <TableCell sx={{ fontWeight: "600", textAlign: "center" }}>Order Id</TableCell>
            <TableCell sx={{ fontWeight: "600", textAlign: "center" }} align="right">Customer</TableCell>
            <TableCell sx={{ fontWeight: "600", textAlign: "center" }} align="right">Products</TableCell>
            <TableCell sx={{ fontWeight: "600", textAlign: "center" }} align="right">Order Date</TableCell>
            <TableCell sx={{ fontWeight: "600", textAlign: "center" }} align="right">Delivery Fee</TableCell>
            <TableCell sx={{ fontWeight: "600", textAlign: "center" }} align="right">Total</TableCell>
            <TableCell sx={{ fontWeight: "600", textAlign: "center" }} align="right">Status</TableCell>
            <TableCell sx={{ fontWeight: "600", textAlign: "center" }} align="right">Actions</TableCell>

          </TableRow>
        </TableHead>
        <TableBody>
          {
            allOrders?.length >= 1 && allOrders?.filter(item =>{ 
              const searchTerm = filtertext.toLowerCase();
              return (
                item?.razorpay_order_id?.toLowerCase()?.includes(searchTerm.toLowerCase()) ||
                item.user_details?.username?.toLowerCase()?.includes(searchTerm) ||
                item.user_details?.first_name?.toLowerCase()?.includes(searchTerm) ||
                item.user_details?.last_name?.toLowerCase()?.includes(searchTerm) 
            );}).map((order, o_id) => {
              return (

                <TableRow
                  key={o_id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  {/* <TableCell sx={{ fontWeight: "600", textAlign: "center" }}>
                    <InputBase fullWidth  endAdornment={<Edit sx={{width:"16px",height:"16px",cursor:"pointer"}} onClick={()=>// console.log("edit clicked for awb")}/>} sx={{border: "1px solid", borderColor: "grey.400", width: "100%", borderRadius: "5px", height: "48px", fontSize: "16px", padding: "14px", color: "grey.700" }} />
                  </TableCell> */}
                  <TableCell sx={{ fontWeight: "600", textAlign: "center" }}>{order.razorpay_order_id || order.cash_pickup_id||order.order_id}</TableCell>
                  <TableCell sx={{ fontWeight: "600", textAlign: "center" }}>{order?.user_details?.username || order?.user_details?.first_name + order?.user_details?.last_name}</TableCell>
                  <TableCell sx={{ fontWeight: "600", textAlign: "center" }}>{order.carts.length}</TableCell>
                  <TableCell sx={{ fontWeight: "600", textAlign: "center" }}>{new Date(order.created_on).toLocaleDateString()}</TableCell>
                  <TableCell sx={{ fontWeight: "600", textAlign: "center" }}>{parseFloat(order.delivery_charge)}</TableCell>
                  <TableCell sx={{ fontWeight: "600", textAlign: "center" }}>{parseFloat(order.total_amount)}</TableCell>
                  <TableCell sx={{ fontWeight: "600", textAlign: "center" }}>{order.status}</TableCell>
                  <TableCell sx={{ fontWeight: "600", textAlign:"center"}}>
                    {order.status === "Payment pending" ?
                      <VisibilityOff sx={{ color: "#6c6c6c80" }} />
                      :
                      <Visibility sx={{ color: "#6c6c6c", cursor: "pointer" }} onClick={() => navigate(`${order.razorpay_order_id || order.cash_pickup_id ||order.order_id }`)} />
                    }
                  </TableCell>
                </TableRow>
              )
            })
          }

        </TableBody>
      </Table>
    </TableContainer>
  );
}
