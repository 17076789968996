import { Box, Button, Divider, InputBase, Typography, InputAdornment, IconButton, Checkbox, CircularProgress, Paper } from "@mui/material";
import { updateEmail, updateMobile, updateFirstName, updateLastName, updatePassword, updateOtpCheck, updateMemberResponse, updateRole, updateId, updateProfile  } from "../redux/user_reducer";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import mayi_logo from "../assets/mayi_logo.png";
import signupImage from "../assets/Frame 91-1.jpg";
import CustomAxios from '../utils/CustomAxios';
import { useMediaQuery } from 'react-responsive';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import { jwtDecode } from 'jwt-decode';
const Otpverify=()=>{
    const level= useSelector(state=>state.user.OtpVerifyPopup)
    return level
}
export const inputStyles = {
    inputBase: { border: "1px solid", borderColor: "grey.400", maxWidth: { xs: "350px", sm: "100%" }, width: "100%", borderRadius: "5px", height: "48px", fontSize: "14px", padding: "14px", color: "grey.600" },
    verifyLabel: { fontWeight: 600, fontSize: "16px",  textAlign: "left",lineHeight:"24px",color:"primary.light" },
    label: { marginBottom: "12px", fontWeight: 400, fontSize: "14px", color: "grey.900", textAlign:"left" },
    verificationLabel: { fontWeight: 400, fontSize: "12px", color: "grey.900", textAlign:"left",lineHeight:"16px",marginLeft:"30px"},
    terms: { fontWeight: 400, fontSize: "14px", whiteSpace: "pre-wrap" },
    paper: { padding: { sm: "32px", xs: "32px" }, display: "flex", alignItems: 'center', justifyContent: "center", flexDirection: 'column', bgcolor: "#fff", width:Otpverify? {xs:'100%',sm:"596px"}: { sm: "50%", xs: "100%" } },
    button: { height: "3rem", textTransform: "none", fontWeight: 600, fontSize: "14px", maxWidth: "100%", "&:hover": { bgcolor: "primary.light", color: "primary.contrastText" }, borderRadius: "5px", fontSize: "", textTransform: 'none', "&:focus": { border: "0px solid white" } },
    otpbutton: { height: "48px",textTransform: "none", fontWeight: 400, fontSize: "16px", width: "100%",maxWidth:"150px", "&:hover": { bgcolor: "primary.light", color: "primary.contrastText" }, borderRadius: "5px", lineHeight:"24px", "&:focus": { border: "0px solid white" } },
    heading: {},
    logo: { width: "100px", cursor: "pointer" },
    subHeading: { marginTop: "12px", fontWeight: "600", fontSize: "14px" },
    constent: { marginBottom: "16px", fontWeight: "400", fontSize: "14px", textAlign: "center" }
};

const SignupForm = () => {
    const dispatch = useDispatch();
    const first_name = useSelector(state => state.user.first_name);
    const last_name = useSelector(state => state.user.last_name);
    const email = useSelector(state => state.user.email);
    const password = useSelector(state => state.user.password);
    const [showPassword, setShowPassword] = useState(false);
    const [referralCode, setReferralCode] = useState("");
    const [response, setResponse] = useState("");
    const [isloading, setIsloading] = useState(false);
    const navigate = useNavigate();
    const [agree, setAgree] = useState(false);
    const [errors, setErrors] = useState({});
    const queryParameters = new URLSearchParams(window.location.search);
    const clientId = "521447616048-relr5c80sac2rudgmfub8kantovfc5pe.apps.googleusercontent.com"; // Replace with your actual client ID
    const authtoken=localStorage.getItem("authtoken")
    useEffect(()=>{
        if (authtoken){
            navigate("/")
        }
    
    },[])
    
    const handleSummit = () => {
        const formData = {
            email: email,
            first_name: first_name,
            last_name: last_name,
            password: password,
            referralCode: referralCode
        };

        const validate = validateForm(formData);
        if (validate && agree) {
            signup();
        }
    };

    useEffect(() => {
        setReferralCode(queryParameters.get('code'));
    }, []);

    const signup = async () => {
        setIsloading(true);
        await CustomAxios.post(`signup/`, {
            email: email,
            first_name: first_name,
            last_name: last_name,
            password: password,
            referralcode: referralCode,
            isactive:false

        }).then(data => {
            setIsloading(false);
            localStorage.setItem("user", data.data);
            if (data.data.message === "Registration Successful!") {
                dispatch(updateOtpCheck('signup'));
                navigate("/otp-verification");
            } else {
                setResponse(data.data.message);
            }
        }).catch(error => {
            setIsloading(false);
            // console.log(error);
        });
    };

    const handleTogglePasswordVisibility = () => {
        setShowPassword((prevShowPassword) => !prevShowPassword);
    };

    const isMobile = useMediaQuery({ maxWidth: 600 });

    const validateForm = (formData) => {
        let valid = true;
        const newErrors = {};
        if (!formData.first_name.trim()) {
            newErrors.first_name = 'Enter your First name.​';
            valid = false;
        }
        if (!formData.last_name.trim()) {
            newErrors.last_name = 'Enter your Last name.​';
            valid = false;
        }

        const emailPattern = /^\S+@\S+\.\S+$/;
        if (!formData.email.trim()) {
            newErrors.email = 'Enter you email address.​';
            valid = false;
        } else if (!emailPattern.test(formData.email)) {
            newErrors.email = 'Please enter valid email address';
            valid = false;
        }

        if (!formData.password.trim()) {
            newErrors.password = 'Please enter your password.';
            valid = false;
        } else if (formData.password.length < 8 || !/[A-Z]/.test(formData.password) || !/[a-z]/.test(formData.password) || !/\d/.test(formData.password)) {
            newErrors.password = "The password must be at least 8 characters long and contain uppercase and lowercase letters and numbers.";
            valid = false;
        }
        if (!agree) {
            newErrors.checkbox = "Please agree to terms and Policy.​";
        }

        setErrors(newErrors);
        return valid;
    };

    useEffect(() => {
        dispatch(updateEmail(""));
        dispatch(updatePassword(""));
    }, [dispatch]);

    const passwordVisible = {
        endAdornment: (
            <InputAdornment position="end">
                <IconButton onClick={handleTogglePasswordVisibility} edge="end">
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
            </InputAdornment>
        ),
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            handleSummit();
        }
    };

    const homePage = () => {
        navigate("/");
    };

    // const handleGoogleSuccess = (credentialResponse) => {
    //     const details = jwtDecode(credentialResponse.credential);
    //     // console.log(details);
    //     const { given_name, family_name, email } = details;
    //     // Dispatch these details to update the Redux store or make an API call to register/login the user
    //     // console.log(given_name,family_name,email)
    //     dispatch(updateFirstName(given_name));
    //     dispatch(updateLastName(family_name));
    //     dispatch(updateEmail(email));

    //     // Redirect to the password page
    //     navigate("/");
        
    // };

    // const handleGoogleError = () => {
    //     // console.log('Login Failed');
    // };

    const handleGoogleSuccess = async (credentialResponse) => {
        const details = jwtDecode(credentialResponse.credential);
        // console.log(details);
        const { given_name, family_name, email } = details;
        // console.log(given_name, family_name, email);
        
        const payload = {
            email: email,
            first_name: given_name,
            last_name: "family_name" in details?family_name:"",
            referralcode: referralCode? referralCode:"",
            is_active: true,
            registration_type: "google"
        };
        // console.log(payload)
    
        // console.log(given_name);
        // console.log(family_name);
        // console.log(email);
    
        setIsloading(true);
        try {
            const response = await CustomAxios.post(`signup/`, payload);
            setIsloading(false);
    
            if (response.data.message === "Registration Successful!") {
                const decode = jwtDecode(response.data.access_token);
                localStorage.setItem("address", JSON.stringify(response.data.add));
                localStorage.setItem('authtoken', response.data.access_token);
                localStorage.setItem('refreshToken', response.data.refresh_token);
                // console.log(response.data.add);
                // console.log(decode);
                dispatch(updateMemberResponse(response.data));
                dispatch(updateFirstName(decode.first_name));
                dispatch(updateLastName(decode.last_name));
                dispatch(updateEmail(decode.email));
                dispatch(updateRole(decode.role));
                dispatch(updateId(decode.id));
                if (response.data.att.length > 0) {
                    dispatch(updateProfile(response.data.att[0].original));
                }
                dispatch(updateEmail(decode.email));
                navigate("/");
            } else {
                setErrors({ passworderror: response.data.message });
            }
        } catch (error) {
            setIsloading(false);
            console.error(error);
        }
    };
    



    

    return (
            <GoogleOAuthProvider clientId={clientId}>
                <Box component={!isMobile && Paper} sx={{ left: "50%", top: "50%", position: "absolute", transform: 'translate(-50%, -50%)', display: "flex", alignItems: { xs: "center", sm: "initial" }, flexDirection: { sm: "row", xs: "column" }, width: { md: "750px", xs: "100%" }, maxWidth: { xs: "100%", md: "750px" }, height: { xs: "auto", sm: "100vh", md: "auto" } }} >
                    <Box src={signupImage} sx={{ clipRule: "", width: { md: "40%", xs: "100%", sm: "40%" }, height: { xs: "50vh", sm: "100vh", md: "auto" }, maxheight: "80vh", overflowY: "scroll", backgroundSize: "auto", objectFit: "cover" }} component="img" />
                    <Box sx={{ padding: { sm: "32px", xs: "32px" }, display: "flex", alignItems: 'center', justifyContent: "center", flexDirection: 'column', bgcolor: "#fff", width: { sm: "60%", xs: "100%" } }}>
                        <Box src={mayi_logo} component='img' sx={inputStyles.logo} onClick={homePage} />
                        <Typography sx={inputStyles.subHeading} color="grey.900">Join us!</Typography>
                        <Typography sx={inputStyles.constent} color="grey.900"> Create your account and start exploring.</Typography>
                        <Box sx={{ display: "flex", flexDirection: 'column', gap: "16px", width: "100%" }}>
                            <Box sx={{ display: "flex", gap: "16px", flexDirection: { xs: "column", sm: "row" } }}>
                                <Box>
                                    <Typography sx={inputStyles.label} color="grey.700">First Name <span style={{ color: "red" }}>*</span></Typography>
                                    <InputBase sx={inputStyles.inputBase} placeholder="Enter your First Name" onKeyDown={handleKeyPress} onChange={(e) => dispatch(updateFirstName(e.target.value))} />
                                    {errors.first_name && <Typography sx={{ fontWeight: 400, fontSize: "14px", color: "error.light", marginBottom: "-20px" }}>{errors.first_name}</Typography>}
                                </Box>
                                <Box>
                                    <Typography sx={inputStyles.label} color="grey.700">Last Name <span style={{ color: "red" }}>*</span></Typography>
                                    <InputBase sx={inputStyles.inputBase} placeholder="Enter your Last Name" onKeyDown={handleKeyPress} onChange={(e) => dispatch(updateLastName(e.target.value))} />
                                    {errors.last_name && <Typography sx={{ fontWeight: 400, fontSize: "14px", color: "error.light", marginBottom: "-20px" }}>{errors.last_name}</Typography>}
                                </Box>
                            </Box>
                            <Box>
                                <Typography sx={inputStyles.label} color="grey.700">Email <span style={{ color: "red" }}>*</span></Typography>
                                <InputBase sx={inputStyles.inputBase} placeholder="Enter email address" onKeyDown={handleKeyPress} onChange={(e) => dispatch(updateEmail(e.target.value))} />
                                {errors.email && <Typography sx={{ fontWeight: 400, fontSize: "14px", color: "error.light", marginBottom: "-20px" }}>{errors.email}</Typography>}
                            </Box>
                            <Box>
                                <Typography sx={inputStyles.label} color="grey.700">Password <span style={{ color: "red" }}>*</span></Typography>
                                <InputBase type={showPassword ? 'text' : 'password'} placeholder="Enter password" sx={inputStyles.inputBase} onKeyDown={handleKeyPress} onChange={(e) => dispatch(updatePassword(e.target.value))} endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton onClick={handleTogglePasswordVisibility} edge="end">
                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                } />
                                {errors.password && <Typography sx={{ fontWeight: 400, fontSize: "14px", color: "error.light", marginBottom: "-20px" }}>{errors.password}</Typography>}
                            </Box>
                            <Box>
                                <Typography sx={inputStyles.label} color="grey.700">Sponsor Referral Code</Typography>
                                <InputBase sx={inputStyles.inputBase} value={referralCode} placeholder="Enter Referral Code" onKeyDown={handleKeyPress} onChange={(e) => setReferralCode(e.target.value)} />
                                {errors.referralCode && <Typography sx={{ fontWeight: 400, fontSize: "14px", color: "error.light", marginBottom: "-20px" }}>{errors.referralCode}</Typography>}
                            </Box>
                            <Box>
                                <Box sx={{ display: "flex", alignItems: "center", }}>
                                    <Checkbox
                                        checked={agree}
                                        onChange={() => setAgree(!agree)}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                    />
                                    <Typography sx={inputStyles.terms} ><a href="https://mayiiq.com/terms-of-services/" style={{ textDecoration: "none", color: "rgba(39, 161, 255, 1)", fontWeight: "400", textDecorationLine: "underline" }} >Terms&nbsp;of&nbsp;Services</a >&nbsp;and&nbsp;<a style={{ textDecoration: "none", color: "rgba(39, 161, 255, 1)", fontWeight: "400", textDecorationLine: "underline" }} href="https://mayiiq.com/privacy-policy/">Privacy Policy</a></Typography>
                                </Box>
                                {errors.checkbox && <Typography sx={{ fontWeight: 400, fontSize: "14px", color: "error.light", marginBottom: "-20px" }}>{errors.checkbox}</Typography>}
                            </Box>
                            <Typography sx={{ color: "error.light" }}>{response}</Typography>
                            <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                                <Button variant="contained" sx={inputStyles.button} onClick={handleSummit} >{isloading ? <CircularProgress color="inherit" size={30} /> : "Sign up"}</Button>
                            </Box>
                            <Box sx={{ display: 'flex', justifyContent: "center" }}>
                                <Typography sx={{ textAlign: "center", fontSize: "14px" }}> Already have an account?&nbsp;</Typography><Link to="/login" style={{ textDecoration: "none", textDecorationLine: "underline", fontSize: "14px" }}><Typography sx={{ textDecoration: "none", fontSize: "14px" }} color="primary">Sign in</Typography></Link>
                            </Box>
                            <Divider sx={{ marginTop: "24px", marginBottom: "20px", fontSize: "14px" }} >Or</Divider>
                            <Box sx={{ display: "flex", justifyContent: "center", marginTop: "16px" }}>
                                <GoogleLogin
                                    onSuccess={handleGoogleSuccess}
                                    // onError={handleGoogleError}
                                />
                            </Box>
                            {/* <Divider sx={{ marginTop: "24px", marginBottom: "20px", fontSize: "14px" }} >Or</Divider> */}
                            
                        </Box>
                    </Box>
                </Box>
            </GoogleOAuthProvider>
        );

};
export default SignupForm;