import * as React from 'react';
import { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { updateAllProduct } from '../redux/products';
import { Alert, Box, Button, Snackbar } from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';
import { Link, useNavigate } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { updateSnackBarOpen } from '../redux/snackbar';
import { useMediaQuery } from 'react-responsive';


const MessageBar = () => {
    const dispatch = useDispatch()
    const snackMsg = useSelector(state=>state.snackBar.message)
    const snackOpen = useSelector(state=>state.snackBar.open)
    const snackSeverity = useSelector(state=>state.snackBar.severity)

    
    const handleSnackClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        dispatch(updateSnackBarOpen(false))
    };
    
    const Snackaction = (
        <React.Fragment>
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleSnackClose}
            >
                <CloseIcon fontSize="small" />
            </IconButton>
        </React.Fragment>
    );
    const isTablet = useMediaQuery({ maxWidth: 900 });
    return (
        snackOpen && <Snackbar
            anchorOrigin={{
                vertical:isTablet?"bottom":"top",
                horizontal: isTablet?"center":"right"
            }}
            sx={{marginBottom:{xs:"80px",md:"0px"},marginTop:{xs:"0px",md:"80px"}}}
            open={snackOpen}
            autoHideDuration={4000}
            onClose={handleSnackClose}
        >
            <Alert onClose={handleSnackClose} severity={snackSeverity} variant="filled">
                {snackMsg}
            </Alert>
        </Snackbar>
    )
}

export default MessageBar