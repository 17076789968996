import Footer from "../components/footer"
import { Box, Menu, Typography } from "@mui/material"
import { useNavigate } from "react-router-dom"
import { useEffect } from "react";
import Header1 from "../components/header1";

export const style = {
    title: { color: "grey.900", fontSize: "24px", fontWeight: "500", letterSpacing: "-1px", fontFamily: "'Poppins',sans-serif", lineHeight: "1.2em", marginBottom: "26px" },
    content: { color: "grey.700", fontSize: "14px", fontFamily: "'Poppins',sans-serif", lineHeight: "26px" }
}

const ShippingPolicy = () => {
    useEffect(()=>{
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;

    },[])
    const navigate = useNavigate()
    return <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", width: "100%", overflow: "hidden" }}>
        <Header1 />
        <Box sx={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
            <Box sx={{ marginTop: {xs:"77px",md:"118px"}, height: "150px", bgcolor: "grey.200", width: "100%", display: "flex", justifyContent: "center" }}>
                <Box sx={{ padding: { xs: "16px", sm: "24px" }, display: "flex", alignItems: "center", flexDirection: { xs: "column", sm: "row" }, justifyContent: "space-between", maxWidth: "1440px", width: "100%" }}>
                    <Typography sx={{ fontSize: "30px", fontWeight: "500" }}>Shipping Policy</Typography>
                    <Box sx={{ display: "flex", justifyContent: "space-between", gap: "10px" }}>
                        <Typography onClick={() => navigate("/")} sx={{ fontSize: "12px", fontWeight: "400", color: "#ea4d2a", cursor: "pointer" }}>Home</Typography>
                        <Typography sx={{ fontSize: "12px", fontWeight: "400" }}>{">"}</Typography>
                        <Typography sx={{ fontSize: "12px", fontWeight: "400", color: "grey.600" }}>Shipping Policy</Typography>
                    </Box>

                </Box>

            </Box>
            <Box sx={{ maxWidth: "1440px", padding: { xs: "16px", sm: "24px" } }}>
                {/* <Divider sx={{ marginTop: "20px", marginBottom: "10px" }} /> */}
                <Box sx={{ width: "100%", display: "flex", justifyContent: "center", marginTop: "70px", flexDirection: "column", gap: "24px" }}>
                    <Box>

                        <Typography sx={style.title}>Overview</Typography>
                        <Typography sx={style.content}>Thank you for shopping with us at <a style={{color:"rgb(39,162,253,1)",cursor:"pointer",textDecoration:"none"}} href="https://mayiiq.com" target="_blank" >https://mayiiq.com</a>. We strive to provide you with the best products and services, ensuring your satisfaction with every purchase. Please take a moment to review our return policy outlined below.</Typography>
                    </Box>
                    <Box>
                        <Typography sx={style.title}>Order Processing</Typography>
                        <Typography sx={style.content}>We aim to process orders within 1-3 business days after receiving your confirmation.</Typography>
                    </Box>
                    <Box>

                        <Typography sx={style.title}>Shipping Methods</Typography>
                        <Typography sx={style.content}>We offer several shipping methods to cater to your needs and budget. The available options and estimated delivery times will be displayed during checkout and may vary depending on your location and order weight. Here are some common methods:</Typography>
                        <br />
                        <ul>
                            <li style={{marginBottom:"8px"}}><Typography component={'span'} sx={{...style.title,fontSize:"18px"}}>Standard Shipping :</Typography>    This is the most economical option with an estimated delivery time of 3-5 business days within India.</li>
                            <li><Typography component={'span'} sx={{...style.title,fontSize:"18px"}}>Expedited Shipping :</Typography>   This option prioritizes faster delivery, typically reaching you within 1-3 business days within India.</li>
                        </ul>
                    </Box>
                    <Box>

                        <Typography sx={style.title}>Shipping Costs</Typography>
                        <Typography sx={style.content}>Shipping costs are calculated based on your order weight, destination, and chosen shipping method. The exact cost will be reflected at checkout before finalizing your purchase.</Typography>
                        <br />
                        {/* <Typography sx={style.content}>We offer Free Shipping for orders above Rs. 5000</Typography>
                        <br /> */}
                        
                    </Box>
                    <Box>

                        <Typography sx={style.title}>Tracking Your Order</Typography>
                        <Typography sx={style.content}>Once your order ships, you will receive a notification email with a tracking number. This allows you to monitor the progress of your delivery.</Typography>
                        <br />
                    </Box>
                    <Box>

                        <Typography sx={style.title}>International Shipping</Typography>
                        <Typography sx={style.content}>We currently ship within India.</Typography>
                        <br />
                    </Box>
                   
                    <Box>

                        <Typography sx={style.title}>Delivery Exceptions</Typography>
                        <Typography sx={style.content}>While we strive for timely deliveries, unforeseen circumstances like holidays, carrier delays, or natural disasters may cause occasional disruptions. We will keep you informed in such cases.</Typography>
                        <br />
                    </Box>

                    <Box>

                        <Typography sx={style.title}>Returns and Exchanges</Typography>
                        <Typography sx={style.content}>Please refer to our separate Returns and Exchanges Policy for information on handling unwanted or damaged items.</Typography>
                        <br />
                    </Box>

                    <Box>

                        <Typography sx={style.title}>Contact Us</Typography>
                        <Typography sx={style.content}>Contact us at<a style={{color:"rgb(39,162,253,1)",cursor:"pointer",textDecoration:"none"}} href="mailto:shipment@mayiiq.com" > shipment@mayiiq.com </a>for questions related to shipping policy OR Submit a ticket at <a style={{color:"rgb(39,162,253,1)",cursor:"pointer",textDecoration:"none"}} href="https://help.mayiiq.com" target="_blank" >https://help.mayiiq.com</a> </Typography>
                        <br />
                        {/* <Typography sx={style.content}>We will replace the item or issue a refund, depending on the availability of the product and your preference.</Typography>
                        <br /> */}
                    </Box>
                </Box>
            </Box>
            <Footer />

        </Box>
    </Box>
}

export default ShippingPolicy