import { Box, Button, Paper, Typography } from "@mui/material";
import Layout from "../layout"
import SideBarList from "../components/list"
import { useMediaQuery } from 'react-responsive';
import { formStyle } from './profile';
import { useState, useEffect } from "react";
import Address from '../components/address_modal';
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import { updateOrders } from "../redux/products";
import { UseDispatch } from "react-redux";
import CustomAxios from "../utils/CustomAxios";
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import Order_details from "../components/order_details"; 
import { updateAllOrders } from "../redux/orders";
import IndianNumFormat from "../components/numberFormat";
import Orders from "./orders";
import ShoppingBagIcon from '@mui/icons-material/ShoppingBag';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
const OrdersStatus = () => {
    const cartSidebar=useSelector(state=>state.sideBar.cartSidebar)
    const dispatch = useDispatch()
    const [orderIndex,setIndex]=useState()
    // const id=useSelector(state=>state.user.id)
    const orders = useSelector(state => state.orders.AllOrders)
    const authtoken = localStorage.getItem("authtoken")
    let platform_fee = 1.5/100
    useEffect(() => {
        getOrders()
    }, [authtoken])

    const getOrders = async () => {
        const decode = jwtDecode(authtoken)
        await CustomAxios.get(`${process.env.REACT_APP_BACKEND_BASE_URL}get_orders/${decode.id}`).then(res => {
            // console.log(res.data)
            dispatch(updateAllOrders(res.data))
            setSelected(res.data[res.data.length-1]?.razorpay_order_id || res.data[res.data.length-1]?.cash_pickup_id||res.data[res.data.length-1]?.order_id)
        })
        
    }
    // console.log(orders)
    const isMobile = useMediaQuery({ maxWidth: 600 });
    const isLaptop = useMediaQuery({ query: '(min-width: 900px)' });
    const isTabletHori = useMediaQuery({ query: '(min-width: 1200px)' });
    const isTablet= useMediaQuery({ maxWidth: 900,minWidth:600 });
    const [selected, setSelected] = useState()
    const formStyle = {
        inputBase: { border: "1px solid", borderColor: "grey.400", width: "100%", borderRadius: "5px", height: "48px", fontSize: "16px", padding: "14px", color: "grey.700" },
        label: { marginBottom: "12px", fontWeight: 500, fontSize: {xs:"14px",sm:"14px"} },
        textBox: { border: "1px solid", borderColor: "grey.400", width: "100%", borderRadius: "5px", fontSize: "16px", padding: "14px", color: "grey.700" },
        button: { height: "60px", textTransform: "none", fontWeight: 500, fontSize: "18px", maxWidth: "150px", alignSelf: "flex-end" },
    }
    const address = useSelector(state => state.user.address)
    // console.log(address)
    // const orders=[
    //     {id:1,
    //     paymentmethod:"Online",
    //     amount:"100.00",
    //     tax:"15",
    //     delivery_charge:"10.00",
    //     total_amount:"135.00",
    //     createdon:"2024-04-10 06:37:33.271088",
    //     status:"Order Processing"},
    //     {id:2,
    //     paymentmethod:"Cash On Delivery",
    //     amount:"50.00",
    //     tax:"15",
    //     delivery_charge:"5.00",
    //     total_amount:"62.50",
    //     createdon:"2024-04-15 06:37:33.271088",
    //     status:"Delivered"},
    //     {id:3,
    //     paymentmethod:"Online",
    //     amount:"90.00",
    //     tax:"15",
    //     delivery_charge:"7.00",
    //     total_amount:"127.50",
    //     createdon:"2024-04-15 06:37:33.271088",
    //     status:"Order Processing"}
    // ]

    const deliverydate = (date) => {
        const newdate = new Date(date);
        const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
            "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        const month = monthNames[newdate.getMonth()];
        const year = newdate.getFullYear()
        const formatdate = month + " " + newdate.getDate() + " ," + year
        return formatdate
    }

    const handleOrdersClick = (id,index) => {
        setSelected(id.razorpay_order_id || id.cash_pickup_id || id.order_id)
        setIndex(index)

    }


    return (
        <Box sx={{overflowY:cartSidebar?"hidden":"auto",height:"100vh", bgcolor: isMobile ? "#fff" : "grey.100", minHeight: "100vh", display: "flex", alignItems: "start", justifyContent: "start" ,}}>
            {/* <Box component={"img"} src={background_leaf} sx={{position:"absolute",width:"70%"}}/> */}
            <Layout />

            <Box sx={{ alignSelf: "start",marginTop:{xs:"77px", md:"140px"},height:{md:'calc(100vh - 160px)'},marginBottom:"60px",  justifySelf: "center", display: "flex",flexDirection:{xs:"column",sm:"row"}, justifyContent: "center", width: "100%", gap: "36px" }}>
                {isTabletHori && <Paper sx={{ height: "max-content",width:"20%" }} elevation={1}>
                    {SideBarList('left')}
                </Paper>}
                <Box  className="background" sx={{  width: {xs:"100%",md:"95%",lg:"65%"},display:"flex",gap:"16px"}} >
                
                <Box component={ Paper } sx={{width:{xs:"100%",md:"55%",lg:"55%"},padding:{xs:"20px"}, display: "flex", flexDirection: "column", gap: "24px",maxWidth:{md:"330px"}}}>
                    <Box sx={{}}>
                    <Typography sx={{ fontWeight: 700, fontSize: {xs:"20px",sm:"20px"} }} color="grey.700">My Orders</Typography>
                    </Box>
                    
                    {orders.length<=0 ? <Box sx={{display:"flex",flexDirection:"column",alignItems:"center",justifyContent:"center",height:"100%"}}>
                    <ShoppingBagIcon sx={{ color: "primary.light", opacity: "0.5", fontSize: "150px" }} />
                    {(isMobile || isTablet)&& <Box sx={{display:"flex",alignItems:"center",justifyContent:"center",height:"100%",flexDirection:"column"}}>
                    {/* <InfoOutlinedIcon sx={{ color: "primary.light", opacity: "0.5", fontSize: "150px" }}/> */}
                    <Typography sx={{fontSize:"24px",fontWeight:"700",color:"primary.light"}}>No Orders Available!</Typography>
                        </Box>}
                        </Box>:
                    <Box sx={{display:"flex",flexDirection:"column",gap:"20px",overflowY:"hidden", "&:hover":{overflowY: "auto"},position:"relative" }}>
                    {orders.map((order, index) => {
                        // // console.log(selected,order.razorpay_order_id)
                       return <><Box sx={{ display: "flex", flexDirection: "column", bgcolor: "grey.100", width: "100%", padding: "20px", borderRadius: "5px", border: order.razorpay_order_id == selected || order.cash_pickup_id == selected || order.order_id == selected ? "2px solid" : "1px solid",borderColor: order.razorpay_order_id== selected  || order.cash_pickup_id == selected || order.order_id == selected? "secondary.light":"#fff" , cursor: "pointer" }} onClick={() => handleOrdersClick(order,index)}>
                            <Box sx={{ display: "flex",   justifyContent:"space-between",alignItems:"center" }}>
                                <Typography sx={formStyle.label} color="grey.700">Order #{index+1} </Typography>
                                <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column", marginLeft: "20px", borderRadius: "20px",padding:"12px", height: "30px", marginBottom: "20px",border:"1px solid",borderColor:"secondary.light",bgcolor:"rgba(103, 251, 168, 0.1)" }}>
                                    <Typography sx={{ fontSize: "14px", fontWeight: "400", color: "textContrast" ,}}>{order.status}</Typography>
                                </Box>
                            </Box>
                            {/* <Box sx={{ display: "flex", flexDirection: "column", width: "100%", height: "1px", background: "grey", marginTop: "20px" }}></Box> */}
                            <Box sx={{ display: "flex", flexDirection: "column", marginTop: "0px",overflowWrap:"anywhere" }}>
                            {/* <Box sx={{ display: "flex", flexDirection: "row", gap: "20px",justifyContent:"space-between" }}>
                                    <Typography sx={formStyle.label} color="grey.600">Package:</Typography>
                                    <Typography sx={[formStyle.label,]} color="grey.600">{
                                   order.pack === 1 ? "D" :
                                   order.pack === 2 ? "C" :
                                   order.pack === 3 ? "B" :
                                   order.pack === 4 ? "A" :order.pack === 5 ?"AA":null}</Typography>
                                </Box> */}
                                <Box sx={{ display: "flex", flexDirection: "row", gap: "20px",justifyContent:"space-between" }}>
                                    <Typography sx={formStyle.label} color="grey.600">Order Date:</Typography>
                                    <Typography sx={[formStyle.label,]} color="grey.600">{deliverydate(order.created_on)}</Typography>
                                </Box>
                                {/* <Box sx={{ display: "flex", flexDirection: "row", gap: "26px",justifyContent:"space-between",overflowWrap:"anywhere"  }}>
                                    <Typography sx={formStyle.label} color="grey.600">Amount:</Typography>
                                    
                                    <Typography sx={formStyle.label} color="grey.600">{IndianNumFormat(parseFloat(order.amount))}</Typography>
                                </Box>
                                <Box sx={{ display: "flex", flexDirection: "row", gap: "20px",justifyContent:"space-between",overflowWrap:"anywhere",alignItems:"center"  }}>
                                    <Typography sx={[formStyle.label,{width:"150px"}]} color="grey.600">Delivery Fees:</Typography>
                                    <Typography sx={{ marginBottom: "12px", fontWeight: 400, fontSize: "14px", marginLeft: "3px",overflowWrap:"anywhere" }} color="grey.600">{'₹'+IndianNumFormat(parseFloat(order.delivery_charge).toFixed(2))}</Typography>
                                </Box>
                                <Box sx={{ display: "flex", flexDirection: "row", gap: "20px",justifyContent:"space-between",overflowWrap:"anywhere",alignItems:"center"  }}>
                                    <Typography sx={[formStyle.label,{width:"150px"}]} color="grey.600">Transaction Fees:</Typography>
                                    <Typography sx={{ marginBottom: "12px", fontWeight: 400, fontSize: "14px", marginLeft: "3px",overflowWrap:"anywhere" }} color="grey.600">{'₹'+IndianNumFormat((
                                        // parseFloat(order.platform_fee)+
                                        (parseFloat(order?.cash_pickup_id?0:order.transaction_fee))).toFixed(2))}</Typography>
                                </Box> */}
                                <Box sx={{ display: "flex", flexDirection: "row", gap: "20px",justifyContent:"space-between"  }}>
                                    <Typography sx={formStyle.label} color="grey.600">Total Price<span style={{ fontSize: "16px", fontWeight: 400, marginRight: "32px" }}>   :</span></Typography>
                                    {/* <Typography sx={{ marginBottom: "12px", fontWeight: 400, fontSize: "14px", marginLeft: "3px" }} color="grey.600"><CurrencyRupeeIcon sx={{fontSize:"14px",fontWeight: 700}}/>{IndianNumFormat(parseFloat(order.total_amount))}</Typography> */}
                                    <Typography sx={{ marginBottom: "12px", fontWeight: 500, fontSize: "14px", marginLeft: "3px", }} color="grey.600">{IndianNumFormat(parseFloat(order.total_amount))}</Typography>
                                </Box>
                            </Box>
                        </Box>
                        {orderIndex==index && (isMobile || isTablet) &&
                         <Order_details orderId={selected && selected} />}</>})}

                        </Box>}
                        </Box>
                        {
                    isLaptop&&
                
                <Box sx={{overflowY: "auto",width:"75%"}} component={Paper}>
                    {!selected? <Box sx={{display:"flex",alignItems:"center",justifyContent:"center",height:"100%",flexDirection:"column"}}>
                    <InfoOutlinedIcon sx={{ color: "primary.light", opacity: "0.5", fontSize: "150px" }}/>
                    <Typography sx={{fontSize:"24px",fontWeight:"700",color:"primary.light"}}>No Orders Available!</Typography>
                        </Box>
                        :
                <Order_details orderId={selected && selected} />}
                </Box>}

                </Box>
               
                


                
            </Box>
        </Box>
    )
}
export default OrdersStatus