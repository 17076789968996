import React from "react";
import { Dialog, Box, Typography, Button } from "@mui/material";

export default function ConfirmationDialog({
  open,
  onClose,
  onConfirm,
  onCancel,
}) {
  return (
    <Dialog
      open={open}
      onClose={onClose}
    >
      <Box
        sx={{
          maxWidth: "383px",
          width: "100%",
          padding: "24px",
          gap: "16px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Typography sx={{ fontWeight: 600, fontSize: "16px" }} color="primary">
          Confirm Action
        </Typography>
        <Typography
          sx={{ fontWeight: 400, fontSize: "14px", marginBottom: 2 }}
          color="grey.900"
        >
          Switching to Other Country will reset the form. Do you want to proceed?
        </Typography>
        <Box sx={{ display: "flex", width: "100%" }}>
          <Button
            onClick={onCancel}
            sx={{
              bgcolor: "grey.300",
              color: "grey.700",
              flex: 1, 
              height: "48px",
              textTransform: "none",
              "&:hover": {
                bgcolor: "grey.400",
              }, 
              borderRadius: "5px",
              marginRight: 1, 
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={onConfirm}
            sx={{
              bgcolor: "primary.main",
              color: "white",
              flex: 1, // Allows the button to take equal space
              height: "48px",
              textTransform: "none",
              "&:hover": {
                bgcolor: "primary.light",
              },
              borderRadius: "5px",
            }}
            autoFocus
          >
            Confirm
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
}
 