import { Download, Edit, Send } from "@mui/icons-material"
import { Box, Button, Divider, Grid, InputBase, MenuItem, Select, Stack, Step, StepLabel, Stepper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useMediaQuery } from "react-responsive"
import IndianNumFormat from "./numberFormat"
import { PDFViewer, BlobProvider, PDFDownloadLink } from "@react-pdf/renderer";
import Invoice from "./invoice"
import CustomAxios from "../utils/CustomAxios"
import { updateSnackBarMessage, updateSnackBarOpen, updateSnackBarSeverity } from "../redux/snackbar"
import { jwtDecode } from "jwt-decode"
import MessageBar from "../modules/messageBar"
import { updateAllOrders } from "../redux/orders"
import { useNavigate } from "react-router-dom"


const Order_details = ({ orderId }) => {
    const dispatch = useDispatch()
    const admin = jwtDecode(localStorage.getItem('authtoken'))?.role
    let allOrders = useSelector(state => state.orders.AllOrders)
    const [order_details, setOrderDetails] = useState()
    const order_steps = ['Pending', 'Processing', 'Local Facility', 'Out For Delivery', 'Delivered']
    const [orderSteps, setOrderSteps] = useState(0)
    const [orderStatus, setOrderstatus] = useState("")
    const [totals,setTotals] = useState({
        sub_total:0,
        total:0
    })
    const [trackingId,setTracking_id] = useState(null)
    const [isTrackingEditable,setIsTrackingEditable] = useState(false)
    let platform_fee = 1.5/100
    const navigate = useNavigate()
    useEffect(() => {
        let order = ""
        // if(orderId.startsWith("cash_")){
        //     order = allOrders.filter(order => order.cash_pickup_id === orderId)[0]
        // }
        // else{
        order = allOrders.filter(order => order.razorpay_order_id === orderId || order.cash_pickup_id === orderId ||order.order_id==orderId )[0]
        // }
        // // console.log(order)
        CalculateTotals(order)
        setOrderDetails(order)
        setTracking_id(order?.tracking_id)
    }, [orderId])

    const CalculateTotals = (order_details)=>{
        let sub_total = 0
        let total = 0
        if(order_details?.carts && order_details.carts.length >=1){
            order_details?.carts.map((cart)=>{
                sub_total += parseFloat(cart?.price? cart?.price:cart?.product.selling_price) * parseFloat(cart.quantity)
            })
            setTotals({...totals,sub_total:sub_total})
        }
    }    
    const isMobile = useMediaQuery({ maxWidth: 600 });
    const isLaptop = useMediaQuery({ query: '(min-width: 900px)' });
    const isTablet= useMediaQuery({ maxWidth: 900,minWidth:600 });

    const get_orders = ()=>{
        // if(isTrackingSendClicked){
            // // console.log("------------------clicked---------",isTrackingSendClicked)
            CustomAxios.get('listOrders/').then((res) => {
                dispatch(updateAllOrders(res.data))
                let order = res.data.filter(order => order.razorpay_order_id === orderId)[0]
                // // console.log(order.tracking_id)
                CalculateTotals(order)
                setOrderDetails(order)
                setTracking_id(order?.tracking_id)
            })
        // }
    }



    const handleUpdateTrackingId = (order_id)=>{
        if(trackingId){
            CustomAxios.put("update_order/"+orderId,{"tracking_id":trackingId}).then((res)=>{
                if(res.data.error){
                    dispatch(updateSnackBarMessage(res.data.error))
                    dispatch(updateSnackBarSeverity("error"))
                }
                else if(res.data){
                    get_orders()
                    dispatch(updateSnackBarMessage(res.data))
                    dispatch(updateSnackBarSeverity("success"))
                    setIsTrackingEditable(false)
                }
            }).catch((err)=>{
                // console.error(err)
            })
        }
        else{
            dispatch(updateSnackBarMessage("    Please Enter a valid Tracking Id    "))
            dispatch(updateSnackBarSeverity("error"))
        }
        dispatch(updateSnackBarOpen(true))
    }
    
    const handleChangeStatus = (e) => {
        e.preventDefault()
        let payload = {
            order_id:orderId,
            status:orderStatus
        }
        CustomAxios.post("change-status/",payload).then((res)=>{
            if(res.data.error){
                dispatch(updateSnackBarMessage(res.data.error))
                dispatch(updateSnackBarOpen(true))
                dispatch(updateSnackBarSeverity('error'))
            }
            else if(res.data.order_id){
                let order_id =  res.data.order_id
                let order_status =  res.data.status
                dispatch(updateAllOrders({order_id,order_status}));
                navigate("/orders")
            }
        }).catch((err)=>{
            // console.error(err)
        })
    }

    return (
        <Box  className="background"  sx={{  width: {xs:"calc(100% )",sm:"100%"}, p: {xs:"0px",sm:"20px"} }}>
            <MessageBar/>
            <Box   >
                {/* bg-[#F7F8FA] px-8 py-4 */}
                <Box sx={{display:"flex",gap:"20px",flexDirection:"column"}}>
                     <Stack direction={isTablet || isLaptop ?"row":"column"} className="status-container" sx={{ marginBottom:"20px",padding:"16px",gap:"16px", bgcolor: "#F7F8FA", justifyContent: "space-between"}}>
                    <Stack direction="row" sx={{ gap: "1rem", alignItems: "center"}}>
                        <Typography sx={{ fontSize:  {xs:"14px",sm:"16px"}, fontWeight: 700 }}>Order Status:</Typography>
                        <Typography sx={{ fontSize: "14px", fontWeight: 500 }}>{order_details?.status}</Typography>
                    </Stack>
                    <Stack direction="row" sx={{ gap: "1rem", alignItems: "center", }}>
                        <Typography sx={{ fontSize: {xs:"14px",sm:"16px"}, fontWeight: 700 }}>Payment Status:</Typography>
                        <Typography sx={{ fontSize: "14px", fontWeight: 500 }}>{order_details?.payment_status? "Completed":"Pending"}</Typography>
                    </Stack>
                </Stack>
               
                </Box>
               
                <Box sx={{  }}>
                    <Box className="tracking-container" sx={{ width: "100%" }}>
                        <Stack sx={{flexDirection:{xs:"column-reverse",sm:"row-reverse",},display:"flex",justifyContent:"space-between",alignItems:"center",gap:"16px"}}>
                            <Stack sx={{gap:"8px",pb:"28px",width:"100%"}}>
                                <Stack direction="row" sx={{justifyContent:"space-between",alignItems:"center",width:"100%",alignItems:"center",marginBottom:"20px"}}>
                                    <Typography sx={{ fontSize: {xs:"14px",sm:"20px"}, fontWeight: 600 ,width:"100%"}}>Order Id: {orderId}</Typography>
                                     <Box sx={{display:"flex",gap:"20px",justifyContent:"end",width:"100%",alignItems:"center"}}>
                                        {admin=="admin" && order_details?.status === "In Progress"  && <><Select  sx={{height:"40px",border:"none",maxWidth:"500px",width:"100%",outline:"none"}} displayEmpty onChange={(e)=>setOrderstatus(e.target.value)} value={orderStatus}>
                                        <MenuItem value="" disabled sx={{fontFamily: "inter", fontSize: "16px", fontStyle: "inter", fontWeight: "400", lineHeight: "24px",color:"#1E293B"}}>Select Order Status...</MenuItem>
                                                        <MenuItem value="In progress">In progress</MenuItem>
                                                        <MenuItem value="Order Completed">Order Completed</MenuItem>
                                                        <MenuItem value="Order Cancelled">Order Cancelled</MenuItem>
                                        </Select>
                                        <Button variant="contained" sx={{width:"250px"}} onClick={(e)=>handleChangeStatus(e)}>Change Status</Button></>}
                                    {/* {order_details?.tracking_id && admin !== "admin" ?
                                        <Typography sx={{ fontSize: {xs:"14px",sm:"20px"}, fontWeight: 600 }}>Tracking Id: {order_details?.tracking_id}</Typography>
                                        :
                                        admin ==="admin"&& <InputBase readOnly={isTrackingEditable?false:true} value={trackingId} onChange={(e)=>setTracking_id(e.target.value.trim())} endAdornment={isTrackingEditable?<Send sx={{cursor:"pointer",color:"primary.dark"}} onClick={()=>handleUpdateTrackingId(orderId)}/>:<Edit sx={{cursor:"pointer"}} onClick={()=>setIsTrackingEditable(true)}/>} placeholder="Tracking Id" sx={{border: "1px solid", borderColor: "grey.400", borderRadius: "5px", height: "48px", fontSize: "16px", padding: "14px", color: "grey.700"}}/>
                                    } */}
                                    {/* <Stack sx={{display:"flex",width:"20%"}}> */}
                                        <PDFDownloadLink document={<Invoice order_details = {order_details}/>} fileName={`${orderId}.pdf`}>
                                            <Typography variant="contained" sx={{ fontWeight: 400, px: "1.25rem", borderRadius: "5px", height: "3rem",fontSize:"16px",textDecoration:"none",color:"primary.dark",textAlign:"center",display:"flex",justifyContent:"center",alignItems:"center" ,flexDirection:{xs:"column",sm:"row"}}}><Download sx={{ mr: "12px",color:"primary.dark" }} />Invoice</Typography>
                                        </PDFDownloadLink>
                                    </Box>
                                        {/* ------------------------Below code is for Printable and downloadable------------------------- */}
                                        {/* <BlobProvider document={<Invoice order_details = {order_details}/>}>
                                            {
                                                ({url,blob}) =>(
                                                    
                                                )
                                            }
                                        </BlobProvider> */}
                                        {/*--------------Code Ends--------------------------------*/}
                                    {/* </Stack> */}
                                </Stack>
                                <Stack sx={{gap:"8px",flexDirection:"row",justifyContent:"space-between",alignItems:"center",width:"100%"}}>
                                    <Stack sx={{gap:"8px",width:"80%",}}>
                                        <Typography sx={{color:"#757575",lineHeight:{xs:"14px",md:"19px"},fontSize:{xs:"12px",md:"16px"}}}> {order_details?.self_pickup? "Your order has been placed and confirmed.":"Your order has been placed and confirmed. Please use the link to track your order"}</Typography>
                                        {
                                            order_details?.shipment_details?.awb?
                                            <Box sx={{textDecoration:"underline"}} component="a"  href={`https://shiprocket.co/tracking/${order_details?.shipment_details?.awb}`} target="_blank">Tracking# : {order_details?.shipment_details?.awb}</Box>
                                            :
                                            !order_details?.self_pickup &&<Typography>Tracking# : Pending</Typography>
                                        }
                                    </Stack>
                                    
                                </Stack>
                            </Stack>
                        </Stack>
                        {/* </Stack> */}
                        
                        {/* <Stack sx={{ py: "28px" }}>
                            <Stepper alternativeLabel={isMobile?false:true} orientation={isMobile? "vertical":"horizontal"}>
                                {
                                    order_steps.map((status, id) => {
                                        return <Step key={id}>
                                            <StepLabel>{status}</StepLabel>
                                        </Step>
                                    })
                                }
                            </Stepper>
                        </Stack> */}
                    </Box>
                    <Box className="order-details-container">
                        <TableContainer sx={{ width: "100%" }}>
                            <Table>
                                <TableHead sx={{ bgcolor: "#F9FAFB" }}>
                                    <TableRow>
                                        <TableCell sx={{ textAlign: "left" }}>Products</TableCell>
                                        <TableCell sx={{ textAlign: "right" }}>Total</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        order_details?.carts && order_details.carts?.map((cart, p_id) => {
                                            
                                            return (

                                                <TableRow key={p_id}>
                                                    <TableCell>
                                                        <Stack direction="row" sx={{ gap: "1rem", alignItems: "center" }}>
                                                            {cart.product.cover_details?.original && <Box component="img" src={cart.product.cover_details.original} sx={{ width: "50px", height: "50px" }}></Box>}
                                                            <Typography sx={{ color: "#666666" }}>{cart.product.name}</Typography> X <Typography sx={{ fontWeight: "bold" }}>{cart.quantity}</Typography>
                                                        </Stack>
                                                    </TableCell>
                                                    <TableCell sx={{ textAlign: "right" }}>
                                                        <Typography>{IndianNumFormat(parseFloat(cart?.price?cart?.price:cart?.product.selling_price)* parseFloat(cart.quantity))}</Typography>
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        })
                                    }

                                </TableBody>
                            </Table>
                        </TableContainer>
                        <Stack sx={{ alignItems: "flex-end", p: "16px" }}>
                            <Stack sx={{ width: {xs:"100%",sm:"50%"}, gap: "1rem" }}>
                                <Stack direction="row" sx={{ justifyContent: "space-between" }}>
                                    <Typography>Sub Total</Typography>
                                    <Typography>{IndianNumFormat(totals.sub_total)}</Typography>
                                </Stack>
                                <Stack direction="row" sx={{ justifyContent: "space-between",alignItems:"center" }}>
                                    <Typography sx={[!window.location.pathname.startsWith("/orders/") &&{width:"170px"}]}>Delivery Fees</Typography>
                                    <Typography>{'₹'+IndianNumFormat(parseFloat(order_details?.delivery_charge).toFixed(2))}</Typography>
                                </Stack>
                                <Stack direction="row" sx={{ justifyContent: "space-between" }}>
                                    <Typography>Tax</Typography>
                                    <Typography>{IndianNumFormat(Math.round(parseFloat(order_details?.tax)))}</Typography>
                                </Stack>
                                {/* <Stack direction="row" sx={{ justifyContent: "space-between" }}>
                                    <Typography>Platform Fees</Typography>
                                    <Typography>{IndianNumFormat(parseFloat(totals?.sub_total * platform_fee))}</Typography>
                                </Stack> */}
                                <Stack direction="row" sx={{ justifyContent: "space-between" }}>
                                    <Typography>Transaction Fees</Typography>
                                    <Typography>{ '₹'+IndianNumFormat(( 
                                        // parseFloat(order_details?.platform_fee) + 
                                        parseFloat(order_details?.cash_pickup_id? 0 : order_details?.transaction_fee)).toFixed(2))}</Typography>
                                </Stack>
                                {order_details?.discounts>0&&
                                <Stack direction="row" sx={{ justifyContent: "space-between" }}>
                                    <Typography> Applied cash Incentives</Typography>
                                    <Typography>-{ '₹'+IndianNumFormat(
                                        // parseFloat(order_details?.platform_fee) + 
                                        parseFloat(order_details?.discounts).toFixed(2))}</Typography>
                                </Stack>}
                                <Stack direction="row" sx={{ justifyContent: "space-between" }}>
                                    <Typography sx={{ fontSize: "16px", fontWeight: 700 }}>Total</Typography>
                                    <Typography sx={{fontWeight:"700"}}>
                                        {
                                            IndianNumFormat(parseFloat(order_details?.total_amount)
                                            )
                                        }
                                    </Typography>
                                </Stack>
                            </Stack>
                            <Box>
                            </Box>
                        </Stack>
                    </Box>
                    <Grid container sx={{}}>
                        {/* <Grid item lg={6}>
                            <Typography component="h3" sx={{ fontWeight: 600, pb: "8px", borderBottom: "1px solid #e5e7eb" }}>Billing Address</Typography>
                            <Typography>{order_details?.billings?.title}</Typography>
                            <Typography>{order_details?.billings?.address}</Typography>
                        </Grid> */}
                        <Grid item sx={{bgcolor:"grey.100",padding:"20px",borderRadius:"8px",width:"auto"}} >
                            <Typography component="h3" sx={{ fontWeight: 600, pb: "8px",  }}>{order_details?.self_pickup?  "Pickup Address":"Delivery Address"}</Typography>
                            <Divider/>
                            <Typography>{order_details?.shippings?.title}</Typography>
                            <Typography>{order_details?.shippings?.address}</Typography>
                            <Typography>{order_details?.shippings?.town_city}, {order_details?.shippings?.state}</Typography>
                            <Typography>{order_details?.shippings?.pin}</Typography>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </Box>
    )
}

export default Order_details