import { Box, Typography } from "@mui/material";
import { landingPage_styles } from "./landing_page";
import Slider from "react-slick";
import banner1 from "./landing_assets/slide1.jpg"
import banner2 from "./landing_assets/slide2.jpg"
import banner3 from "./landing_assets/slide3.jpg"
import { useRef } from "react";
import { useMediaQuery } from "react-responsive";
export function Business() {
    const isMobile = useMediaQuery({ query: '(max-width: 600px)' });
    const sliderRef = useRef(null);
    var settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        arrows: false,
        autoplaySpeed: 3000,
    };
    return (<Box sx={{...landingPage_styles.business.page_prop}}>
        <Box sx={{ width: "100%" }}>
            <Slider {...settings} ref={sliderRef} >
                <Box sx={{ position: "relative" }}>
                    <Box component="img" sx={landingPage_styles.business.image} src={banner1}>
                    </Box>
                    <Box sx={{ position: {md:"absolute"}, top: "35%", left: "8%", maxWidth: {xs:"100%",md:"546px"}, minHeight: {xs:"100%",md:"250px"}, background: {xs:"#000",md:"#00000099"}, width: "100%",borderRadius:{md:"8px"},padding:{xs:"16px",md:"32px"},gap:{xs:"12px",md:"18px"},display:"flex",flexDirection:"column"}}>
                        <Typography sx={{color:"white",fontSize:{xs:"28px",md:"52px"},fontWeight:"600",lineHeight:{xs:"33.6px",md:"62px"}}}>Wisdom  </Typography>
                        <Typography sx={{color:"white",fontSize:{xs:"16px",md:"24px"},fontWeight:"400",lineHeight:{xs:"24px",md:"30px"}}}>Holistic Integration with Living Skills, Financial Management, Kindness, and Balanced Lifestyles</Typography>
                    </Box>
                </Box>
                <Box sx={{ position: "relative" }}>
                    <Box component="img" sx={landingPage_styles.business.image} src={banner3}>
                    </Box>
                    <Box sx={{ position: {md:"absolute"}, top: "35%", left: "8%", maxWidth:  {xs:"100%",md:"546px"}, minHeight: {xs:"100%",md:"260px"},  background: {xs:"#000",md:"#00000099"}, width: "100%",borderRadius:{md:"8px"},padding:{xs:"16px",md:"32px"},gap:{xs:"12px",md:"18px"},display:"flex",flexDirection:"column"}}>
                        <Typography sx={{color:"white",fontSize:{xs:"28px",md:"52px"},fontWeight:"600",lineHeight:{xs:"33.6px",md:"62px"}}}>Investment </Typography>
                        <Typography sx={{color:"white",fontSize:{xs:"16px",md:"24px"},fontWeight:"400",lineHeight:{xs:"24px",md:"30px"}}}>Achieve Financial Freedom with Our Organic Wealth Plan with No Investment, No Expiration of Benefits and Earnings</Typography>
                    </Box>
                </Box>
                <Box sx={{ position: "relative" }}>
                    <Box component="img" sx={landingPage_styles.business.image} src={banner2}>
                    </Box>
                    <Box sx={{ position:{md:"absolute"}, top: "35%", left: "8%", maxWidth: {xs:"100%",md:"546px"}, minHeight: {xs:"100%",md:"250px"}, background: {xs:"#000",md:"#00000099"}, width: "100%",borderRadius:{md:"8px"},padding:{xs:"16px",md:"32px"},gap:{xs:"12px",md:"18px"},display:"flex",flexDirection:"column"}}>
                        <Typography sx={{color:"white",fontSize:{xs:"28px",md:"52px"},fontWeight:"600",lineHeight:{xs:"33.6px",md:"62px"}}}>Leadership </Typography>
                        <Typography sx={{color:"white",fontSize:{xs:"16px",md:"24px"},fontWeight:"400",lineHeight:{xs:"24px",md:"30px"}}}>Empowering Inclusive, Mindful and Conscience Leadership for Well Being through Your Networks </Typography>
                    </Box>
                </Box>
            </Slider>
        </Box>

    </Box >)
}